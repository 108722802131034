export enum EExceptionsGridType {
  BAD_ORDERS = 'BAD_ORDERS',
  BAD_ORDERS_IM = 'BAD_ORDERS_IM',
  EXCEPTION_DETAILS = 'EXCEPTION_DETAILS',
  DEMURRAGE = 'DEMURRAGE',
  STORAGE = 'STORAGE',
  OVERLOAD = 'OVERLOAD',
  STATEMENT_EXCEPTION = 'STATEMENT_EXCEPTION',
  NO_BILLS = 'NO_BILLS'
}

export enum EExceptionsEGridTitle {
  BAD_ORDERS = 'Bad Order Exceptions',
  EXCEPTION_DETAILS = 'Exception Details',
  DEMURRAGE = 'Demurrage Exceptions',
  STORAGE = 'Storage Exceptions',
  STATEMENT_EXCEPTION = 'Statement Exceptions',
  OVERLOAD = 'Overload Exceptions',
  NO_BILLS = 'No Bills Exceptions'
}

export enum EExceptionType {
  BAD_ORDERS = 'Bad Order',
  IM_BAD_ORDERS = 'IM BadOrder',
  EXCEPTION_DETAILS = 'Exception Details',
  DEMURRAGE = 'Demurrage',
  OVERLOAD = 'Overload',
  NO_BILLS = 'No Bills',
  STORAGE = 'Storage',
  STATEMENT = 'Statement',
  HOLD = 'Hold'
}

export enum EExceptionLinkType {
  BAD_ORDERS = 'bad-order',
  DEMURRAGE = 'demurrage',
  OVERLOAD = 'overload',
  NO_BILLS = 'no-bills',
  STORAGE = 'storage',
  STATEMENT = 'statement'
}

