import { Injectable } from '@angular/core';

import { NSCommonConstants } from '@ruby/configs/common.constants';
import { IParseUnits } from '@ruby/shared/models/quick-search/quick-search.interface';

@Injectable({
  providedIn: 'root'
})
export class UnitsParser {

  /**
   * Parse Quick Search Units
   *
   * @summary This function take a string and parse to a valid equipment id or waybill number
   *          if includeWaybills is not set by default waybill numbers will be included
   * @param data: string
   * @param limit: number
   * @param includeWaybills: boolean
   * @returns IParseUnits
   */
  parseUnits(data: string, limit: number = 1000, includeWaybills: boolean = true): IParseUnits {
    if (!data) {
      return {
        validEquipments: [],
        invalidEquipments: [],
        totalEquipments: 0
      };
    }
    const dataArray = this.splitInitialData(data.toLowerCase());
    return this.coreUnitsValidator(dataArray, limit, includeWaybills);
  }

  private splitInitialData(data: string): Array<string> {
    const dataArray = data.split(NSCommonConstants.splitByPunctuation);
    const subListParsed: Array<string> = [];
    const parsedData = dataArray.filter((item: string) => {
      if (item.length > 11 && (item.includes('-') || item.includes('…') || item.includes(' '))) {
        const testData = item.match(NSCommonConstants.unitsRangePattern);
        const matcherAsArray = testData as Array<string>;
        /* istanbul ignore next */
        if (matcherAsArray) {
          subListParsed.push(...matcherAsArray);
          return false;
        }
      }
      return !!item.trim();
    });
    parsedData.push(...subListParsed);
    return parsedData;
  }

  private coreUnitsValidator(dataArray: Array<string>, limit: number, includeWaybillNumbers: boolean): IParseUnits {
    const valid: Set<string> = new Set<string>();
    const invalid: Set<string> = new Set<string>();
    let prefix = '';
    let total = 0;
    dataArray.forEach((item: string) => {
      const searchFor = item.trim();
      if (includeWaybillNumbers && this.isWaybill(searchFor)) {
        valid.add(searchFor);
        return;
      }
      prefix = this.getRealPrefix(searchFor, prefix);
      const unit = this.removePrefix(searchFor, prefix);
      if (!unit && !prefix) {
        invalid.add(searchFor);
        return;
      }
      if (prefix && searchFor.match(NSCommonConstants.unitRange)) {
        const totalRange = this.getTotalRange(unit);
        if (totalRange > limit) {
          total += totalRange;
          return;
        } else {
          const parsedUnits = this.parseRanges(prefix, unit);
          if (parsedUnits.size > 0) {
            parsedUnits.forEach((expanded: string) => valid.add(expanded));
          } else {
            invalid.add(prefix + unit);
          }
          prefix = '';
          return;
        }
      } else if (prefix && NSCommonConstants.validEquipment.test(prefix + unit)) {
        valid.add(`${ prefix } ${ unit.padStart(6, '0') }`);
        return;
      }
      invalid.add(searchFor);
      prefix = '';
      return;
    });
    total += valid.size;
    return {
      validEquipments: Array.from(valid),
      invalidEquipments: Array.from(invalid),
      totalEquipments: total
    };
  }

  private removePrefix(searchFor: string, prefix: string): string {
    if (searchFor.match(NSCommonConstants.unitRange)) {
      const regex = new RegExp(prefix, 'gi');
      return searchFor.replace(regex, '');
    }
    const unit = Number(searchFor.replace(NSCommonConstants.replaceUnitPrefix, '').trim());
    return unit ? unit.toString(10) : '';
  }

  private getRealPrefix(searchFor: string, temp: string): string {
    if (searchFor.match(NSCommonConstants.unitRange)) {
      const matcher = searchFor.match(NSCommonConstants.replaceUnitPrefix);
      return matcher ? matcher[0].trim() : '';
    }
    if (searchFor.match(NSCommonConstants.validUnitWithAnyNumber)) {
      const unit = searchFor.replace(NSCommonConstants.replaceUnitPrefix, '');
      const prefix = searchFor.replace(unit, '').trim();
      return NSCommonConstants.validEquipment.test(prefix + Number(unit).toString(10)) ? prefix : '';
    }
    return (Number(searchFor) && searchFor.length <= 10) ? temp : '';
  }

  private parseRanges(prefix: string, ranges: string): Set<string> {
    const equipRange = this.getUnitsRange(ranges);
    return equipRange ? this.expandUnits(prefix, equipRange[0], equipRange[1]) : new Set<string>();
  }

  private expandUnits(prefix: string, start: number, end: number): Set<string> {
    const result = new Set<string>();
    for (let unit = start; unit <= end; unit++) {
      result.add(`${ prefix } ${ (unit.toString()).padStart(6, '0') }`);
    }
    return result;
  }

  private isWaybill(searchFor: string): boolean {
    const waybill = (Number(searchFor)).toString();
    return NSCommonConstants.validWaybill.test(waybill);
  }

  private getTotalRange(unit: string): number {
    const range = this.getUnitsRange(unit);
    return range ? range[1] - range[0] : 0;
  }

  private getUnitsRange(unit: string): Array<number> | undefined {
    const range = unit.split(NSCommonConstants.splitUnitRanges).filter((item) => item.trim());
    return range.length === 2 ? range.map((num) => Number(num)) : undefined;
  }
}
