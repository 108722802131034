import { Injectable } from '@angular/core';

import { IContact } from '@ruby/modules/account/models/contact.interface';
import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { IAuthorizations } from '@ruby/shared/models/commons/authentication.interface';

@Injectable({
  providedIn: 'root'
})
export class PendoService {
  rageThreshold = 3;
  rageTotalClicks = 0;
  rageLastEvent = 0;
  rageLastTarget?: EventTarget = undefined;
  rageGuideId = 'kSZnkQQhiBg771t6vnP4qTMyfSU';

  constructor() {}

  /**
   * Initialize Pendo
   *
   * @summary Initialize Pendo
   * @param data: IAuthorizations
   * @returns void
   */
  initializePendo(data: IAuthorizations): void {
    const params = {
      visitor: {
        id: data.userRacf,
        role: data.roles,
        authorizations: data.authorizations
      }
    };
    if (pendo && (!pendo.isReady || !pendo.isReady())) {
      pendo.initialize(params);
    } else if (pendo && pendo.isReady && pendo.isReady()) {
      pendo.identify(params);
    }
    this.startRageClickCollector();
  }

  /**
   * Stop Pendo
   *
   * @summary Stop Pendo
   * @returns void
   */
  stopPendo(): void {
    if (pendo && pendo.clearSession) {
      pendo.clearSession();
      this.stopRageClickCollector();
    }
  }

  /**
   * Start Rage Click Collector
   *
   * @summary Start Rage Click Collector
   * @returns void
   */
  startRageClickCollector(): void {
    document.addEventListener('click', this.onRageClicks.bind(this), false);
  }

  /**
   * Stop Rage Click Collector
   *
   * @summary Stop Rage Click Collector
   * @returns void
   */
  stopRageClickCollector(): void {
    document.removeEventListener('click', this.onRageClicks, false);
  }


  /**
   * Check Add Contact Metadata
   *
   * @summary Check Add Contact Metadata
   * @param data: IContact
   * @returns void
   */
  checkAddContactMetadata(data: IContact): void {
    setTimeout(() => this.addContactMetadata(data), 5000);
  }

  /**
   * Add Contact Metadata
   *
   * @summary Add Contact Metadata
   * @param data: IContact
   * @returns void
   */
  addContactMetadata(data: IContact): void {
    const metadata = pendo.getSerializedMetadata();
    pendo.identify({
      visitor: {
        id: metadata.visitor.userRacf,
        role: metadata.visitor.roles,
        authorizations: metadata.visitor.authorizations,
        email: data.email,
        name: `${ data.firstName } ${ data.lastName }`,
        positionRole: data.role
      }
    });
    pendo.loadGuides();
  }

  /**
   * Add Companies Metadata
   *
   * @summary Add Companies Metadata
   * @param customers: Array<ICustomer>
   * @returns void
   */
  addCompaniesMetadata(customers: Array<ICustomer>): void {
    if (!customers || customers.length === 0) {
      return;
    }

    const customer: ICustomer = customers[0];

    let customerId = '';
    if (customer.masterPrimarySix) {
      customerId = customer.masterPrimarySix;
    } else if (customer.scac) {
      customerId = customer.scac;
    } else {
      customerId = customer.customerName;
    }

    const customersRaw: Array<string> = customers.map((cust: ICustomer) =>
      // eslint-disable-next-line max-len
      `| p6:${ customer.masterPrimarySix || '' } | name:${ customer.customerName || '' } | scac:${ customer.scac || '' } | type:${ customer.type?.name || '' } |` +
      ` cmlP6:${ customer.parentCustomer?.commercialPrimarySix || '' } | cmlName:${ customer.parentCustomer?.commercialName || '' } |` +
      ` cmlSeg:${ customer.parentCustomer?.commercialSegmentation || '' } | cmlTier:${ customer.parentCustomer?.commercialTier || '' } |` +
      ` cmlType:${ customer.parentCustomer?.commercialType || '' } |` +
      ` comP6:${ customer.parentCustomer?.commonPrimarySix || '' } | comName:${ customer.parentCustomer?.commonName || '' } |` +
      ` comSeg:${ customer.parentCustomer?.commonSegmentation || '' } | comTier:${ customer.parentCustomer?.commonTier || '' } |` +
      ` comType:${ customer.parentCustomer?.commonType || '' } | validIMP6:${ customer.isValidIMP6 || '' } | validDrayScac:${ customer.isValidDrayScac || '' } |`
    );


    const metadata = pendo.getSerializedMetadata();
    pendo.identify({
      visitor: {
        id: metadata.visitor.userRacf,
        role: metadata.visitor.roles,
        authorizations: metadata.visitor.authorizations,
        email: metadata.visitor.email,
        name: `${ metadata.visitor.firstName } ${ metadata.visitor.lastName }`,
        companies: customers.map((cust: ICustomer) => (cust.customerName)),
        customersRaw
      },
      account: {
        id: customerId,
        name: customer.customerName
      }
    });
    pendo.loadGuides();
  }

  /**
   * On Rage Clicks
   *
   * @summary On Rage Clicks
   * @param event: MouseEvent
   * @returns void
   */
  onRageClicks(event: MouseEvent): void {
    const now = Math.floor(Date.now());
    const target = event.target;

    if (now - this.rageLastEvent < 500) {
      if (target === this.rageLastTarget) {
        this.rageTotalClicks += 1;
      } else {
        this.rageTotalClicks = 0;
      }
    } else {
      this.rageTotalClicks = 0;
    }

    if(this.rageTotalClicks >= this.rageThreshold) {
      pendo.showGuideById(this.rageGuideId);
    }
    this.rageLastEvent = now;
    this.rageLastTarget = target!;
  }
}
