import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectReducerState = createFeatureSelector<fromRouter.RouterReducerState>('router');
export const getRouterInfo = createSelector(
  selectReducerState,
  state => state.state
);

export const {
  selectCurrentRoute,
  selectQueryParams,
  selectQueryParam,
  selectRouteParams,
  selectRouteParam,
  selectRouteData,
  selectUrl
} = fromRouter.getSelectors(selectReducerState);
