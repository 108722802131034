export enum EUserPreferences {
  CUSTOMER_SELECT = 'CUSTOMER_SELECT',
  DASHBOARD = 'DASHBOARD',
  NS_GRID_RUBY = 'NS_GRID_RUBY',
  WATCHLIST = 'WATCHLIST',
  IBOL = 'IBOL',
  TERMS = 'TERMS',
  DIVERSION = 'DIVERSION',
  CASE = 'CASE',
  CASE_CUSTOMER_TOP = 'CASE_CUSTOMER_TOP',
  MENU_TRACK_ALERT = 'MENU_TRACK_ALERT',
  AUTO_SAVE_GRID_COLUMN = 'AUTO_SAVE_GRID_COLUMN',
  IARS = 'IARS',
  IARS_TERMINALS = 'IARS_TERMINALS',
  IARS_WIDGET = 'IARS_WIDGET',
  IARS_REQUEST_RESERVATION ='IARS_REQUEST_RESERVATION'
}
