import { createAction, props } from '@ngrx/store';

import {
  ICase,
  ICaseActivity,
  ICaseAttachment,
  ICaseComment,
  ICaseContact,
  IInterestedPartyStatus
} from '@ruby/shared/models/case-management/case.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadCase = createAction(
  '[Case Summary] Load Case',
  props<{ data: string }>()
);

export const loadCaseSuccess = createAction(
  '[Case Summary] Load Case Success',
  props<{ data: ICase }>()
);

export const loadCaseFailure = createAction(
  '[Case Summary] Load Case Failure',
  props<{ error: IError }>()
);

export const loadInterestedParties = createAction(
  '[Case Summary] Load Interested Parties',
  props<{ data: string }>()
);

export const loadInterestedPartiesSuccess = createAction(
  '[Case Summary] Load Interested Parties Success',
  props<{ data: Array<ICaseContact> }>()
);

export const loadInterestedPartiesFailure = createAction(
  '[Case Summary] Load Interested Parties Failure',
  props<{ error: IError }>()
);

export const uploadFile = createAction(
  '[Case Summary] Upload File',
  props<{ data: string }>()
);

export const uploadFileProgress = createAction(
  '[Case Summary] Upload File Progress',
  props<{ data: number }>()
);

export const uploadFileSuccess = createAction(
  '[Case Summary] Upload File Success',
  props<{ data: string }>()
);

export const uploadFileFailure = createAction(
  '[Case Summary] Upload File Failure',
  props<{ error: IError }>()
);

export const loadActivities = createAction(
  '[Case Summary] Load Activities',
  props<{ data: string }>()
);

export const loadActivitiesSuccess = createAction(
  '[Case Summary] Load Activities Success',
  props<{ data: { activity: Array<ICaseActivity>; caseId: string } }>()
);

export const loadActivitiesFailure = createAction(
  '[Case Summary] Load Activities Failure',
  props<{ error: IError }>()
);

export const createComment = createAction(
  '[Case Summary] Post Comment',
  props<{ data: ICaseComment }>()
);

export const createCommentSuccess = createAction(
  '[Case Summary] Post Comment Success',
  props<{ data: { message: string; caseId: string } }>()
);

export const createCommentFailure = createAction(
  '[Case Summary] Post Comment Failure',
  props<{ error: IError }>()
);

export const addInterestedParty = createAction(
  '[Case Summary] Add Interested Party',
  props<{ data: { caseId: string; contacts: Array<{ id: string; name: string }> } }>()
);

export const addInterestedPartySuccess = createAction(
  '[Case Summary] Add Interested Party Success',
  props<{ data: { caseId: string; response: Array<string> } }>()
);

export const addInterestedPartyFailure = createAction(
  '[Case Summary] Add Interested Party Failure',
  props<{ error: IError }>()
);

export const fetchMedia = createAction(
  '[Case Summary] Fetch Media',
  props<{ data: ICaseAttachment; download?: boolean }>()
);

export const fetchMediaSuccess = createAction(
  '[Case Summary] Fetch Media Success',
  props<{ data: ICaseAttachment }>()
);

export const fetchMediaFailure = createAction(
  '[Case Summary] Fetch Media Failure',
  props<{ error: IError }>()
);

export const clearMedia = createAction(
  '[Case Summary] Clear Media'
);

export const loadAttachments = createAction(
  '[Case Summary] Load Attachments',
  props<{ data: string }>()
);

export const loadAttachmentsSuccess = createAction(
  '[Case Summary] Load Attachments Success',
  props<{ data: Array<ICaseAttachment> }>()
);

export const loadAttachmentsFailure = createAction(
  '[Case Summary] Load Attachments Failure',
  props<{ error: IError }>()
);

export const downloadFile = createAction(
  '[Case Summary] Download File',
  props<{ data: string; name: string }>()
);

export const downloadFileSuccess = createAction(
  '[Case Summary] Download File Success'
);

export const downloadFileFailure = createAction(
  '[Case Summary] Download File Failure',
  props<{ error: IError }>()
);

export const emailValidation = createAction(
  '[Case Summary] Email Validation',
  props<{ data: string }>()
);

export const emailValidationSuccess = createAction(
  '[Case Summary]  Email Validation success',
  props<{ data: ICaseContact }>()
);

export const emailValidationFailure = createAction(
  '[Case Summary]  Email Validation failure',
  props<{ error: IError }>()
);

export const refreshInterestedPartyState = createAction(
  '[Case Summary] Refresh Interested Party State'
);

export const removeInterestedParty = createAction(
  '[Case Summary] Remove Interested Party',
  props<{ data: Array<IInterestedPartyStatus> }>()
);

export const removeInterestedPartySuccess = createAction(
  '[Case Summary] Remove Interested Party Success',
  props<{ data: Array<IInterestedPartyStatus> }>()
);

export const removeInterestedPartyFailure = createAction(
  '[Case Summary] Remove Interested Party Failure',
  props<{ error: IError }>()
);

export const removeContact = createAction(
  '[Case Summary] Remove Contact',
  props<{ data: string }>()
);

export const setSelectedCase = createAction(
  '[Case Summary] Set Selected Case',
  props<{ data: ICase }>()
);

export const removeAttachment = createAction(
  '[Case Summary] Remove Attachment',
  props<{ data: { documentsId: Array<string>; caseId: string } }>()
);

export const removeAttachmentSuccess = createAction(
  '[Case Summary] Remove Attachment Success'
);

export const removeAttachmentFailure = createAction(
  '[Case Summary] Remove Attachment Failure',
  props<{ error: IError }>()
);

export const refreshEquipmentsState = createAction(
  '[Case Summary] Refresh Equipment State'
);

export const removeEquipment = createAction(
  '[Case Summary] Remove Equipment',
  props<{ equipments: Array<string>; caseId: string }>()
);

export const removeEquipmentSuccess = createAction(
  '[Case Summary] Remove Equipment Success',
  props<{ data: Array<string>; caseId: string }>()
);

export const removeEquipmentFailure = createAction(
  '[Case Summary] Remove Equipment Failure',
  props<{ error: IError }>()
);

export const addEquipment = createAction(
  '[Case Summary] Add Equipment',
  props<{ equipments: Array<string>; caseId: string }>()
);

export const addEquipmentSuccess = createAction(
  '[Case Summary] Add Equipment Success',
  props<{ data: Array<string>; caseId: string }>()
);

export const addEquipmentFailure = createAction(
  '[Case Summary] Add Equipment Failure',
  props<{ error: IError }>()
);

export const editCaseStatus = createAction(
  '[Case Summary] Edit Case Status',
  props<{ caseId: string; reason?: string }>()
);

export const editCaseStatusSuccess = createAction(
  '[Case Summary] Edit Case Status Success'
);

export const editCaseStatusFailure = createAction(
  '[Case Summary] Edit Case Status Failure',
  props<{ error: IError }>()
);

export const updateSubject = createAction(
  '[Case Summary] Update Subject',
  props<{ caseId: string; subject: string }>()
);

export const updateSubjectSuccess = createAction(
  '[Case Summary] Update Subject Success'
);

export const updateSubjectFailure = createAction(
  '[Case Summary] Update Subject Failure',
  props<{ error: IError }>()
);

export const selectNextCase = createAction(
  '[Case Summary] Select Next Case',
  props<{ caseId: string; selectedTab: number }>()
);

export const selectNextCaseSuccess = createAction(
  '[Case Summary] Select Next Case Success',
  props<{ data: ICase | undefined }>()
);

export const updateCaseCommentNotification = createAction(
  '[Case Summary] Update Case Comment Notification'
);

export const clearContactList = createAction(
  '[Case Summary] Clear Contact List'
);
