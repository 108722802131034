export enum ERole {
  ROLE_IP = 'role_ip',
  ROLE_SL = 'role_sl',
  ROLE_IM = 'role_im',
  ROLE_UT = 'role_ut',
  ROLE_Dray = 'role_dray',
  ROLE_FF = 'role_ff',
  ROLE_OFFLINE = 'role_offline',
  ROLE_3PL = 'role_3pl',
  ROLE_Internal = 'role_internal',
  ROLE_Admin = 'role_admin',
  ROLE_BASE_USER = 'role_base_user'
}

export const roleDescriptionMap =  new Map<ERole | string, string>([
  [ERole.ROLE_IP, 'Industrial Products'],
  [ERole.ROLE_SL, 'Shortline'],
  [ERole.ROLE_IM, 'Intermodal'],
  [ERole.ROLE_UT, 'Unit Train'],
  [ERole.ROLE_Dray, 'Intermodal Dray'],
  [ERole.ROLE_FF, 'Freight Forward'],
  [ERole.ROLE_OFFLINE, 'Offline'],
  [ERole.ROLE_3PL, 'Third Party Provider'],
  [ERole.ROLE_Internal, 'Internal'],
  [ERole.ROLE_Admin, 'Administrator'],
  [ERole.ROLE_BASE_USER, 'Base User']
]);
