import { Action, createReducer, on } from '@ngrx/store';

import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import * as OfflineCustomersActions from '@ruby/modules/customer-select/store/actions/offline-customers.actions';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'offlineCustomers';

export interface State {
  customers: Array<ICustomer>;
  loading: boolean;
  error?: IError;
}

export const initialState: State = {
  customers: [],
  loading: false,
  error: undefined
};

const offlineCustomersReducer = createReducer(
  initialState,
  on(OfflineCustomersActions.loadOfflineCustomers, state => ({
    ...state,
    loading: true,
    error: undefined
  })),
  on(OfflineCustomersActions.loadOfflineCustomersSuccess, (state, action) => ({
    ...state,
    loading: false,
    customers: action.data,
    error: undefined
  })),
  on(OfflineCustomersActions.loadOfflineCustomersFailure, (state, action) => ({
    ...state,
    loading: false,
    customers: [],
    error: action.error
  }))
);

export const reducer = (state: State | undefined, action: Action) => offlineCustomersReducer(state, action);
