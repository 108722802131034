import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StatusCodes } from 'http-status-codes';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { NSErrors } from '@ruby/configs/common.constants';
import { IError } from '@ruby/shared/models/request/error.interface';

@Injectable({
  providedIn: 'root'
})
export class NSRequestInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError((rawError: HttpErrorResponse) => {

      if (rawError.error instanceof Blob && rawError.error.type === 'application/json') {
        return this.parseErrorBlob(rawError);
      }

      let error: IError | HttpErrorResponse;
      switch (rawError.status) {
        case StatusCodes.INTERNAL_SERVER_ERROR:
        case StatusCodes.SERVICE_UNAVAILABLE:
        case StatusCodes.BAD_REQUEST:
        case StatusCodes.BAD_GATEWAY:
        case StatusCodes.NOT_FOUND:
          if (rawError.error && (rawError.error.message || rawError.error.error_description)) {
            error = {
              code: rawError.error.code || String(rawError.status),
              title: rawError.error.title || rawError.error.error,
              message: rawError.error.message || rawError.error.error_description,
              status: rawError.status,
              messages: rawError.error.messages
            };
          } else {
            error = NSErrors.unexpectedError;
          }
          break;
        case StatusCodes.UNAUTHORIZED:
          if (!rawError.error || rawError.error?.error === 'invalid_token') {
            error = NSErrors.unauthorizedError;
          } else {
            error = {
              code: rawError.error.code || String(rawError.status),
              title: rawError.error.title || rawError.error.error,
              message: rawError.error.message || rawError.error.error_description,
              status: rawError.status
            };
          }
          break;
        case StatusCodes.OK:
          error = NSErrors.unexpectedError;
          this.parseTextHtml(rawError);
          break;
        case StatusCodes.GATEWAY_TIMEOUT:
        default :
          error = NSErrors.unexpectedError;
          break;
      }

      return throwError(error);
    }));
  }

  private parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = new Observable((observer) => {
      reader.onloadend = (e: ProgressEvent<FileReader>) => {
        const msg = e.target?.result || '';
        const blobErr = JSON.parse(msg as string);
        observer.error({
          code: blobErr.code,
          title: blobErr.title,
          message: blobErr.message
        });
        observer.complete();
      };
    });
    reader.readAsText(err.error);
    return obs;
  }

  private parseTextHtml(err: HttpErrorResponse): void {
    if (err.error && typeof err.error.text === 'string' && /Your support ID is/.test(err.error.text)) {
      const supportID: Array<string> = err.error.text.match(/Your support ID is: \d*/);
      if (supportID && supportID.length > 0) {
        console.error(`${ supportID[0] } - ${ err.url }`);
      }
    }
  }
}
