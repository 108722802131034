import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate, UnrecoverableStateEvent } from '@angular/service-worker';
import { Store } from '@ngrx/store';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

import * as VersionStatusActions from '@ruby/modules/admin/store/actions/version-status.actions';

@Injectable({
  providedIn: 'root'
})
export class SWCheckForUpdateService {

  constructor(private appRef: ApplicationRef, private updates: SwUpdate, private store: Store) {}

  start(): void {
    this.logUpdates();
    this.checkForUpdates();
    this.updateApp();
    this.handleErrors();
  }

  updateApp(): void {
    this.updates.versionUpdates.subscribe(evt => {
      if (evt.type === 'VERSION_READY') {
        this.updates.activateUpdate().then(() => {
          console.log(`Current app version: ${ evt.currentVersion.hash }`);
          console.log(`New app version ready for use: ${ evt.latestVersion.hash }`);
          this.store.dispatch(VersionStatusActions.setVersionAvailable({ data: evt.latestVersion.hash }));
        });
      }
    });
  }

  checkForUpdates(): void {
    // Allow the app to stabilize first, before starting
    // polling for updates with `interval()`.
    const appIsStable$ = this.appRef.isStable.pipe(first((isStable: boolean) => isStable));
    const everySixHours$ = interval(5 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => {
      console.log('SW Checking for updates');
      this.updates.checkForUpdate();
    });
  }

  logUpdates(): void {
    this.updates.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          console.log(`Downloading new app version: ${ evt.version.hash }`);
          break;
        case 'VERSION_READY':
          console.log(`Current app version: ${ evt.currentVersion.hash }`);
          console.log(`New app version ready for use: ${ evt.latestVersion.hash }`);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          console.log(`Failed to install app version '${ evt.version.hash }': ${ evt.error }`);
          break;
      }
    });
  }

  handleErrors(): void {
    this.updates.unrecoverable.subscribe((event: UnrecoverableStateEvent) => {
      console.log(`An error occurred that we cannot recover from: ${ event.reason } - reloading the page.`);
      document.location.reload();
    });
  }
}
