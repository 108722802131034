import { DefaultPrivacyLevel } from '@datadog/browser-rum';
export const environment = {
  production: true,
  enableMocks: false,
  makeRealCall: {
    login: true,
    gateReceipts: {
      widget: true,
      module: true
    },
    ebill: false,
    ibol: true,
    specialInstructions: true,
    quicklinks: true,
    quickSearch: false,
    userPreferences: true,
    rateInquiry: false,
    commonCatalogs: {
      states: true
    },
    alerts: true,
    customerSelect: true,
    damageFreight: true,
    authorizations: true,
    hljs: true
  },
  dataDogCredentials: {
    applicationId: 'd5b2bf36-ebb0-4c1b-aa04-f63c642c92df',
    clientToken: 'pub7fbbbe48d2431815b856d6e88999ac2f',
    site: 'datadoghq.com',
    service: 'accessns---ruby',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    allowedTracingUrls: [
      /https?:\/\/accessns(qa)?\.nscorp\.com/,
      /https?:\/\/.*\.(web|apps)\.ocp4np\.nscorp\.com/,
      'https://accessns.web.ocp01.nscorp.com',
      'http://localhost:4200'
    ],
    defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT
  }
};
