import { createReducer, on } from '@ngrx/store';

import { IError } from '@ruby/shared/models/request/error.interface';
import * as PartyToActions from '@ruby/store/actions/party-to.actions';

export const featureKey = 'partyTo';

export interface State {
  flag: boolean;
  loading: boolean;
  hasPartyToComplete: boolean;
  error?: IError;
}

export const initialState: State = {
  flag: false,
  loading: false,
  hasPartyToComplete: false,
  error: undefined
};

export const reducer = createReducer(
  initialState,
  on(PartyToActions.checkPermissions, state => ({
    ...initialState,
    hasPartyToComplete: true,
    loading: true
  })),
  on(PartyToActions.checkPermissionsSuccess, (state, action) => ({
    ...state,
    flag: action.data,
    loading: false
  })),
  on(PartyToActions.checkPermissionsFailure, (state, action) => ({
    ...state,
    error: action.error,
    loading: false,
    hasPartyToComplete: false
  })),
  on(PartyToActions.resetPartyToCount, state => ({
    ...state,
    hasPartyToComplete: false
  }))
);
