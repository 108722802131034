import { Overlay } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { MAT_TOOLTIP_SCROLL_STRATEGY } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { CustomizedReportingRequestInterceptor } from '@ruby/core/interceptors/customized-reporting-request.interceptor';
import { NSRequestInterceptor } from '@ruby/core/interceptors/request.interceptor';
import { NsTokenInterceptor } from '@ruby/core/interceptors/token.interceptor';
import { SecurityLayoutModule } from '@ruby/core/layout/security-layout/security-layout.module';

@NgModule({
  declarations: [],
  imports: [
    SecurityLayoutModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      positionClass: 'toast-bottom-right'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NsTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NSRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomizedReportingRequestInterceptor,
      multi: true
    },
    {
      provide: MAT_TOOLTIP_SCROLL_STRATEGY,
      deps: [Overlay],
      useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.close()
    }
  ]
})
export class RootModule {
  constructor(@Optional() @SkipSelf() parentModule: RootModule) {
    if (parentModule) {
      throw new Error(`${ parentModule } has already been loaded. Import Core module in the AppModule only.`);
    }
  }
}
