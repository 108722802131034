import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromAuthorization from '@ruby/modules/security/store/reducers/authorizations.reducer';
import * as fromSecurity from '@ruby/modules/security/store/reducers/security.reducer';
import * as fromUserInformation from '@ruby/modules/security/store/reducers/user-information.reducer';

export const featureKey = 'security';

export interface State {
  [fromSecurity.featureKey]: fromSecurity.State;
  [fromAuthorization.featureKey]: fromAuthorization.State;
  [fromUserInformation.featureKey]: fromUserInformation.State;
}

export const reducer: ActionReducerMap<State> = {
  [fromSecurity.featureKey]: fromSecurity.reducer,
  [fromAuthorization.featureKey]: fromAuthorization.reducer,
  [fromUserInformation.featureKey]: fromUserInformation.reducer
};

export const selectSecurityFeatureState = createFeatureSelector<State>(featureKey);
