<div class="d-flex flex-column h-100 login-bg">
  <main class="ns-login my-5 pt-5">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </main>
  <footer class="fixed-bottom text-white text-center d-flex flex-column mb-5 pb-5 footer-message">
    <span><strong>© 2024 Norfolk Southern Corp.</strong></span>
    <span><strong>Version: {{ version$ | async }}</strong></span>
  </footer>
</div>
