import { createReducer, on } from '@ngrx/store';

import * as CaseDashboardActions from '@ruby/modules/case-management/store/actions/case-dashboard.actions';
import { ICase } from '@ruby/shared/models/case-management/case.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'dashboard';

export interface State {
  cases: Array<ICase>;
  companyCases: Array<ICase>;
  interestedPartyCases: Array<ICase>;
  quickSearchCases: Array<ICase>;
  loading: boolean;
  loadingInterested: boolean;
  loadingCompany: boolean;
  loadingQuickSearchCase: boolean;
  showCaseSummary: boolean;
  selectedIndex: number;
  error?: IError;
}

export const initialState: State = {
  cases: [],
  companyCases: [],
  interestedPartyCases: [],
  quickSearchCases: [],
  loading: true,
  loadingCompany: true,
  loadingInterested: true,
  loadingQuickSearchCase: false,
  showCaseSummary: false,
  selectedIndex: 0,
  error: undefined
};

export const reducer = createReducer(
  initialState,
  on(CaseDashboardActions.loadCases, state => ({
    ...state,
    cases: [],
    loading: true,
    error: undefined
  })),
  on(CaseDashboardActions.loadCasesSuccess, (state, action) => ({
    ...state,
    cases: [ ...state.cases, ...action.data ],
    loading: false
  })),
  on(CaseDashboardActions.loadCasesFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error
  })),
  on(CaseDashboardActions.loadCompanyCases, state => ({
    ...state,
    error: undefined,
    companyCases: [],
    loadingCompany: true
  })),
  on(CaseDashboardActions.loadCompanyCasesSuccess, (state, action) => ({
    ...state,
    loadingCompany: false,
    companyCases: action.data
  })),
  on(CaseDashboardActions.loadCompanyCasesFailure, (state, action) => ({
    ...state,
    loadingCompany: false,
    error: action.error
  })),
  on(CaseDashboardActions.loadInterestedPartyCases, state => ({
    ...state,
    interestedPartyCases: [],
    loadingInterested: true,
    error: undefined
  })),
  on(CaseDashboardActions.loadInterestedPartyCasesSuccess, (state, action) => ({
    ...state,
    loadingInterested: false,
    interestedPartyCases: action.data
  })),
  on(CaseDashboardActions.loadInterestedPartyCasesFailure, (state, action) => ({
    ...state,
    loadingInterested: false,
    error: action.error
  })),
  on(CaseDashboardActions.setSelectedIndex, (state, action) => ({
    ...state,
    selectedIndex: action.data
  })),
  on(CaseDashboardActions.toggleSummary, (state, action) => ({
    ...state,
    showCaseSummary: action.data
  })),
  on(CaseDashboardActions.loadQuickSearchCases, state => ({
    ...state,
    quickSearchCases: [],
    loadingQuickSearchCase: true,
    error: undefined
  })),
  on(CaseDashboardActions.loadQuickSearchCasesSuccess, (state, action) => ({
    ...state,
    loadingQuickSearchCase: false,
    quickSearchCases: action.data
  })),
  on(CaseDashboardActions.loadQuickSearchCasesFailure, (state, action) => ({
    ...state,
    loadingQuickSearchCase: false,
    error: action.error
  })),
  on(CaseDashboardActions.updateCaseData, (state, action) => ({
    ...state,
    cases: state.cases.map((item: ICase) => item.caseId === action.data.caseId ? action.data : item),
    companyCases: state.companyCases.map((item: ICase) => item.caseId === action.data.caseId ? action.data : item),
    interestedPartyCases: state.interestedPartyCases.map((item: ICase) => item.caseId === action.data.caseId ? action.data : item)
  })),
  on(CaseDashboardActions.updateCaseDataNotification, (state, action) => ({
      ...state,
      cases: updateCaseNotification(state.cases, action.caseId),
      companyCases: updateCaseNotification(state.companyCases, action.caseId),
      interestedPartyCases: updateCaseNotification(state.interestedPartyCases, action.caseId)
    }))
);

const updateCaseNotification = (cases: Array<ICase>, caseId: string) =>
  cases.map((item: ICase) => {
    if (item.caseId === caseId) {
      return {
        ...item,
        notificationCommentIds: []
      };
    }
    return item;
  });
