import { Action, createReducer, on } from '@ngrx/store';

import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import * as CustomerActions from '@ruby/modules/customer-select/store/actions/customers.actions';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'customer';

export interface State {
  customers: Array<ICustomer>;
  loading: boolean;
  error?: IError;
  selectedCustomer?: ICustomer;
}

export const initialState: State = {
  customers: [],
  loading: false,
  error: undefined,
  selectedCustomer: undefined
};

const customerReducer = createReducer(
  initialState,
  on(CustomerActions.loadCustomers, state => ({
    ...state,
    loading: true,
    error: undefined
  })),
  on(CustomerActions.loadCustomersSuccess, (state, action) => ({
    ...state,
    loading: false,
    customers: action.data,
    error: undefined
  })),
  on(CustomerActions.loadCustomersFailure, (state, action) => ({
    ...state,
    loading: false,
    customers: [],
    error: action.error
  })),
  on(CustomerActions.selectCustomer, (state, action) => ({
    ...state,
    loading: false,
    selectedCustomer: action.data
  })),
  on(CustomerActions.validateSelectedCustomerSuccess, (state, action) => ({
    ...state,
    customers: action.data
  }))
);

export const reducer = (state: State | undefined, action: Action) => customerReducer(state, action);
