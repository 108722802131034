import { Action, createReducer, on } from '@ngrx/store';

import { IContact } from '@ruby/modules/account/models/contact.interface';
import * as UserInformationActions from '@ruby/modules/security/store/actions/user-information.actions';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'userInformation';

export interface State {
  contact?: IContact;
  loading: boolean;
  error?: IError;
}

export const initialState: State = {
  contact: undefined,
  loading: false,
  error: undefined
};

const userInformationReducer = createReducer(
  initialState,
  on(UserInformationActions.getUserInformation, state => ({
    ...state,
    loading: true
  })),
  on(UserInformationActions.getUserInformationSuccess, (state, action) => ({
    ...state,
    contact: action.data,
    loading: false
  })),
  on(UserInformationActions.getUserInformationFailure, (state, action) => ({
    ...state,
    error: action.error,
    loading: false
  }))
);

export const reducer = (state: State | undefined, action: Action) => userInformationReducer(state, action);
