import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { endOfWeek, getYear, set, startOfMonth, startOfWeek, subDays, subMonths, subYears } from 'date-fns';

@Injectable()
export class NSDateUtils {

  constructor() { }

  static getNSTimezoneDiff(): number {
    const date: Date = new Date(new Date().toLocaleString('en-US'));
    const nsDate = new Date(date.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    let diff = 0;

    if (date.getTime() !== nsDate.getTime()) {
      diff = date.getTime() - nsDate.getTime();
    }

    return diff;
  }

  static convertFromAnyTimezoneToNSDate(value: number | string | Date): Date {
    if (NSDateUtils.getNSTimezoneDiff() > 0) {
      return new Date(new Date(value).getTime() + NSDateUtils.getNSTimezoneDiff());
    } else {
      return new Date(new Date(value).getTime() - NSDateUtils.getNSTimezoneDiff());
    }
  }

  static formatNSDate(value: number | string | Date, formatter: string): string {
    const dateInMilli = NSDateUtils.convertToNSDate(value);
    return formatDate(dateInMilli, formatter, 'en-US');
  }

  static convertToNSDate(value: number | string | Date): Date {
    return new Date(new Date(value).toLocaleString('en-US', { timeZone: 'America/New_York' }));
  }

  static nsStartOfWeek(date: Date | number): Date {
    return startOfWeek(NSDateUtils.convertToNSDate(date), { weekStartsOn: 1 });
  }

  static nsEndOfWeek(date: Date | number): Date {
    return endOfWeek(NSDateUtils.convertToNSDate(date), { weekStartsOn: 1 });
  }

  static nsSetDate(date: Date | number): Date {
    return set(NSDateUtils.convertToNSDate(date), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
  }

  static nsSetEndDate(date: Date | number): Date {
    return set(NSDateUtils.convertToNSDate(date), { hours: 23, minutes: 59, seconds: 0, milliseconds: 0 });
  }

  static nsStartOfMonth(date: Date | number): Date {
    return startOfMonth(NSDateUtils.nsSetDate(date));
  }

  static nsSubMonths(date: Date | number, amount: number): Date {
    return subMonths(NSDateUtils.convertToNSDate(date), amount);
  }

  static nsSubDays(date: Date | number, amount: number): Date {
    return subDays(NSDateUtils.convertToNSDate(date), amount);
  }

  static nsGetYear(date: Date, substract: number = 0): string {
    return getYear(subYears(date, substract)).toString();
  }
}
