import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ESvgIcons } from '@ruby/shared/enums/svg-icons.enums';

@Injectable({
  providedIn: 'root'
})
export class SvgIconsService {
  private readonly baseUrl = '../../../assets/icons/svg';

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.loadConfiguration();
  }

  private loadConfiguration(): void {
    this.matIconRegistry.addSvgIcon(
      ESvgIcons.BAR_ORDER_CONTAINER,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${ this.baseUrl }/bo-container.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      ESvgIcons.BAR_ORDER_CHASSIS,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${ this.baseUrl }/bo-chassis.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      ESvgIcons.BAR_ORDER_TRAILER,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${ this.baseUrl }/bo-trailer.svg`)
    );
    this.matIconRegistry.addSvgIcon(
      ESvgIcons.BAR_ORDER_CONTAINER_CHASSIS,
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${ this.baseUrl }/bo-container-chassis.svg`)
    );
  }
}
