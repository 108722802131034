import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NSUrlsConstants } from '@ruby/configs/urls.constants';
import { TokenService } from '@ruby/core/authentication/token.service';
import { IAccessToken } from '@ruby/shared/models/commons/authentication.interface';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  isAuthenticated = false;

  constructor(private http: HttpClient, private tokenService: TokenService) { }

  getAccessToken(code: string): Observable<IAccessToken | never> {
    return this.http.post(NSUrlsConstants.accessTokenUrl, { code }).pipe(
      map(data => this.processAuthData(data as IAccessToken))
    );
  }

  /**
   * Populate
   *
   * @summary Verify token in session storage with server
   * @returns IAccessToken
   */
  populate(): IAccessToken | undefined {
    if (this.tokenService.getToken()) {
      this.isAuthenticated = true;
      return {
        accessToken: this.tokenService.getToken(),
        idToken: this.tokenService.getIdToken(),
        refreshToken: this.tokenService.getRefreshToken(),
        expiresIn: this.tokenService.getTokenExpiration()
      } as IAccessToken;
    }
    return;
  }

  /**
   * Set Auth
   *
   * @summary Sets the current auth session
   * @param auth: IAccessToken
   * @returns void
   */
  setAuth(auth: IAccessToken): void {
    this.isAuthenticated = true;
    this.tokenService.saveToken(auth.accessToken);
    this.tokenService.saveRefreshToken(auth.refreshToken);
    this.tokenService.saveIdToken(auth.idToken);
    this.tokenService.saveTokenExpiration(auth.expiresIn);
    this.tokenService.saveTokenCreation(Math.floor(new Date().getTime() / 1000));
  }

  /**
   * Purge Auth
   *
   * @summary removes session
   * @returns void
   */
  purgeAuth(): void {
    this.isAuthenticated = false;
    this.tokenService.destroyToken();
  }

  /**
   * Process Auth Data
   *
   * @summary Checks whether credentials are good or not
   * @param data: IAccessToken
   * @throws Error
   * @return IAccessToken
   */
  processAuthData(data: IAccessToken): IAccessToken {
    if (data.accessToken) {
      this.setAuth(data);
      return data;
    }

    throw new Error('Expired Credentials');
  }

  /**
   * Remove Access Token
   *
   * @summary Sends request to remove access token
   * @returns Observable<void | never>
   */
  removeAccessToken(): Observable<void | never> {
    const token = this.tokenService.getToken();
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        Authorization: `Bearer ${ token }`
      })
    };
    return this.http.delete(NSUrlsConstants.logoutUrl, options).pipe(map(() => undefined));
  }

  /**
   * Refresh Token
   *
   * @summary Sends request to refresh the OAut token
   * @param refreshToken: string
   * @returns Observable<IAccessToken | never>
   */
  refreshToken(refreshToken: string): Observable<IAccessToken | never> {
    const options = {};
    const body = { requestRefresh: refreshToken };

    return this.http.post(NSUrlsConstants.refreshTokenUrl, body, options).pipe(
      map(data => this.processAuthData(data as IAccessToken))
    );
  }
}
