import { createSelector } from '@ngrx/store';

import { selectSecurityFeatureState, State } from '@ruby/modules/security/store';
import * as fromAuthorizations from '@ruby/modules/security/store/reducers/authorizations.reducer';
import { EAuthorization } from '@ruby/shared/enums/authorizations.enums';
import { ERole } from '@ruby/shared/enums/roles.enums';

export const selectAuthorizationsState = createSelector(
  selectSecurityFeatureState,
  (securityState: State) => securityState[fromAuthorizations.featureKey]
);

export const selectRoles = createSelector(
  selectSecurityFeatureState,
  (securityState: State) => securityState[fromAuthorizations.featureKey].roles
);

export const selectAuthorizations = createSelector(
  selectSecurityFeatureState,
  (securityState: State) => securityState[fromAuthorizations.featureKey].authorizations
);

export const hasRole = (roleName: ERole) => createSelector(
  selectSecurityFeatureState,
  (securityState: State) => securityState[fromAuthorizations.featureKey].roles.includes(roleName)
);

export const hasRoles = (roleNames: Array<ERole>) => createSelector(
  selectSecurityFeatureState,
  (securityState: State) => roleNames.some(roleName=> securityState[fromAuthorizations.featureKey].roles.includes(roleName))
);

export const hasAuthorization = (authName: EAuthorization) => createSelector(
  selectSecurityFeatureState,
  (securityState: State) => securityState[fromAuthorizations.featureKey].authorizations.includes(authName)
);

export const hasAuthorizations = (authNames: Array<EAuthorization>) => createSelector(
  selectSecurityFeatureState,
  (securityState: State) => authNames.every((authName => securityState[fromAuthorizations.featureKey].authorizations.includes(authName)))
);

export const isInternalRole = createSelector(
  selectSecurityFeatureState,
  (securityState: State) => securityState[fromAuthorizations.featureKey].roles.includes(ERole.ROLE_Internal)
);

export const selectUserRacf = createSelector(
  selectSecurityFeatureState,
  (securityState: State) => securityState[fromAuthorizations.featureKey].userRacf
);
