import { createSelector } from '@ngrx/store';

import { selectSecurityFeatureState, State } from '@ruby/modules/security/store';
import * as fromUserInformation from '@ruby/modules/security/store/reducers/user-information.reducer';

export const selectUserInformationState = createSelector(
  selectSecurityFeatureState,
  (authState: State) => authState[fromUserInformation.featureKey]
);
export const selectUserInformation = createSelector(
  selectSecurityFeatureState,
  (authState: State) => authState[fromUserInformation.featureKey].contact
);
