import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import * as SecuritySelectors from '@ruby/modules/security/store/selectors/security.selectors';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  ansLadingUrl = '/redirect/main';

  constructor(private router: Router, private store: Store) { }

  /**
   * Can Activate
   *
   * @summary Checks If The User Can Navigate To The Url
   * @param next ActivatedRouteSnapshot
   * @param state RouterStateSnapshot
   * @returns Observable<boolean | UrlTree>
   */
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(SecuritySelectors.selectSecurityState).pipe(
      map(authState => {
        if (!authState.isAuthenticated) {
          this.router.navigate([this.ansLadingUrl], {
            queryParams: {
              state: encodeURIComponent(state.url)
            }
          });
        }

        return authState.isAuthenticated;
      })
    );
  }
}
