import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor() { }

  /**
   * Get Token
   *
   * @summary get the token from session storage
   * @returns string
   */
  getToken(): string {
    return window.sessionStorage['ns-token'];
  }

  /**
   * Save Token
   *
   * @summary Saves the token in session storage
   * @param token: string
   * @returns: void
   */
  saveToken(token: string): void {
    window.sessionStorage['ns-token'] = token;
  }

  /**
   * Get Refresh Token
   *
   * @summary get the refresh token from session storage
   * @returns string
   */
  getRefreshToken(): string {
    return window.sessionStorage['ns-refresh-token'];
  }

  /**
   * Save Refresh Token
   *
   * @summary Saves the refresh token in session storage
   * @param token: string
   * @returns: void
   */
  saveRefreshToken(token: string): void {
    window.sessionStorage['ns-refresh-token'] = token;
  }

  /**
   * Get Token Expiration
   *
   * @summary get the token expiration from session storage
   * @returns number
   */
  getTokenExpiration(): number {
    return parseInt(window.sessionStorage['ns-token-exp'], 10);
  }

  /**
   * Save Token Expiration
   *
   * @summary Saves the token in session storage
   * @param exp: number
   * @returns: void
   */
  saveTokenExpiration(exp: number): void {
    window.sessionStorage['ns-token-exp'] = exp;
  }

  /**
   * Get Token Creation
   *
   * @summary get the token creation from session storage
   * @returns number
   */
  getTokenCreation(): number {
    return parseInt(window.sessionStorage['ns-token-created'], 10);
  }

  /**
   * Save Token Creation
   *
   * @summary Saves the creation token in session storage
   * @param creation: number
   * @returns: void
   */
  saveTokenCreation(creation: number): void {
    window.sessionStorage['ns-token-created'] = creation;
  }

  /**
   * Get ID Token
   *
   * @summary get the id token from session storage
   * @returns string
   */
  getIdToken(): string {
    return window.sessionStorage['ns-id-token'];
  }

  /**
   * Save ID Token
   *
   * @summary Saves the id token in session storage
   * @param token: string
   * @returns: void
   */
  saveIdToken(token: string): void {
    window.sessionStorage['ns-id-token'] = token;
  }

  /**
   * Destroy Token
   *
   * @summary removes the token from session
   * @returns void
   */
  destroyToken(): void {
    window.sessionStorage.removeItem('ns-token');
    window.sessionStorage.removeItem('ns-refresh-token');
    window.sessionStorage.removeItem('ns-token-exp');
    window.sessionStorage.removeItem('ns-token-created');
    window.sessionStorage.removeItem('ns-id-token');
  }
}
