import { Action, createReducer, on } from '@ngrx/store';

import { IError } from '@ruby/shared/models/request/error.interface';
import { IUserPreference } from '@ruby/shared/models/user-preferences/user-preference.interface';
import * as UserPreferencesActions from '@ruby/store/actions/user-preference.actions';

export const featureKey = 'userPreferences';

export interface State {
  userPreferences: {
    [key: string]: IUserPreference;
  };
  loading: boolean;
  errors: Array<IError>;
}

export const initialState: State = {
  userPreferences: {},
  loading: false,
  errors: []
};

const mapPreference = (preference: IUserPreference) => ({
  screenName: preference.screenName,
  preferenceName: preference.preferenceName,
  preferenceValue: preference.preferenceValue,
  id: preference.id,
  loading: false
});

const mapPreferencesList = (preferences: Array<IUserPreference>) => {
  const preferencesObj: { [key: string]: IUserPreference } = {};
  for (const preference of preferences) {
    preferencesObj[preference.preferenceName] = mapPreference(preference);
  }
  return preferencesObj;
};

const userPreferenceReducer = createReducer(
  initialState,
  on(UserPreferencesActions.loadUserPreference, (state, action) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences,
      [action.data.preferenceName]: {
        screenName: action.data.screenName,
        preferenceName: action.data.preferenceName,
        loading: true,
        loadCompleted: false
      }
    },
    loading: true
  })),
  on(UserPreferencesActions.loadUserPreferenceSuccess, (state, action) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences,
      [action.data.preferenceName]: {
        ...state.userPreferences[action.data.preferenceName],
        preferenceValue: action.data.preferenceValue,
        id: action.data.id,
        loading: false,
        loadCompleted: true
      }
    },
    loading: false
  })),
  on(UserPreferencesActions.loadUserPreferenceFailure, (state, action) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences,
      [action.data.preferenceName]: {
        ...state.userPreferences[action.data.preferenceName],
        loading: false,
        error: action.error
      }
    },
    loading: false,
    errors: [action.error, ...state.errors]
  })),
  on(UserPreferencesActions.saveUserPreferenceSuccess, (state, action) => ({
    ...state,
    userPreferences: {
      ...state.userPreferences,
      [action.data.preferenceName]: {
        ...state.userPreferences[action.data.preferenceName],
        preferenceValue: action.data.preferenceValue,
        id: action.data.id,
        loading: false
      }
    },
    loading: false
  })),
  on(UserPreferencesActions.removeUserPreference, (state, action) => ({
      ...state,
      userPreferences: {
        ...state.userPreferences,
        [action.data.preferenceName]: {
          ...state.userPreferences[action.data.preferenceName],
          deleting: true
        }
      },
      loading: false
    })),
  on(UserPreferencesActions.removeUserPreferenceSuccess, (state, action) => {
    const allPreferences = { ...state.userPreferences };
    delete allPreferences[action.data.preferenceName];
    return {
      ...state,
      userPreferences: allPreferences,
      loading: false
    };
  }),
  on(UserPreferencesActions.removeUserPreferenceFailure, (state, action) => ({
      ...state,
      userPreferences: {
        ...state.userPreferences,
        [action.data.preferenceName]: {
          ...state.userPreferences[action.data.preferenceName],
          deleting: false,
          error: action.error
        }
      }
    })),
  on(UserPreferencesActions.loadAllUserPreferences, (state, action) => ({
    ...state,
    loading: true,
    errors: []
  })),
  on(UserPreferencesActions.loadAllUserPreferencesSuccess, (state, action) => ({
    ...state,
    userPreferences: mapPreferencesList(action.data),
    loading: false
  })),
  on(UserPreferencesActions.loadAllUserPreferencesFailure, (state, action) => ({
    ...state,
    loading: false,
    errors: [action.error, ...state.errors]
  })),
  on(UserPreferencesActions.removeAllUserPreferences, (state, action) => ({
    ...state,
    loading: true,
    errors: []
  })),
  on(UserPreferencesActions.removeAllUserPreferencesSuccess, (state, action) => ({
    ...state,
    userPreferences: {},
    loading: false
  })),
  on(UserPreferencesActions.removeAllUserPreferencesFailure, (state, action) => ({
    ...state,
    loading: false,
    errors: [action.error, ...state.errors]
  }))
);

export const reducer = (state: State | undefined, action: Action) => userPreferenceReducer(state, action);
