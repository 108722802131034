export enum EGridOrigin {
  QUICK_SEARCH = 'quickSearch',
  WATCHLIST = 'watchlist',
  PIPELINE = 'pipeline',
  TYPE = 'type'
}

export enum EGridType {
  //quick search
  TRACK_AND_TRACE_UMLER = 'TRACK_AND_TRACE_UMLER',
  TRACK_AND_TRACE = 'TRACK_AND_TRACE',
  QUICK_DRAY = 'QUICK_DRAY',
  UMLER = 'UMLER',
  WAYBILL_VERIFICATION = 'WAYBILL_VERIFICATION',
  WAYBILL_HISTORY = 'WAYBILL_HISTORY',
  SCALE_WEIGHTS = 'SCALE_WEIGHTS',
  EVENT_HISTORY = 'EVENT_HISTORY',
  //other
  RC_EVENT_CODE = 'EVENT_CODE',
  RC_COMMODITY = 'COMMODITY',
  RC_STATION = 'STATION',
  RC_UMLER = 'UMLER',
  RC_AUDIT_HISTORY = 'AUDIT_HISTORY',
  DIVERSIONS_TRACK_TRACE = 'DIVERSIONS_TRACK_TRACE',
  DIVERSIONS_ROUTING_DETAILS = 'DIVERSIONS_ROUTING_DETAILS',
  WATCHLIST = 'WATCHLIST',
  PIPELINE = 'pipeline',
  HLJS_PREVIEW = 'HLJS_PREVIEW',
  HLJS_DETAILS = 'HLJS_DETAILS',
  HLJS_SUMMARY = 'HLJS_SUMMARY',
  BAD_ORDERS = 'BAD_ORDERS',
  BAD_ORDERS_SUMMARY = 'BAD_ORDERS_SUMMARY',
  AT_ORIGIN = 'AT_ORIGIN',
  IM_AT_ORIGIN = 'IM_AT_ORIGIN',
  UT_AT_ORIGIN = 'UT_AT_ORIGIN',
  EN_ROUTE = 'EN_ROUTE',
  DRAY_AT_ORIGIN = 'DRAY_AT_ORIGIN',
  DRAY_EN_ROUTE = 'DRAY_EN_ROUTE',
  DRAY_AT_DESTINATION = 'DRAY_AT_DESTINATION',
  ENROUTE = 'ENROUTE',
  IM_AT_DESTINATION = 'IM_AT_DESTINATION',
  UT_AT_DESTINATION = 'UT_AT_DESTINATION',
  AT_DESTINATION = 'AT_DESTINATION',
  ON_STREET = 'ON_STREET',
  DRAY_ON_STREET = 'DRAY_ON_STREET',
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
  INTERCHANGE = 'INTERCHANGE',
  ACTIVE_SHIPMENTS = 'ACTIVE_SHIPMENTS',
  IM_ACTIVE_SHIPMENT = 'IM_ACTIVE_SHIPMENT',
  DRAY_ACTIVE_SHIPMENT = 'DRAY_ACTIVE_SHIPMENT',
  UT_ACTIVE_SHIPMENT = 'UT_ACTIVE_SHIPMENT',
  ENROUTE_ORIGIN = 'ENROUTE_ORIGIN',
  ENROUTE_DESTINATION = 'ENROUTE_DESTINATION',
  OFFLINE_RECEIVING = 'OFFLINE_RECEIVING',
  OFFLINE_SHIPPING = 'OFFLINE_SHIPPING',
  BILL_TO = 'BILL_TO',
  SPECIAL_INSTRUCTIONS = 'SPECIAL_INSTRUCTIONS',
  ORDER_FOR_PLACEMENT = 'ORDER_FOR_PLACEMENT',
  RELEASE_SWITCH = 'RELEASE_SWITCH',
  QUANTITY_ORDER = 'QUANTITY_ORDER',
  LEASE_TRACK = 'LEASE_TRACK',
  AVAILABLE_FOR_PLACEMENT = 'AVAILABLE_FOR_PLACEMENT',
  RECEIVING_QUANTITY_ORDER = 'RECEIVING_QUANTITY_ORDER',
  SERVICE_IN_PROGRESS = 'SERVICE_IN_PROGRESS',
  RECEIVING_PIPELINE = 'RECEIVING_PIPELINE',
  INTRA_PLANT_SWITCH = 'INTRA_PLANT_SWITCH',
  INTRA_TERMINAL_SWITCH = 'INTRA_TERMINAL_SWITCH',
  PULL_TURN = 'PULL_TURN',
  PULL_WEIGHT = 'PULL_WEIGHT',
  ORDER_NOT_USED = 'ORDER_NOT_USED',
  CHARGEABLE_REASON_CODE = 'CHARGEABLE_REASON_CODE',
  LATE_SURRENDER = 'LATE_SURRENDER',
  LEASE_TRACK_OVERAGES = 'LEASE_TRACK_OVERAGES',
  IBOL_ROUTING_DETAILS = 'IBOL_ROUTING_DETAILS',
  IBOL_SAVED_BOLS = 'IBOL_GRID_SAVED_BOLS',
  IBOL_REPORTS = 'IBOL_GRID_DAILY_ACTIVITY_REPORTS',
  IBOL_CREATED_BILLS = 'IBOL_GRID_CREATED_BILLS',
  IBOL_PATTERNS = 'IBOL_GRID_PATTERNS',
  IBOL_CUSTOMER_ADDRESS = 'IBOL_PATTERN_FORM_SELECT_CUSTOMER',
  TERMINATING = 'TERMINATING',
  TERMINATING_DEMURRAGE = 'TERMINATING_DEMURRAGE',
  ORIGINATING = 'ORIGINATING',
  ORIGINATING_DEMURRAGE = 'ORIGINATING_DEMURRAGE',
  ORIGINATING_STORAGE = 'ORIGINATING_STORAGE',
  PENDING_DISPUTES = 'PENDING_DISPUTES',
  DISPUTE_HISTORY = 'DISPUTE_HISTORY',
  DEMURRAGE_DISPUTE_HISTORY = 'DEMURRAGE_DISPUTE_HISTORY',
  DEMURRAGE_PENDING_DISPUTES = 'DEMURRAGE_PENDING_DISPUTES',
  DEMURRAGE_TERMINATING = 'DEMURRAGE_TERMINATING',
  DEMURRAGE_ORIGINATING = 'DEMURRAGE_ORIGINATING',
  PRIVATE_CAR_STORAGE = 'PRIVATE_CAR_STORAGE',
  ASSIGNED_CAR_STORAGE = 'ASSIGNED_CAR_STORAGE',
  UT_SHIPPING_LISTING = 'UT_SHIPPING_LISTING',
  UT_FLEET_INQUIRY = 'UT_FLEET_INQUIRY',
  UT_TRAIN_SET_INQUIRY = 'UT_TRAIN_SET_INQUIRY',
  UT_TRAIN_CONSIST = 'UT_TRAIN_CONSIST',
  UT_LOAD_TRAIN = 'UT_LOAD_TRAIN',
  UT_RELEASE_EMPTIES = 'UT_RELEASE_EMPTIES',
  UT_SHIPMENT_STATUS = 'UT_SHIPMENT_STATUS',
  UT_HOPPER_RUN = 'UT_HOPPER_RUN',
  UT_TRAIN_SIZE_PERFORMANCE = 'UT_TRAIN_SIZE_PERFORMANCE',
  UT_MAPPING = 'UT_MAPPING',
  UT_VESSEL_CALENDAR = 'UT_VESSEL_CALENDAR',
  UT_MONTHLY_CALENDAR = 'UT_MONTHLY_CALENDAR',
  UT_WEEKLY_CALENDAR = 'UT_WEEKLY_CALENDAR',
  UT_COAL_ORDER_FULFILLMENT = 'UT_COAL_ORDER_FULFILLMENT',
  UT_CYCLE_REPORT = 'UT_CYCLE_REPORT',
  UT_REVIEW_DETENTION = 'UT_REVIEW_DETENTION',
  UT_REVIEW_DISPUTES = 'UT_REVIEW_DISPUTES',
  UT_48_HOUR_REPORT = 'UT_48_HOUR_REPORT',
  ON_TRACK = 'ON_TRACK',
  PLACE_DETAILS = 'PLACE_DETAILS',
  PULL_DETAILS = 'PULL_DETAILS',
  SWITCH_DETAILS = 'SWITCH_DETAILS',
  UNIT_HOLDS = 'UNIT_HOLDS',
  STORAGE_GUARANTEE = 'STORAGE',
  LIFT_GUARANTEE = 'LIFT',
  SHIPPING_INVENTORY = 'SHIPPING_INVENTORY',
  HISTORY_INTRA_PLANT_SWITCH = 'HISTORY_INTRA_PLANT_SWITCH',
  HISTORY_INTRA_TERMINAL_SWITCH = 'HISTORY_INTRA_TERMINAL_SWITCH',
  HISTORY_PULL_TURN = 'HISTORY_PULL_TURN',
  HISTORY_PULL_WEIGHT = 'HISTORY_PULL_WEIGHT',
  HISTORY_ORDER_NOT_USED = 'HISTORY_ORDER_NOT_USED',
  HISTORY_CHARGEABLE_REASON_CODE = 'HISTORY_CHARGEABLE_REASON_CODE',
  HISTORY_LATE_SURRENDER = 'HISTORY_LATE_SURRENDER',
  HISTORY_LEASE_TRACK_OVERAGES = 'HISTORY_LEASE_TRACK_OVERAGES',
  HISTORICAL_TERMINATING_DEMURRAGE = 'HISTORICAL_TERMINATING_DEMURRAGE',
  HISTORICAL_ORIGINATING_DEMURRAGE = 'HISTORICAL_ORIGINATING_DEMURRAGE',
  HISTORICAL_ORIGINATING_STORAGE = 'HISTORICAL_ORIGINATING_STORAGE',
  HISTORICAL_ASSIGNED_CAR_STORAGE = 'HISTORICAL_ASSIGNED_CAR_STORAGE',
  HISTORICAL_PRIVATE_CAR_STORAGE = 'HISTORICAL_PRIVATE_CAR_STORAGE',
  OUTGATED = 'OUTGATED',
  INGATED = 'INGATED',
  CAR_TRAFFIC_THREE_YEAR_HISTORY = 'CAR_TRAFFIC_THREE_YEAR_HISTORY',
  PULLED = 'PULLED',
  LOADED = 'LOADED',
  WEEKLY_SNAPSHOT = 'WEEKLY_SNAPSHOT',
  WEEKLY_METRICS = 'WEEKLY_METRICS',
  REVENUE_CONTRACT_COMPLIANCE_SUMMARY = 'REVENUE_CONTRACT_COMPLIANCE_SUMMARY',
  REVENUE_CONTRACT_SUMMARY_BREAKDOWN = 'REVENUE_CONTRACT_SUMMARY_BREAKDOWN',
  REVENUE_CONTRACT_CANDIDATE_DETAILS = 'REVENUE_CONTRACT_CANDIDATE_DETAILS',
  REVENUE_ALL_NS_CONTRACTS = 'REVENUE_ALL_NS_CONTRACTS',
  REVENUE_CLAIMS_ALL = 'REVENUE_CLAIMS_ALL',
  REVENUE_CLAIMS_RECORDS = 'REVENUE_CLAIMS_RECORDS',
  REVENUE_CLAIMS_BY_STATUS_ALL = 'REVENUE_CLAIMS_BY_STATUS_ALL',
  DAMAGE_FREIGHT = 'DAMAGE',
  ECR_PATTERNS = 'ECR_PATTERNS',
  THIRD_PARTY = 'THIRD_PARTY',
  EXCEPTION = 'EXCEPTION',
  TERMINAL_DETAIL = 'TERMINAL_DETAIL',
  D_WELL = 'D_WELL',
  REVENUE_VOLUME_CONTRACTS = 'REVENUE_VOLUME_CONTRACTS',
  REVENUE_VOLUME_CONTRACT_PERIODS = 'REVENUE_VOLUME_CONTRACT_PERIODS',
  REVENUE_VOLUME_CONTRACT_PERIOD_DETAILS = 'REVENUE_VOLUME_CONTRACT_PERIOD_DETAILS',
  GUARANTEE_HISTORY = 'GUARANTEE_HISTORY',
  IP_ACTIVE_SHIPMENT = 'IP_ACTIVE_SHIPMENT',
  EXCEPTIONS = 'EXCEPTIONS',
  REPORT_BUILDER_MIGRATED_REPORTS = 'REPORT_BUILDER_MIGRATED_REPORTS',
  DRIVER_ACTIVITY = 'DRIVER_ACTIVITY',
  LEFT_BEHINDS = 'LEFT_BEHINDS',
  IARS_DETAILS = 'DETAILS_FOR_UNITS_BY_LANE',
  MONTHLY_SUMMARY ='MONTHLY_LANE_SUMMARY',
  IARS_DAILY_SUMMARY = 'IARS_DAILY_SUMMARY',
  IARS_REQUEST_RESERVATIONS = 'IARS_REQUEST_RESERVATIONS',
  IARS_CANCEL_RESERVATION = 'IARS_CANCEL_RESERVATION',
  IARS_ALLOCATIONS_CHANGES_OVER_TIME = 'IARS_ALLOCATIONS_CHANGES_OVER_TIME',
  IARS_RESERVATIONS_OVER_TIME = 'IARS_RESERVATIONS_OVER_TIME',
  IARS_LINE_UTILIZATION = 'IARS_LINE_UTILIZATION',
  CASE_CREATE_EQUIPMENTS = 'CASE_CREATE_EQUIPMENTS'
}

export enum EGridTitle {
  TRACK_AND_TRACE_UMLER = 'Track and Trace/Umler',
  TRACK_AND_TRACE = 'Track and Trace',
  DIVERSIONS_ROUTING_DETAILS = 'Junction Selection',
  DIVERSIONS_SUBMIT_DETAILS = 'Submit Details',
  DIVERSIONS_COMMODITY = 'Commodities',
  QUICK_DRAY = 'Quick Dray',
  UMLER = 'Umler',
  RC_EVENT_CODE = 'Event Codes',
  RC_COMMODITY = 'Commodity',
  RC_STATION = 'Location Code',
  RC_UMLER = 'UMLER Report',
  RC_AUDIT_HISTORY = 'Audit History for Rail Emissions',
  WAYBILL_HISTORY = 'Waybill History',
  WAYBILL_VERIFICATION = 'Waybill Verification',
  WATCHLIST = 'Watchlist',
  SCALE_WEIGHTS = 'Scale Weights',
  EVENT_HISTORY = 'Event History',
  HLJS_DETAILS = 'Statement Details',
  HLJS_SUMMARY = 'Statements Summary',
  ONSITE_INVENTORY = 'Onsite Inventory',
  RECEIVING_INVENTORY = 'Receiving Inventory',
  INBOUND_INVENTORY = 'Inbound Inventory',
  INVENTORY_MANAGEMENT = 'Inventory Management',
  ACCESSORIAL_CHARGES = 'Accessorial Charges',
  ACCESSORIAL_CHARGE_HISTORY = 'Accessorial Charge History',
  ON_TRACK = 'On Track',
  TRACK_BY_CLASS = 'Track By Class',
  SHIPPING_INVENTORY = 'Shipping Inventory',
  EXPIRING_PUBLICATIONS = 'Price Publications Expiring Within the Next 30 days',
  SERVICE_REQUESTS = 'Service Requests',
  AVAILABLE_FOR_PLACEMENT = 'Available For Placement',
  PIPELINE = 'Pipeline',
  SERVICE_IN_PROGRESS = 'Service In Progress',
  LEASE_TRACK = 'Lease Track',
  QUANTITY_ORDER = 'Quantity Order',
  LEASE_TRACK_OVERAGES = 'Lease Track Overages',
  IBOL_SAVED_BOLS = 'Saved Unsent',
  IBOL_ROUTING_DETAILS = 'Junction Selection',
  IBOL_REPORTS = 'Daily Activity Report',
  IBOL_CREATED_BILLS = 'Created Bills',
  IBOL_PATTERNS = 'Pattern Selection',
  IBOL_CUSTOMER_ADDRESS = 'Select New',
  LEASE_TRACK_MONITORED = 'Lease Track Monitored',
  RELEASE_SWITCH = 'Release Switch',
  INVENTORY_ORDER = 'Order',
  DEMURRAGE_CANDIDATE = 'Demurrage Candidates',
  STORAGE_CANDIDATE = 'Storage Candidates',
  HISTORICAL_INVENTORY = 'Demurrage/Storage Billing History',
  DISPUTE_EVENTS = 'Dispute Events',
  UT_SHIPPING_LISTING = 'Shipping Listing',
  STORAGE_GUARANTEE = 'Storage Guarantee',
  LIFT_GUARANTEE = 'Lift Guarantee',
  ACTIVE_SHIPMENTS = 'Active Shipments',
  BAD_ORDERS = 'Bad Orders',
  WEEKLY_SNAPSHOT = 'Weekly Snapshot',
  WEEKLY_METRICS = 'Weekly Metrics',
  HOLDS = 'Current Open Holds:',
  ECR_PATTERNS = 'ECR All Requests',
  TERMINAL_DETAIL = 'Terminal Detail',
  D_WELL = 'Units Available for Pickup',
  GUARANTEE_HISTORY = 'Guarantee History',
  REPORT_BUILDER_MIGRATED_REPORTS = 'Migrated Reports',
  DRIVER_ACTIVITY = 'Driver Activity',
  LEFT_BEHINDS = 'Left Behinds',
  IARS_DETAILS = 'Unit Reservation Details',
  IARS_MONTHLY_SUMMARY ='Lane Utilization Summary by Month',
  IARS_DAILY_SUMMARY = 'Lane Utilization Summary by Day',
  IARS_REQUEST_RESERVATIONS = 'Request Reservations',
  IARS_ALLOCATIONS_CHANGES_OVER_TIME = 'Allocation Changes Over Time',
  IARS_RESERVATIONS_OVER_TIME = 'Requested Reservations Over Time',
  IARS_LINE_UTILIZATION = 'Units and Usage Over Time',
  CASE_CREATE_EQUIPMENTS = 'Create Support Case Equipments'
}

export enum EGridPreferencesActionsSource {
  COLUMNS_MOVE= 'COLUMNS_MOVE',
  USER_SAVE= 'USER_SAVE'
}
