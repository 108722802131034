import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NSUrlsConstants } from '@ruby/configs/urls.constants';
import { IApplicationRequest } from '@ruby/modules/account/models/application-request.interface';
import { IApplication } from '@ruby/modules/account/models/application.interface';
import { IContact } from '@ruby/modules/account/models/contact.interface';
import { ICloneCoworkerInformation } from '@ruby/modules/clone-coworker/models/clone-coworker.interface';
import {
  IAccessToken,
  IChallengeQts,
  IChallengeQuestions,
  IQuestion,
  IQuestionsAnswers,
  IResetPassword,
  IResetStatus
} from '@ruby/shared/models/commons/authentication.interface';
import { ICatalogItem } from '@ruby/shared/models/commons/catalog-item.interface';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  constructor(private http: HttpClient) { }

  /**
   * Check Reset Questions
   *
   * @summary Gets the challenge questions status if needed to reset
   * @param userRacf string
   * @returns Observable<IResetStatus | never>
   */
  checkStatus(userRacf: string): Observable<IResetStatus | never> {
    return this.http.get(`${ NSUrlsConstants.securityQuestionsUrl }/${ userRacf }/status`).pipe(map(response => response as IResetStatus));
  }

  /**
   * Fetch Questions By UserId
   *
   * @summary Fetch the user security questions
   * @param userRacf: string
   * @returns Observable<IQuestions | never>
   */
  fetchQuestionsByUserId(userRacf: string): Observable<Array<IQuestion> | never> {
    return this.http.get(`${ NSUrlsConstants.securityQuestionsUrl }/${ userRacf }`).pipe(
      map(item => (item as Array<string>).map(question => ({ question } as IQuestion)))
    );
  }

  /**
   * Challenge Questions Answers
   *
   * @summary post challenge questions and answers for forgot password
   * @param questionsAnswers: IQuestionsAnswers
   * @param userRacf: string
   * @returns Observable<void | never>
   */
  challengeQuestionsAnswers(questionsAnswers: IQuestionsAnswers, userRacf: string): Observable<void | never> {
    return this.http.post(`${ NSUrlsConstants.securityQuestionsUrl }/${ userRacf }/answer`, questionsAnswers).pipe(map(() => undefined));
  }

  /**
   * Change Password
   *
   * @summary sends the updated password to the backend
   * @param resetPassword: IPassword
   * @returns Observable<IAccessToken | never>
   */
  changePassword(resetPassword: IResetPassword): Observable<IAccessToken | never> {
    return this.http.post<IAccessToken>(`${ NSUrlsConstants.resetPasswordUrl }`, resetPassword);
  }

  /**
   * Fetch All Challenge Questions
   *
   * @summary Gets all the questions to set up
   * @returns Observable<IChallengeQuestions | never>
   */
  fetchAllChallengeQuestions(): Observable<IChallengeQuestions | never> {
    return this.http.get(`${ NSUrlsConstants.securityQuestionsUrl }/parameters`).pipe(
      map(item => ({
        challengeQuestions: (item as IChallengeQts).challengeQuestions.map(question => ({ question } as IQuestion)),
        maximumChallengeQuestions: (item as IChallengeQts).maximumChallengeQuestions,
        minimumChallengeQuestions: (item as IChallengeQts).minimumChallengeQuestions
      }))
    );
  }

  /**
   * Submit Challenge Questions
   *
   * @summary Sends the user challenge questions and answers
   * @param challengeQuestions: IChallengeQuestions
   * @returns Observable<void | never>
   */
  submitChallengeQuestions(challengeQuestions: IChallengeQuestions): Observable<void | never> {
    return this.http.post(`${ NSUrlsConstants.securityQuestionsUrl }`, challengeQuestions).pipe(map(() => undefined));
  }

  /**
   * Get user information external
   *
   * @summary Gets user information for an external user
   * @returns Observable<IContact | never>
   */
  getUserInformationExternal(): Observable<IContact | never> {
    return this.http.get(`${ NSUrlsConstants.userInformationExternal }`).pipe(map(response => response as IContact));
  }

  /**
   * Get user information internal
   *
   * @summary Gets user information for an internal user
   * @returns Observable<IContact | never>
   */
  getUserInformationInternal(): Observable<IContact | never> {
    return this.http.get(`${ NSUrlsConstants.userInformationInternal }`).pipe(map(response => response as IContact));
  }

  /**
   * Update user information external
   *
   * @summary Update user information for an external user
   * @returns Observable<IContact | never>
   */
  updateUserInformationExternal(contact: IContact): Observable<IContact | never> {
    return this.http.put(`${ NSUrlsConstants.userInformationExternal }`, contact).pipe(map(response => response as IContact));
  }

  /**
   * Get Primary Company Contacts
   *
   * @summary Get current user primary company contacts
   * @returns Observable<Array<IContact> | never>
   */
  getPrimaryCompanyContacts(): Observable<Array<IContact> | never> {
    return this.http.get(`${ NSUrlsConstants.userCustomerContacts }`).pipe(map(response => response as Array<IContact>));
  }

  /**
   * Get Current Applications
   *
   * @summary Get user current applications
   * @returns Observable<Array<IApplication> | never>
   */
  getCurrentApplications(): Observable<Array<IApplication> | never> {
    return this.http.get(NSUrlsConstants.currentApplicationsUrl).pipe(map(response => response as Array<IApplication>));
  }

  /**
   * Get Available Applications
   *
   * @summary Get user available applications
   * @returns Observable<Array<IApplication> | never>
   */
  getAvailableApplications(): Observable<Array<IApplication> | never> {
    return this.http.get(NSUrlsConstants.availableApplicationsUrl).pipe(map(response => response as Array<IApplication>));
  }

  /**
   * Request Application
   *
   * @summary will request an application
   * @param request: IApplicationRequest
   * @returns Observable<void | never>
   */
  requestApplication(request: IApplicationRequest): Observable<void | never> {
    return this.http.post(NSUrlsConstants.requestApplicationUrl, request).pipe(map(() => undefined));
  }

  /**
   * Get Roles
   *
   * @summary Gets account roles
   * @returns Observable<Array<ICatalogItem> | never>
   */
  getRoles(): Observable<Array<ICatalogItem> | never> {
    return this.http.get(NSUrlsConstants.rolesUrl).pipe(map(response => response as Array<ICatalogItem>));
  }

  /**
   * Get User Comparison
   *
   * @summary Get current user comparison information
   * @param coworkerRacf: string
   * @returns Observable<Array<ICloneCoworkerInformation> | never>
   */
  getUserComparison(coworkerRacf: string): Observable<ICloneCoworkerInformation | never> {
    return this.http.get(`${ NSUrlsConstants.userManagementUserDifferencesUrl }/compare/coworker-racf/${ coworkerRacf }`)
      .pipe(map(response => response as ICloneCoworkerInformation));
  }

  /**
   * Clone Coworker
   *
   * @summary will clone the given coworker
   * @param racf: string
   * @returns Observable<ICloneCoworkerInformation | never>
   */
  cloneCoworker(racf: string): Observable<ICloneCoworkerInformation | never> {
    return this.http.post(`${ NSUrlsConstants.userManagementCloneCoworkerUrl }/${ racf }`, {}).pipe(map(response => response as ICloneCoworkerInformation));
  }
}
