import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Store } from '@ngrx/store';
import { EPersona } from '@norfolk-southern/accessns-components';
import { Observable } from 'rxjs';
import { exhaustMap, filter, map, tap } from 'rxjs/operators';

import { NSCommons, NSCommonConstants } from '@ruby/configs/common.constants';
import { CommonsService } from '@ruby/core/http/commons/commons.service';
import {
  ICaseCreated,
  ICaseCreation,
  ICaseCreationFormState,
  ICaseCreationResult
} from '@ruby/modules/case-management/models/case-create.interface';
import * as CaseCreateActions from '@ruby/modules/case-management/store/actions/case-create.actions';
import * as fromCaseCreate from '@ruby/modules/case-management/store/reducers/case-create.reducer';
import * as CaseCreateSelectors from '@ruby/modules/case-management/store/selectors/case-create.selectors';
import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { EUserPreferences } from '@ruby/shared/enums/user-preferences.enums';
import { IGridCriteria, IGridResult } from '@ruby/shared/models/commons/grid.interface';
import { ITransaction } from '@ruby/shared/models/commons/transaction.interface';
import { UtilsService } from '@ruby/shared/services/utils/utils.service';
import { AppState } from '@ruby/store';
import * as PartyToActions from '@ruby/store/actions/party-to.actions';
import * as UserPreferencesActions from '@ruby/store/actions/user-preference.actions';
import { selectUserPreferenceState } from '@ruby/store/selectors/user-preferences.selectors';

@Component({
  selector: 'ng-case-create-container',
  templateUrl: './case-create.container.html',
  styleUrls: ['./case-create.container.scss']
})
export class CaseCreateContainer {
  selectedCustomer?: ICustomer;
  customerSelectedPersona?: EPersona;
  caseCreated$!: Observable<ICaseCreated | undefined>;
  equipments$: Observable<string>;
  gridResults$: Observable<IGridResult | undefined> ;
  store$: Observable<{
    state: fromCaseCreate.State; customer?: ICustomer;
    isAutomotive: boolean; isIPNoTyesPermissions: boolean; is3PL: boolean; hasOCM: boolean;
  }>;
  storePreference$: Observable<Array<{ customerIdentifier: string; customerName: string }>>;

  constructor(
    private readonly appStore: Store<AppState>,
    private readonly activatedRoute: ActivatedRoute,
    private readonly utilsSvc: UtilsService,
    private readonly commonsSvc: CommonsService
  ) {
    this.store$ = this.appStore.select(CaseCreateSelectors.selectManagementWithCustomerInfoState).pipe(
      tap((st: {
          state: fromCaseCreate.State;
          customer?: ICustomer;
          isAutomotive: boolean;
          isIPNoTyesPermissions: boolean;
          is3PL: boolean;
          hasOCM: boolean;
        }) => {
          if (this.selectedCustomer?.customerIdentifier !== st.customer?.customerIdentifier && st.customer!.type.name) {
            this.selectedCustomer = st.customer;
            this.customerSelectedPersona = st.customer?.type.name;
            if (st.state.savedForm?.customerIdentifier !== this.selectedCustomer?.customerIdentifier) {
              this.loadCustomers(st.is3PL ? EPersona.THREEPL : st.customer!.type.name, st.customer!.customerIdentifier);
              if (st.customer?.type.name) {
                this.loadCatalogs(st.customer?.type.name);
              }
            }
          }
        }
      ),
      map((st: {
        state: fromCaseCreate.State;
        customer?: ICustomer;
        isAutomotive: boolean;
        isIPNoTyesPermissions: boolean;
        is3PL: boolean;
        hasOCM: boolean;
      }) => {
        if (st.state.savedForm && st.state.savedForm?.customerIdentifier !== this.selectedCustomer?.customerIdentifier) {
          return { ...st, state: { ...st.state, savedForm: undefined, gridResult: undefined } };
        }
        return st;
      })
    );
    this.storePreference$ = this.appStore.select(selectUserPreferenceState(EUserPreferences.CASE_CUSTOMER_TOP)).pipe(
      map((preference?: string) => {
        if (preference) {
          return JSON.parse(preference);
        }
      })
    );
    this.caseCreated$ = this.appStore.select(CaseCreateSelectors.selectCaseCreationResult).pipe(
      map((result?: ICaseCreationResult) => result
        ? {
          caseId: result.idCaseNumber,
          date: NSCommons.formatNSDate(result.dateAndTime),
          message: result.message
        }
        : undefined
      )
    );
    this.loadCaseSelectPreference();
    this.equipments$ = this.activatedRoute.queryParams.pipe(
      filter((data: Params) => !!data?.transaction),
      exhaustMap((data: Params) => {
        const transaction: string = data.transaction;
        return this.commonsSvc.decompressPayload(transaction);
      }),
      filter((param: ITransaction) => !!(param?.parameters?.case)),
      map((transaction: ITransaction) => JSON.parse(transaction.parameters.case!))
    );
    this.gridResults$ = this.appStore.select(CaseCreateSelectors.selectGridEquipmentResult);
  }

  /**
   * Dispatch Load Catalogs
   *
   * @summary Dispatch Load Interaction Catalogs
   * @param $event EPersona
   * @returns void
   */
  loadCatalogs($event: EPersona) {
    this.appStore.dispatch(CaseCreateActions.loadInteractionReasons({ data: $event }));
    this.appStore.dispatch(CaseCreateActions.loadInteractionCategories({ data: $event }));
    this.appStore.dispatch(CaseCreateActions.loadInteractionTypes({ data: $event }));
    this.appStore.dispatch(CaseCreateActions.loadCarTypes({ data: $event }));
  }

  /**
   * Dispatch Email Validation
   *
   * @summary Dispatch an Email Validation action
   * @param $event string
   * @returns void
   */
  dispatchEmailValidation($event: string): void {
    this.appStore.dispatch(CaseCreateActions.emailValidation({ data: $event }));
  }

  /**
   * Dispatch Remove Contact
   *
   * @summary Dispatch Remove Contact
   * @param $event string
   * @returns void
   */
  dispatchRemoveContact($event: string): void {
    this.appStore.dispatch(CaseCreateActions.removeContact({ data: $event }));
  }

  /**
   * Submit Case
   *
   * @summary submit case for creation
   * @params $payload { request: ICaseCreation; fileData: FormData; attachmentsNumber: number }
   * @returns void
   */
  submitCase($payload: { request: ICaseCreation; fileData: FormData; attachmentsNumber: number }): void {
    this.utilsSvc.file = $payload.fileData;
    this.appStore.dispatch(CaseCreateActions.submitCaseCreation({
      data: {
        request: $payload.request,
        attachmentsNumber: $payload.attachmentsNumber
      }
    }));
  }

  /**
   * Reset State
   *
   * @summary Reset Case create state
   * @params $data: { preserveData: boolean }
   * @returns void
   */
  resetState($data: { preserveData: boolean }): void {
    this.appStore.dispatch(CaseCreateActions.resetState({ preserveData: $data.preserveData }));
  }

  /**
   * Dispatch Customer Top Preferences
   *
   * @summary Dispatch the customer top preferences
   * @params $customersTopPreferences: Array<{ customerIdentifier: string; customerName: string }>
   * @returns void
   */
  dispatchSaveCustomerTopPreferences($customersTopPreferences: Array<{
    customerIdentifier: string;
    customerName: string;
  }>): void {
    const customerPreference = {
      screenName: EUserPreferences.CASE_CUSTOMER_TOP,
      preferenceName: EUserPreferences.CASE_CUSTOMER_TOP,
      data: $customersTopPreferences
    };

    this.appStore.dispatch(UserPreferencesActions.saveUserPreference({ data: customerPreference }));
  }

  /**
   * Close Minimize
   *
   * @summary Dispatch update form action
   * @params $data: ICaseCreationFormState
   * @returns void
   */
  closeMinimize($data: ICaseCreationFormState): void {
    this.appStore.dispatch(CaseCreateActions.updateForm({ data: $data }));
  }

  /**
   * Party To Equipment Check
   *
   * @summary Dispatches equipment check permissions action that redirects to unit details page when successful
   * @param $equipment string
   * @returns void
   */
  partyToEquipmentCheck($equipment: string): void {
    this.appStore.dispatch(PartyToActions.checkPermissions({
      data: {
        equipmentId: $equipment.replace(NSCommonConstants.replaceMultipleSpacesRegex, '-')
      }
    }));
  }

  /**
   * Dispatch Criteria
   *
   * @summary Dispatch criteria to get grid data
   * @param $criteria IGridCriteria
   * @returns
   */
  dispatchCriteria($criteria: IGridCriteria): void {
    this.appStore.dispatch(CaseCreateActions.getGridDataOCM({ data: $criteria }));
  }

  private loadCustomers(customerPersona: EPersona, customerIdentifier?: string): void {
    if ((customerPersona === EPersona.IP || customerPersona === EPersona.OFFLINE || customerPersona === EPersona.THREEPL || customerPersona === EPersona.UT) &&
      customerIdentifier) {
      this.appStore.dispatch(CaseCreateActions.loadCustomers({ customerPersona }));
    }
  }

  private loadCaseSelectPreference(): void {
    this.appStore.dispatch(
      UserPreferencesActions.loadUserPreference({
        data: {
          screenName: EUserPreferences.CASE_CUSTOMER_TOP,
          preferenceName: EUserPreferences.CASE_CUSTOMER_TOP
        }
      })
    );
  }
}
