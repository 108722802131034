export enum EUnitDetailsExceptionsGridType {
  UNIT_EXCEPTIONS_IM = 'UNIT_EXCEPTIONS_IM',
  UNIT_EXCEPTIONS_IP = 'UNIT_EXCEPTIONS_IP',
  UNIT_EXCEPTIONS_SL = 'UNIT_EXCEPTIONS_SL',
  UNIT_EXCEPTIONS_UT = 'UNIT_EXCEPTIONS_UT',
  UNIT_EXCEPTIONS_GENERAL = 'UNIT_EXCEPTIONS_GENERAL'
}

export enum EUnitDetailsTab {
  WAYBILL = 'WAYBILL',
  EXCEPTIONS = 'EXCEPTIONS',
  UMLER = 'UMLER',
  SCALE_WEIGHTS = 'SCALE_WEIGHTS',
  TRACE = 'TRACE'
}

export enum EOnTimeStatus {
  LOADED = 'loaded',
  PLACED = 'placed',
  DELAYED = 'delayed',
  ACTIVE = 'active',
  IN_TRANSIT = 'in transit',
  ONTIME = 'ontime',
  DELIVERED = 'delivered',
  BEHIND = 'behind',
  AHEAD = 'ahead',
  PULLED = 'pulled',
  AT_DESTINATION = 'at destination',
  IN_TRANSIT_OFFLINE = 'in transit offline',
  IN_TRANSIT_ONLINE = 'in transit online',
  AWAITING_SERVICE = 'awaiting service'
}
