import { Params } from '@angular/router';
import { createSelector } from '@ngrx/store';

import { selectSecurityFeatureState, State } from '@ruby/modules/security/store';
import * as fromSecurity from '@ruby/modules/security/store/reducers/security.reducer';
import { selectQueryParams } from '@ruby/store/selectors/root.selectors';

export const selectSecurityState = createSelector(
  selectSecurityFeatureState,
  (securityState: State) => securityState[fromSecurity.featureKey]
);


export const selectAccessToken = createSelector(
  selectSecurityFeatureState,
  (securityState: State) => securityState[fromSecurity.featureKey].token
);
export const selectAuthStateWithParam = createSelector(
  selectQueryParams,
  (params: Params) => ({ code: params.code, state: params.state })
);

export const selectLoginWithCredentials = createSelector(
  selectSecurityState,
  (securityState: fromSecurity.State) => securityState.loginWithCredentials
);

export const selectSecurityStateWithStateParam = createSelector(
  selectSecurityState,
  selectQueryParams,
  (securityState: fromSecurity.State, params?: Params) => ({ state: securityState, stateParam: params?.state })
);

