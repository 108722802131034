export class NSUrlsConstants {
  static readonly baseUrl = '/api/v1';
  static readonly baseV2Url = '/api/v2';
  static inventoryBaseUrl = `${ NSUrlsConstants.baseUrl }/track-moves`;
  static readonly pipelineBaseUrl = `${ NSUrlsConstants.baseUrl }/pipeline`;
  static readonly pipelineV2BaseUrl = `${ NSUrlsConstants.baseV2Url }/pipeline`;

  // Alerts & Notifications
  static readonly alertsBaseUrl = `${ NSUrlsConstants.baseUrl }/alerts`;
  static readonly notificationsCounterUrl = `${ NSUrlsConstants.alertsBaseUrl }/user/unseen-count`;
  static readonly userAlertsUrl = `${ NSUrlsConstants.alertsBaseUrl }/user`;
  static readonly landingAlertsUrl = `${ NSUrlsConstants.alertsBaseUrl }/landing-page`;
  static readonly alertsImageUrl = `${ NSUrlsConstants.alertsBaseUrl }/image`;
  static readonly alertsInternalSeenUrl = `${ NSUrlsConstants.alertsBaseUrl }/internal/{id}/mark-seen`;
  static readonly alertsExternalSeenUrl = `${ NSUrlsConstants.alertsBaseUrl }/external/{id}/mark-seen`;
  static readonly alertsApplicationsUrl = `${ NSUrlsConstants.alertsBaseUrl }/applications`;
  static readonly alertsOTIUrl = `${ NSUrlsConstants.alertsBaseUrl }/oti`;
  static readonly alertsOTIGenerateUrl = `${ NSUrlsConstants.alertsOTIUrl }/generate`;
  static readonly alertsOTIImageUrl = `${ NSUrlsConstants.alertsOTIUrl }/image/`;

  // Shipments
  static readonly mapBaseUrl = `${ NSUrlsConstants.baseUrl }/map`;
  static readonly mapTrackUrl = `${ NSUrlsConstants.mapBaseUrl }/tracks`;
  static readonly activeShipmentSummaryUrl = `${ NSUrlsConstants.mapBaseUrl }/summary`;
  static readonly currentLocationUrl = `${ NSUrlsConstants.mapBaseUrl }/currentlocomotivelocation`;
  static readonly trainInformationUrl = `${ NSUrlsConstants.mapBaseUrl }/train/location`;

  // Security
  static readonly securityBaseUrl = `/api/security`;
  static readonly accessTokenUrl = `${ NSUrlsConstants.securityBaseUrl }/access-token`;
  static readonly authorizationsUrl = `${ NSUrlsConstants.securityBaseUrl }/authorizations`;
  static readonly logoutUrl = `${ NSUrlsConstants.securityBaseUrl }/logout`;
  static readonly refreshTokenUrl = `${ NSUrlsConstants.securityBaseUrl }/refresh-token`;

  // Account
  static readonly userManagementBase = `${ NSUrlsConstants.baseUrl }/user-management`;
  static readonly userInformationExternal = `${ NSUrlsConstants.userManagementBase }/user-information/external`;
  static readonly userInformationInternal = `${ NSUrlsConstants.userManagementBase }/user-information/internal`;
  static readonly userCustomerContacts = `${ NSUrlsConstants.userManagementBase }/user-information/user-customer-contacts-cache`;
  static readonly inventoryEmailNotificationsUrl = `${ NSUrlsConstants.userManagementBase }/inventory-email-notifications`;
  static readonly userManagementUserDifferencesUrl = `${ NSUrlsConstants.userManagementBase }/user`;
  static readonly userManagementCloneCoworkerUrl = `${ NSUrlsConstants.userManagementBase }/user/compare/coworker-racf`;

  // USER
  static readonly userBaseUrl = `${ NSUrlsConstants.baseUrl }/user`;
  static readonly currentApplicationsUrl = `${ NSUrlsConstants.userBaseUrl }/current-permissions`;
  static readonly availableApplicationsUrl = `${ NSUrlsConstants.userBaseUrl }/available-permissions`;
  static readonly requestApplicationUrl = `${ NSUrlsConstants.userBaseUrl }/grant-self-permission`;

  // Case Management
  static readonly caseManagementBaseUrl = `${ NSUrlsConstants.baseUrl }/case-management/case`;
  static readonly caseCreateCaseUrl = `${ NSUrlsConstants.baseV2Url }/ocm/case`;
  static readonly caseCreateUploadFilesUrl = `${ NSUrlsConstants.caseCreateCaseUrl }/{caseId}/file`;
  static readonly caseCreateEmailValidationUrl = `${ NSUrlsConstants.baseV2Url }/ocm/case/user-interested-party`;
  static readonly caseManagementCatalogsUrl = `${ NSUrlsConstants.baseV2Url }/ocm/catalogs`;

  // Common
  static catalogsBaseUrl = `${ NSUrlsConstants.baseUrl }/catalog`;
  static readonly compressionUrl = '/api/v1/string-compression/compress';
  static readonly decompressionUrl = '/api/v1/string-compression/decompress';
  static readonly statesUrl = `${ NSUrlsConstants.catalogsBaseUrl }/catalogs/1`;
  static readonly statesMultipleCountriesUrl = `${ NSUrlsConstants.catalogsBaseUrl }/catalogs/multiple`;
  static readonly stationUrl = `${ NSUrlsConstants.catalogsBaseUrl }/tep/station`;
  static readonly eventCodesUrl = `${ NSUrlsConstants.catalogsBaseUrl }/catalogs/7`;
  static readonly holdCodesUrl = `${ NSUrlsConstants.catalogsBaseUrl }/catalogs/8`;
  static readonly carOwnersUrl = `${ NSUrlsConstants.catalogsBaseUrl }/account-support/car-owners`;
  static readonly carTypesUrl = `${ NSUrlsConstants.catalogsBaseUrl }/account-support/car-types`;
  static readonly stccUrl = `${ NSUrlsConstants.catalogsBaseUrl }/tep/stcc`;
  static readonly roadsUrl = `${ NSUrlsConstants.baseUrl }/catalog/account-support/roads`;
  static readonly currentModeUrl = `${ NSUrlsConstants.catalogsBaseUrl }/rate-request/current-mode-of-transportation`;
  static readonly shipperConsigneUrl = `${ NSUrlsConstants.catalogsBaseUrl }/rate-request/shipper-consignee`;
  static readonly targetRateUrl = `${ NSUrlsConstants.catalogsBaseUrl }/rate-request/target-rate`;
  static readonly commodityTypeUrl = `${ NSUrlsConstants.catalogsBaseUrl }/catalogs/2`;
  static readonly citiesUrl = `${ NSUrlsConstants.baseV2Url }/catalog/tep/stations`;
  static readonly rolesUrl = `${ NSUrlsConstants.catalogsBaseUrl }/catalogs/10`;

  // Customer Select
  static readonly customerBaseUrl = `${ NSUrlsConstants.baseUrl }/customer-select`;
  static readonly externalCustomersUrl = '/api/v3/customer';
  static readonly internalCustomersUrl = '/api/v3/customer/internal/list';
  static readonly internalSearchCustomersUrl = '/api/v3/customer/internal/search';
  static readonly internalIMCustomerLocationsUrl = `${ NSUrlsConstants.customerBaseUrl }/internal/intermodal-locations/customer`;
  static readonly internalSLCustomerLocationsUrl = `${ NSUrlsConstants.customerBaseUrl }/internal/shortline-locations/scac`;
  static readonly internalUTContactsUrl = `${ NSUrlsConstants.customerBaseUrl }/internal/offline-customers`;
  static readonly internalUTCustomersUrl = `${ NSUrlsConstants.customerBaseUrl }/internal/unittrain-customers/userracf`;
  static readonly internalUTCustomerLocationsUrl = `${ NSUrlsConstants.customerBaseUrl }/internal/unittrain-customer/userracf`;
  static readonly externalCustomersOfflineUrl = `${ NSUrlsConstants.customerBaseUrl }/customers/role`;
  static readonly externalCustomersAllOfflineUrl = `${ NSUrlsConstants.customerBaseUrl }/customers/all`;
  static readonly ipCustomerAuthorizations = `${ NSUrlsConstants.customerBaseUrl }/acm/industrial`;
  static readonly tplCustomerAuthorizations = `${ NSUrlsConstants.customerBaseUrl }/acm/tpl`;
  static readonly primaryCompanyCustomer = `${ NSUrlsConstants.baseV2Url }/customer-select/customers/primary`;

  // Damage Freight
  static damageBaseUrl = `${ NSUrlsConstants.baseUrl }/damage-freight`;
  static readonly damageInternalCustomersUrl = `${ NSUrlsConstants.damageBaseUrl }/catalogs/internal-customers`;
  static readonly damageTypeUrl = `${ NSUrlsConstants.damageBaseUrl }/catalogs/damage-type`;
  static readonly damageDefectiveTypeUrl = `${ NSUrlsConstants.damageBaseUrl }/catalogs/defective-equipment`;
  static readonly damageProductStatusUrl = `${ NSUrlsConstants.damageBaseUrl }/catalogs/product-status`;
  static readonly damageNotificationUrl = `${ NSUrlsConstants.damageBaseUrl }/damage-notification/create-notification`;
  static readonly damageClaimUrl = `${ NSUrlsConstants.damageBaseUrl }/damage-notification/create-claim`;
  static readonly damageClaimResultsUrl = `${ NSUrlsConstants.damageBaseUrl }/damage-notification/claims`;
  static readonly damageNotificationResultsUrl = `${ NSUrlsConstants.damageBaseUrl }/damage-notification/notifications`;
  static readonly damageDeleteNotificationUrl = `${ NSUrlsConstants.damageBaseUrl }/damage-notification/delete-notification`;
  static readonly damageEditFreightUrl = `${ NSUrlsConstants.damageBaseUrl }/damage-notification/update-freight`;
  static readonly damageEmailUrl = `${ NSUrlsConstants.damageBaseUrl }/email/send`;
  static readonly damageMediaUrl = `${ NSUrlsConstants.damageBaseUrl }/file`;
  static readonly damageUploadUrl = `${ NSUrlsConstants.damageBaseUrl }/file/save`;
  static readonly damageDownloadMediaUrl = `${ NSUrlsConstants.damageBaseUrl }/file/download`;
  static readonly damageSearchWaybillUrl = `${ NSUrlsConstants.damageBaseUrl }/waybill-detail`;

  // Inventory Visualization
  static readonly inventoryWeeklySummaryUrl = `${ NSUrlsConstants.pipelineBaseUrl }/visualization/week-ahead-view`;
  static readonly inventoryInboundWeeklyDetailUrl = `${ NSUrlsConstants.pipelineBaseUrl }/visualization/weekly-view`;
  static readonly inventoryWeeklyFilterUrl = `${ NSUrlsConstants.pipelineBaseUrl }/visualization/week-ahead-filter`;

  // Empty Car Request
  static readonly ecrBaseUrl = `${ NSUrlsConstants.baseUrl }/ecr`;
  static readonly ecrSubmitUrl = `${ NSUrlsConstants.baseUrl }/ecr/submit-car`;
  static readonly ecrSubmitExceededUrl = `${ NSUrlsConstants.baseUrl }/ecr/submit-exceeded`;
  static readonly ecrUserDataUrl = `${ NSUrlsConstants.baseUrl }/ecr/user-data`;
  static readonly ecrCommodityUrl = `${ NSUrlsConstants.baseUrl }/ecr/commodity`;
  static readonly ecrAllRequestsUrl = `${ NSUrlsConstants.baseUrl }/ecr/request-history-by-date`;
  static readonly ecrMetricsUrl = `${ NSUrlsConstants.baseUrl }/ecr/metrics-graph`;
  static readonly ecrMetricsGridUrl = `${ NSUrlsConstants.baseUrl }/ecr/metrics-grid`;
  static readonly ecrRequestStatusUrl = `${ NSUrlsConstants.baseUrl }/ecr/current-car-request-status`;
  static readonly ecrCurrentWaybillUrl = `${ NSUrlsConstants.baseUrl }/ecr/current-waybill`;
  static readonly ecrCurrentEtaUrl = `${ NSUrlsConstants.baseUrl }/ecr/current-eta`;
  static readonly ecrCalendarUrl = `${ NSUrlsConstants.baseUrl }/ecr/load-calendar`;

  // Exceptions
  static exceptionsBaseUrl = `${ NSUrlsConstants.baseUrl }/exceptions`;
  static exceptionUrl = `${ NSUrlsConstants.exceptionsBaseUrl }/exception`;
  static exceptionsTerminalsUrl = `${ NSUrlsConstants.exceptionsBaseUrl }/exception/terminals`;

  // Favorites
  static readonly favoritesUrl = `${ NSUrlsConstants.baseUrl }/favorite/`;

  // Gate Receipts
  static gateBaseUrl = `${ NSUrlsConstants.baseUrl }/gate-receipt`;
  static gateSearchUrl = `${ NSUrlsConstants.gateBaseUrl }/search`;
  static readonly gateTerminalsUrl = `${ NSUrlsConstants.gateBaseUrl }/terminal`;
  static readonly gatePdfUrl = `${ NSUrlsConstants.gateBaseUrl }/pdf-encoded`;
  static readonly gateSnapshotUrl = `${ NSUrlsConstants.gateBaseUrl }/snapshot`;
  static readonly gateImageExportUrl = `${ NSUrlsConstants.gateBaseUrl }/exportImage`;
  static readonly gateImageUrl = `${ NSUrlsConstants.gateBaseUrl }/image`;
  static readonly sharePdfUrl = `${ NSUrlsConstants.gateBaseUrl }/share-pdf`;

  // HLJS
  static hljsBaseUrl = `${ NSUrlsConstants.baseUrl }/hljs`;
  static hljsContactsUrl = `${ NSUrlsConstants.hljsBaseUrl }/contact`;
  static hljsStatementsUrl = `${ NSUrlsConstants.hljsBaseUrl }/widget-summary/`;
  static hljsTrafficUrl = `${ NSUrlsConstants.hljsBaseUrl }/traffic-class`;
  static hljsRateCodeUrl = `${ NSUrlsConstants.hljsBaseUrl }/rate-code/`;
  static hljsUploadStatementUrl = `${ NSUrlsConstants.hljsBaseUrl }/upload-statements/`;
  static hljsSubmitStatementUrl = `${ NSUrlsConstants.hljsBaseUrl }/submit-statement`;
  static hljsStatementDetailUrl = `${ NSUrlsConstants.hljsBaseUrl }/statement-detail`;

  // IBOL
  static readonly ibolBaseUrl = `${ NSUrlsConstants.baseUrl }/ibol/ibol`;
  static readonly ibolCustomersUrl = `${ NSUrlsConstants.ibolBaseUrl }/customer-list`;
  static readonly ibolCustomerAddressesUrl = `${ NSUrlsConstants.ibolBaseUrl }/customers`;
  static readonly ibolExternalCustomerUrl = `${ NSUrlsConstants.ibolBaseUrl }/customer-locations?type=ibol`;
  static readonly ibolCountersUrl = `${ NSUrlsConstants.ibolBaseUrl }/widget-count-list/primary10/`;
  static readonly ibolPatternTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/rwc-types`;
  static readonly ibolPatternsUrl = `${ NSUrlsConstants.ibolBaseUrl }/patterns`;
  static readonly ibolRouteTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/agent-shipper-routes`;
  static readonly ibolPackageQualifiersTypeUrl = `${ NSUrlsConstants.ibolBaseUrl }/package-qualifiers`;
  static readonly ibolPaymentTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/payment-methods`;
  static readonly ibolSpecialHandlingCodeTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/special-handling-codes`;
  static readonly ibolEquipmentTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/equipment-types`;
  static readonly ibolBillTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/bill-types`;
  static readonly ibolWeightAgreementsUrl = `${ NSUrlsConstants.ibolBaseUrl }/weight-agreements`;
  static readonly ibolWeightQualifiersUrl = `${ NSUrlsConstants.ibolBaseUrl }/weight-qualifiers`;
  static readonly ibolPackageQualifiersUrl = `${ NSUrlsConstants.ibolBaseUrl }/package-qualifiers`;
  static readonly ibolTareWeightQualifiersUrl = `${ NSUrlsConstants.ibolBaseUrl }/tare-weight-qualifiers`;
  static readonly ibolChassisTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/chassis-types`;
  static readonly ibolPlanServiceCodesUrl = `${ NSUrlsConstants.ibolBaseUrl }/plan-service-codes`;
  static readonly ibolPricingAuthorityQualifiersUrl = `${ NSUrlsConstants.ibolBaseUrl }/pricing-authority-qualifiers`;
  static readonly ibolReferenceNumbersUrl = `${ NSUrlsConstants.ibolBaseUrl }/reference-numbers`;
  static readonly ibolRegulatoryAgenciesUrl = `${ NSUrlsConstants.ibolBaseUrl }/regulatory-agencies`;
  static readonly ibolPublicationTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/publication-types`;
  static readonly ibolShipmentTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/shipment-types`;
  static readonly ibolOtherPartiesUrl = `${ NSUrlsConstants.ibolBaseUrl }/other-parties`;
  static readonly ibolVinPositionsUrl = `${ NSUrlsConstants.ibolBaseUrl }/vin-positions`;
  static readonly ibolVinTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/vin-types`;
  static readonly ibolBondTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/bond-types`;
  static readonly ibolPortFunctionsUrl = `${ NSUrlsConstants.ibolBaseUrl }/port-functions`;
  static readonly ibolManifestTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/manifest-types`;
  static readonly ibolProtectiveServiceCodesUrl = `${ NSUrlsConstants.ibolBaseUrl }/protective-service-codes`;
  static readonly ibolProtectiveServiceRulesUrl = `${ NSUrlsConstants.ibolBaseUrl }/protective-service-rules`;
  static readonly ibolBondReferenceTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/bond-reference-types`;
  static readonly iBolSaveBillUrl = `${ NSUrlsConstants.ibolBaseUrl }/save-shipment-instructions`;
  static readonly ibolStatesUrl = `${ NSUrlsConstants.ibolBaseUrl }/states`;
  static readonly ibolRoadsUrl = `${ NSUrlsConstants.ibolBaseUrl }/roads`;
  static readonly ibolRoutesUrl = `${ NSUrlsConstants.ibolBaseUrl }/routes`;
  static readonly ibolStationsUrl = `${ NSUrlsConstants.ibolBaseUrl }/stations`;
  static readonly ibolShippingInfoUrl = `${ NSUrlsConstants.ibolBaseUrl }/shipper-name-addresses/web-owner`;
  static readonly iBolShipmentInstructionsUrl = `${ NSUrlsConstants.ibolBaseUrl }/shipment-instructions`;
  static readonly iBolDeleteShipmentInstructionUrl = `${ NSUrlsConstants.ibolBaseUrl }/delete-shipment-instructions`;
  static readonly iBolEmailPrintUrl = `${ NSUrlsConstants.ibolBaseUrl }/mail/print`;
  static readonly iBolBolPrintUrl = `${ NSUrlsConstants.ibolBaseUrl }/bol-print`;
  static readonly iBolEdiPrintUrl = `${ NSUrlsConstants.ibolBaseUrl }/edi-print`;
  static readonly iBolWaybillPrintUrl = `${ NSUrlsConstants.ibolBaseUrl }/waybill-print`;
  static readonly iBolActivityReportsUrl = `${ NSUrlsConstants.ibolBaseUrl }/activity-reports`;
  static readonly iBolPatternUrl = `${ NSUrlsConstants.ibolBaseUrl }/patterns`;
  static readonly iBolExcelConvertionUrl = `${ NSUrlsConstants.ibolBaseUrl }/files/excel-conversion/csv`;
  static readonly iBolTemporaryFileUrl = `${ NSUrlsConstants.ibolBaseUrl }/files/temporary`;
  static readonly iBolImageIdsUrl = `${ NSUrlsConstants.ibolBaseUrl }/image-ids`;
  static readonly iBolHazardousListUrl = `${ NSUrlsConstants.ibolBaseUrl }/hazardous-list`;
  static readonly iBolHazardousDataUrl = `${ NSUrlsConstants.ibolBaseUrl }/hazardous-data`;
  static readonly ibolCompartmentsUrl = `${ NSUrlsConstants.ibolBaseUrl }/compartment-ids`;
  static readonly ibolPackageGroupsUrl = `${ NSUrlsConstants.ibolBaseUrl }/package-groups`;
  static readonly ibolFlashPointsUrl = `${ NSUrlsConstants.ibolBaseUrl }/flash-points`;
  static readonly ibolPhysicalFormsUrl = `${ NSUrlsConstants.ibolBaseUrl }/physical-forms`;
  static readonly ibolHazWeightQualifiersUrl = `${ NSUrlsConstants.ibolBaseUrl }/haz-weight-qualifiers`;
  static readonly ibolLabelTypesUrl = `${ NSUrlsConstants.ibolBaseUrl }/label-types`;
  static readonly ibolFissileIndicatorsUrl = `${ NSUrlsConstants.ibolBaseUrl }/fissile-indicators`;
  static readonly ibolNetExplosiveQualifiersUrl = `${ NSUrlsConstants.ibolBaseUrl }/net-explosive-qualifiers`;
  static readonly ibolHazardousZonesUrl = `${ NSUrlsConstants.ibolBaseUrl }/hazardous-zones`;
  static readonly ibolActivityUnitsUrl = `${ NSUrlsConstants.ibolBaseUrl }/radioactive-units`;
  static readonly iBolSubmitBillUrl = `${ NSUrlsConstants.ibolBaseUrl }/submit-shipment-instructions`;
  static readonly iBolImageUrl = `${ NSUrlsConstants.baseUrl }/ibol/ibol-image`;
  static readonly ibolMexicoMaterialTypeUrl = `${ NSUrlsConstants.ibolBaseUrl }/catalog/mexico-material-type`;

  // Mileage Inquiry
  static mileageBaseUrl = `${ NSUrlsConstants.baseUrl }/mileage-inquiry`;
  static readonly milesUrl = `${ NSUrlsConstants.mileageBaseUrl }/miles`;

  // Quick Links
  static readonly quickLinksUrl = `${ NSUrlsConstants.baseUrl }/quick-link`;

  // Quick Search
  static readonly quickSearchBaseUrl = `${ NSUrlsConstants.baseUrl }/quick-search`;
  static readonly quickTypeUrl = `${ NSUrlsConstants.quickSearchBaseUrl }/search-options`;
  static readonly quickUploadFileUrl = `${ NSUrlsConstants.quickSearchBaseUrl }/uploadExcel`;

  // Price Publication
  static pricePublicationBaseUrl = `${ NSUrlsConstants.baseUrl }/price-publication`;
  static readonly pricePublicationStatesUrl = `${ NSUrlsConstants.pricePublicationBaseUrl }/state`;
  static readonly pricePublicationStationsUrl = `${ NSUrlsConstants.pricePublicationBaseUrl }/station`;
  static readonly pricePublicationMajorMinorGroupUrl = `${ NSUrlsConstants.pricePublicationBaseUrl }/major-minor`;
  static readonly pricePublicationSearchUrl = `${ NSUrlsConstants.pricePublicationBaseUrl }/search-publication`;
  static readonly pricePublicationCommodityByPublicationUrl = `${ NSUrlsConstants.pricePublicationBaseUrl }/commodity-by-publication`;
  static readonly pricePublicationMyPublicationsUrl = `${ NSUrlsConstants.pricePublicationBaseUrl }/my-publications`;
  static readonly pricePublicationExportUrl = `${ NSUrlsConstants.pricePublicationBaseUrl }/export`;
  static readonly pricePublicationExportCountUrl = `${ NSUrlsConstants.pricePublicationExportUrl }/count`;

  // Price Publication Subscription
  static pricePublicationSubscriptionBaseUrl = `${ NSUrlsConstants.baseUrl }/price`;
  static readonly pricePublicationNotificationSettingsUrl = `${ NSUrlsConstants.pricePublicationSubscriptionBaseUrl }/notification-setting`;
  static readonly pricePublicationSubscriptionUrl = `${ NSUrlsConstants.pricePublicationSubscriptionBaseUrl }/subscription`;
  static readonly pricePublicationSubscribeUrl = `${ NSUrlsConstants.pricePublicationSubscriptionUrl }/subscribe`;
  static readonly pricePublicationUnsubscribeUrl = `${ NSUrlsConstants.pricePublicationSubscriptionUrl }/unsubscribe`;

  // Rate Inquiry
  static rateBaseUrl = `${ NSUrlsConstants.baseUrl }/rate-inquiry/rate-inquiry`;
  static readonly rateSearchUrl = `${ NSUrlsConstants.rateBaseUrl }/search-rate`;
  static readonly rateEmailUrl = `${ NSUrlsConstants.rateBaseUrl }/email/send`;
  static readonly rateExportUrl = `${ NSUrlsConstants.rateBaseUrl }/export/`;
  static readonly submitRateRequestUrl = `${ NSUrlsConstants.baseUrl }/rate-inquiry/rate-request`;

  // Security Questions
  static readonly securityQuestionsUrl = `${ NSUrlsConstants.securityBaseUrl }/challenge-question`;
  static readonly resetPasswordUrl = `${ NSUrlsConstants.securityBaseUrl }/reset-password`;

  // Special Instructions
  static readonly specialBaseUrl = `${ NSUrlsConstants.baseUrl }/special-instruction`;

  // User Preferences
  static userPreferencesUrl = `${ NSUrlsConstants.baseUrl }/user-preference`;

  // Watchlist
  static readonly watchUrl = `${ NSUrlsConstants.baseUrl }/watchlist`;

  // Menu
  static readonly menuUrl = `${ NSUrlsConstants.baseUrl }/acm-menu/user`;

  // Unit Detail
  static unitDetailBaseUrl = `${ NSUrlsConstants.baseUrl }/unit-detail`;
  static readonly unitDetailUrl = `${ NSUrlsConstants.baseV2Url }/unit-detail`;
  static readonly unitDetailWaybillPartyToUrl = `${ NSUrlsConstants.baseV2Url }/is-party-to`;

  // Third Party Providers
  static readonly thirdPartyBaseUrl = `${ NSUrlsConstants.baseUrl }/tpl`;
  static readonly thirdPartyLOASUrl = `${ NSUrlsConstants.thirdPartyBaseUrl }/loa`;
  static readonly thirdPartyUserLOASUrl = `${ NSUrlsConstants.thirdPartyBaseUrl }/user`;
  static readonly thirdPartyLOACancelUrl = `${ NSUrlsConstants.thirdPartyBaseUrl }/loa/cancel`;
  static readonly thirdPartyLOAActivateUrl = `${ NSUrlsConstants.thirdPartyBaseUrl }/loa/activate`;
  static readonly thirdPartyAvailablePersonasUrl = `${ NSUrlsConstants.thirdPartyBaseUrl }/loa/company`;
  static readonly thirdPartyCompanySearchUrl = `${ NSUrlsConstants.thirdPartyBaseUrl }/company/search`;
  static readonly thirdPartyEcrEquipmentUrl = `${ NSUrlsConstants.baseV2Url }/equipment`;
  static readonly thirdPartyEcrScacUrl = `${ NSUrlsConstants.baseUrl }/scac-mapping`;
  static readonly thirdPartyTemplateUrl = `${ NSUrlsConstants.thirdPartyBaseUrl }/loa/template/active`;
  static readonly thirdPartyRequestEidUrl = `${ NSUrlsConstants.thirdPartyBaseUrl }/company/eid`;

  // Onsite Inventory
  static readonly onsiteInventoryUrl = `${ NSUrlsConstants.pipelineBaseUrl }/industrial-product/onsite/details`;

  // Shipping Inventory
  static readonly shippingInventoryUrl = `${ NSUrlsConstants.pipelineBaseUrl }/industrial-product/shipping/details`;

  // Transit Performance
  static readonly transitPerformanceUrl = `${ NSUrlsConstants.baseUrl }/transit-performance`;
  static readonly transitPerformanceLocationsUrl = `${ NSUrlsConstants.transitPerformanceUrl }/locations/locations`;
  static readonly transitPerformanceOnTimePerformanceUrl = `${ NSUrlsConstants.transitPerformanceUrl }/reports/transit-performance-dashboard`;
  static readonly transitPerformanceLanePerformanceDetailsUrl = `${ NSUrlsConstants.transitPerformanceUrl }/reports/lane-performance-details`;
  static readonly transitPerformanceHistoricalTripStatsUrl = `${ NSUrlsConstants.transitPerformanceUrl }/reports/historical-trip-stats`;

  // EBill
  static readonly eBillUrl = `${ NSUrlsConstants.baseUrl }/e-bill`;
  static readonly eBillViewUrl = `${ NSUrlsConstants.eBillUrl }/profile/all`;
  static readonly eBillNewProfileUrl = `${ NSUrlsConstants.eBillUrl }/profile/delivery`;
  static readonly eBillDeliverySummaryUrl = `${ NSUrlsConstants.eBillUrl }/delivery/summary`;
  static readonly eBillUserUrl = `${ NSUrlsConstants.eBillUrl }/customer/user-search`;
  static readonly eBillDeleteUrl = `${ NSUrlsConstants.eBillUrl }/profile`;
  static readonly eBillEditUrl = `${ NSUrlsConstants.eBillUrl }/profile/profile-for-edit`;
  static readonly eBillNotificationUrl = `${ NSUrlsConstants.eBillUrl }/delivery/notification-email`;
  static readonly eBillCompanySummaryUrl = `${ NSUrlsConstants.eBillUrl }/company/summary`;
  static readonly eBillDetailsUrl = `${ NSUrlsConstants.eBillUrl }/bill/general-dispute-details`;
  static readonly eBillDetailsAllUrl = `${ NSUrlsConstants.eBillUrl }/bill/general-dispute-details/all`;
  static readonly eBillImagePDFUrl = `${ NSUrlsConstants.eBillUrl }/documents/image-data-pdf`;
  static readonly eBillDisputeSummaryUrl = `${ NSUrlsConstants.eBillUrl }/dispute/history`;
  static readonly eBillSearchUrl = `${ NSUrlsConstants.eBillUrl }/bill/search`;
  static readonly eBillDisputeUrl = `${ NSUrlsConstants.eBillUrl }/dispute`;
  static readonly eBillDisputeReasonsUrl = `${ NSUrlsConstants.eBillDisputeUrl }/reasons`;
  static readonly eBillEmailUrl = `${ NSUrlsConstants.eBillUrl }/documents/email`;
  static readonly eBillProfileSearchUrl = `${ NSUrlsConstants.eBillUrl }/bill/email-search`;
  static readonly eBillCustomerSearchUrl = `${ NSUrlsConstants.eBillUrl }/customer/search`;
  static readonly eBillUpdateLocationsUrl = `${ NSUrlsConstants.eBillUrl }/profile/update-profile`;
  static readonly eBillCarDetailsUrl = `${ NSUrlsConstants.eBillUrl }/freight-bill/view-demurrage-car-details`;
  static readonly eBillDemurrageCarDetailsUrl = `${ NSUrlsConstants.eBillUrl }/freight-bill/demurrage-car-details`;
  static readonly eBillDemurrageDetailsUrl = `${ NSUrlsConstants.eBillUrl }/freight-bill/demurrage-details`;
  static readonly eBillDemurrageDetailsExcelUrl = `${ NSUrlsConstants.eBillUrl }/documents/demurrage-excel`;
  static readonly eBillDemurrageCarDisputeUrl = `${ NSUrlsConstants.eBillUrl }/freight-bill/dispute-demurrage-car`;
  static readonly eBillFinanceDetailsUrl = `${ NSUrlsConstants.eBillUrl }/freight-bill/miscellaneous-charge/details`;
  static readonly eBillTbtTocDetailsUrl = `${ NSUrlsConstants.eBillUrl }/bill/tbt-toc-details`;
  static readonly eBillFinanceExcelUrl = `${ NSUrlsConstants.eBillUrl }/documents/miscellaneous-details`;
  static readonly eBillTbtTocExcelUrl = `${ NSUrlsConstants.eBillUrl }/documents/tbt-toc-excel`;
  static readonly eBillTbtMiscDetailsUrl = `${ NSUrlsConstants.eBillUrl }/bill/tbt-misc-details`;
  static readonly eBillTbtMiscExcelUrl = `${ NSUrlsConstants.eBillUrl }/documents/tbt-misc-excel`;

  // EPrice
  static readonly ePriceUrl = `${ NSUrlsConstants.baseUrl }/eprice`;
  static readonly ePriceBeneficialOwnerUrl = `${ NSUrlsConstants.ePriceUrl }/beneficial-owner`;
  static readonly ePriceOriginDestinationUrl = `${ NSUrlsConstants.ePriceUrl }/origin-destination`;
  static readonly ePriceServiceCodeUrl = `${ NSUrlsConstants.ePriceUrl }/service-code`;
  static readonly ePriceSearchUrl = `${ NSUrlsConstants.ePriceUrl }/search-rates`;
  static readonly ePriceExportUrl = `${ NSUrlsConstants.ePriceUrl }/export`;

  // Track Move
  static readonly tracksUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/track/moves`;
  static readonly trackDetailsUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/track/moves/detail`;
  static readonly trackUnlockUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/track/moves/unlock`;
  static readonly trackAdjustUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/track/moves/adjust`;
  static readonly trackPerformMovesUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/track/perform/moves`;
  static readonly trackRefreshUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/track/refresh`;

  // Inventory Report
  static readonly serviceReportUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/report/service`;
  static readonly serviceReportDownloadUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/report/pdf`;

  // Accessorial Inventory
  static readonly accessorialUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/revenue-candidate`;
  static readonly accessorialLeaseTrackUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/lease-track-detail`;

  // Demurrage Inventory
  static readonly demurrageUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/candidate-detail`;
  static readonly demurragePendingDisputeUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/pending-dispute`;
  static readonly demurrageDisputeHistoryUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/pending-history`;
  static readonly demurrageDisputeDetailUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/dispute-summary`;
  static readonly demurrageApproveUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/approve-demurrage`;
  static readonly demurrageDisputeUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/submit-dispute`;
  static readonly demurragePreValidationUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/validate-dispute`;

  // Customized Reporting
  static customizedReportingUrl = `${ NSUrlsConstants.baseUrl }/customized-reporting`;
  static readonly customizedReportingStructureResourceUrl = `${ NSUrlsConstants.customizedReportingUrl }/structure`;
  static readonly customizedReportingEnsureStructureUrl = `${ NSUrlsConstants.customizedReportingStructureResourceUrl }/ensure`;
  static readonly customizedReportingStructureUrl = `${ NSUrlsConstants.customizedReportingStructureResourceUrl }/active`;
  static readonly customizedReportingPersonalStructureUrl = `${ NSUrlsConstants.customizedReportingStructureUrl }/personal`;
  static readonly customizedReportingCompaniesStructureUrl = `${ NSUrlsConstants.customizedReportingStructureUrl }/companies`;
  static readonly customizedReportingFTPValidationUrl = `${ NSUrlsConstants.customizedReportingUrl }/ftp/validate`;
  static readonly customizedReportingReportResourceUrl = `${ NSUrlsConstants.customizedReportingUrl }/report`;
  static readonly customizedReportingRecentlyDeletedUrl = `${ NSUrlsConstants.customizedReportingReportResourceUrl }/deleted`;
  static readonly customizedReportingRecentlyUpdatedUrl = `${ NSUrlsConstants.customizedReportingReportResourceUrl }/updated`;
  static readonly customizedReportingRestoreUrl = `${ NSUrlsConstants.customizedReportingReportResourceUrl }/restore`;
  static readonly customizedReportingCloneUrl = `${ NSUrlsConstants.customizedReportingReportResourceUrl }/clone`;
  static readonly customizedReportingEditReportColumnsUrl = `${ NSUrlsConstants.customizedReportingReportResourceUrl }/columns`;
  static readonly customizedReportingSchedulerResourceUrl = `${ NSUrlsConstants.customizedReportingUrl }/scheduler`;
  static readonly customizedReportingSchedulerRunReportUrl = `${ NSUrlsConstants.customizedReportingSchedulerResourceUrl }/run`;
  static readonly customizedReportingFavoritesResourceUrl = `${ NSUrlsConstants.customizedReportingUrl }/favorite`;
  static readonly customizedReportingRecipientsResourceUrl = `${ NSUrlsConstants.customizedReportingUrl }/recipient`;
  static readonly customizedReportingRecipientsPauseAllResourceUrl = `${ NSUrlsConstants.customizedReportingRecipientsResourceUrl }/pause`;
  static readonly customizedReportingRecipientsResumeAllResourceUrl = `${ NSUrlsConstants.customizedReportingRecipientsResourceUrl }/resume`;
  static readonly customizedReportingUnsubscribeResourceUrl = `${ NSUrlsConstants.customizedReportingUrl }/unsubscribe`;
  static readonly customizedReportingBatchResourceUrl = `${ NSUrlsConstants.customizedReportingUrl }/batch`;
  static readonly customizedReportingGeneratorResourceUrl = `${ NSUrlsConstants.customizedReportingUrl }/generator`;
  static readonly customizedReportingGeneratorDataUrl = `${ NSUrlsConstants.customizedReportingGeneratorResourceUrl }/data`;
  static readonly customizedReportingGeneratorFileUrl = `${ NSUrlsConstants.customizedReportingGeneratorResourceUrl }/file`;
  static readonly customizedReportingActiveReportsUrl = `${ NSUrlsConstants.customizedReportingUrl }/report/active`;

  // Reservations
  static reservationsUrl = `${ NSUrlsConstants.baseUrl }/reservations`;
  static reservationsByStatusUrl = `${ NSUrlsConstants.baseUrl }/reservations/reservations-by-status`;

  // Diversions
  static readonly diversionsBaseUrl = `${ NSUrlsConstants.baseUrl }/diversion`;
  static readonly diversionsCustomerDetailsUrl = `${ NSUrlsConstants.diversionsBaseUrl }/customer-details`;
  static readonly diversionsWaybillUnderReviewUrl = `${ NSUrlsConstants.diversionsBaseUrl }/waybill-under-review`;
  static readonly diversionsWaybillPrintUrl = `${ NSUrlsConstants.diversionsBaseUrl }/waybill-print`;
  static readonly diversionsWaybillDetailUrl = `${ NSUrlsConstants.diversionsBaseUrl }/waybill-detail`;
  static readonly diversionsCarResultsUrl = `${ NSUrlsConstants.diversionsBaseUrl }/td-cars`;
  static readonly diversionsCitiesUrl = `${ NSUrlsConstants.diversionsBaseUrl }/station`;
  static readonly diversionsRoutesUrl = `${ NSUrlsConstants.diversionsBaseUrl }/route`;
  static readonly diversionsConsigneeUrl = `${ NSUrlsConstants.diversionsBaseUrl }/consignee`;
  static readonly diversionsAuthCustomersUrl = `${ NSUrlsConstants.diversionsBaseUrl }/auth-customer`;
  static readonly diversionsCustomersUrl = `${ NSUrlsConstants.diversionsBaseUrl }/customer`;
  static readonly diversionsExternalCustomerDetailsUrl = `${ NSUrlsConstants.baseUrl }/customer-select/customers/all`;
  static readonly diversionsRequestSubmitUrl = `${ NSUrlsConstants.diversionsBaseUrl }/submit-diversion`;
  static readonly diversionsPrivateCarSubmitUrl = `${ NSUrlsConstants.diversionsBaseUrl }/submit-private-car-empty-disposition`;
  static readonly diversionsDestTurnoverSubmitUrl = `${ NSUrlsConstants.diversionsBaseUrl }/submit-destination-turnover`;
  static readonly diversionsOriginTurnoverSubmitUrl = `${ NSUrlsConstants.diversionsBaseUrl }/submit-origin-turnover`;
  static readonly diversionsReshipSubmitUrl = `${ NSUrlsConstants.diversionsBaseUrl }/submit-reship`;
  static readonly diversionsPreviousRequestsUrl = `${ NSUrlsConstants.diversionsBaseUrl }/previous-waybills`;
  static readonly diversionsStatusAndRequestTypesUrl = `${ NSUrlsConstants.diversionsBaseUrl }/status-req-type`;
  static readonly diversionsHistoryEventsUrl = `${ NSUrlsConstants.diversionsBaseUrl }/events`;
  static readonly diversionsCancelDiversionUrl = `${ NSUrlsConstants.diversionsBaseUrl }/cancel-diversion`;
  static readonly diversionsRejectUrl = `${ NSUrlsConstants.diversionsBaseUrl }/reject-diversion`;
  static readonly diversionsCompleteUrl = `${ NSUrlsConstants.diversionsBaseUrl }/complete-reship`;
  static readonly diversionsMovesUrl = `${ NSUrlsConstants.diversionsBaseUrl }/moves`;
  static readonly diversionsNetWeightUrl = `${ NSUrlsConstants.diversionsBaseUrl }/stcc-net-weight`;

  // Guarantees
  static readonly guaranteeBaseURL = `${ NSUrlsConstants.baseV2Url }/guarantee`;
  static readonly storageGuaranteeBaseUrl = `${ NSUrlsConstants.guaranteeBaseURL }/storage`;
  static readonly liftGuaranteeBaseUrl = `${ NSUrlsConstants.guaranteeBaseURL }/lift`;
  static readonly guaranteesHistory = `${ NSUrlsConstants.storageGuaranteeBaseUrl }/history`;

  // ENS Notifications
  static readonly ensBaseUrl = `${ NSUrlsConstants.baseUrl }/ens`;
  static readonly ensNotificationUrl = `${ NSUrlsConstants.ensBaseUrl }/notification`;
  static readonly ensNotificationsSummaryUrl = `${ NSUrlsConstants.ensNotificationUrl }/summary`;
  static readonly ensSubscriptionUrl = `${ NSUrlsConstants.ensBaseUrl }/subscription`;
  static readonly ensNotificationWindowUrl = `${ NSUrlsConstants.ensBaseUrl }/notification-window`;
  static readonly ensEventTypesUrl = `${ NSUrlsConstants.ensBaseUrl }/event-type`;
  static readonly ensPublicUrl = `${ NSUrlsConstants.ensBaseUrl }/public`;
  static readonly ensPublicNotificationTypeUrl = `${ NSUrlsConstants.ensPublicUrl }/subscription`;
  static readonly ensPublicUnsubscribeNotificationUrl = `${ NSUrlsConstants.ensPublicUrl }/subscription/changestatus`;

  // Short Line Profile
  static readonly shortLineBaseUrl = `${ NSUrlsConstants.baseUrl }/shortline-profile`;

  // Unit Train
  static unitTrainBaseUrl = `${ NSUrlsConstants.baseUrl }/unit-train`;
  static unitTrainPermissionUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/acm/authorization`;
  static unitTrainCatalogBaseUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/catalogs`;
  static unitTrainFavoriteCommodityUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/favorite-commodity`;
  static unitTrainCommoditiesUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/commodities`;
  static unitTrainTrafficTypesUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/traffic-types`;
  static unitTrainTrafficCategoriesUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/traffic-categories`;
  static unitTrainDivisionsUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/divisions`;
  static unitTrainDivisionTypesUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/division/types`;
  static unitTrainAccountManagersUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/account-managers`;
  static unitTrainReceiversUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/receivers/filter`;
  static unitTrainDestinationsUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/destinations/filter`;
  static unitTrainDestinationGroupsUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/destination-groups`;
  static unitTrainShippersUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/shippers/filter`;
  static unitTrainOriginsUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/origin/filter`;
  static unitTrainShipmentStatusUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/shipment/statuses/report`;
  static unitTrainDocksUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/docks`;
  static unitTrainVesselsUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/vessels`;
  static unitTrainDateTypesUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/date-types`;
  static unitTrainCarTypesUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/car-types`;
  static unitTrainStatesUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/states`;
  static unitTrainCitiesUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/cities`;
  static unitTrainRegionsUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/regions`;
  static unitTrainServingYardsUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/serving-yards`;
  static unitTrainTrafficGroupsUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/traffic-groups`;
  static unitTrainReceiversCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/receivers`;
  static unitTrainShippersCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/shippers`;
  static unitTrainDestinationsCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/destinations`;
  static unitTrainProductTypesCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/product-types`;
  static unitTrainPermitsCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/permit-numbers`;
  static unitTrainProductClassesCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/product-classes`;
  static unitTrainFinalCustomersCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/final-customers`;
  static unitTrainContractNosCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/contract-numbers`;
  static unitTrainMineDistrictsCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/mine/districts`;
  static unitTrainDocksWithIndicatorCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/docks-with-indicator`;
  static unitTrainStorageTypesCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/storage-types`;
  static unitTrainVesselEventsCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/vessel-events`;
  static unitTrainCommodityDatePrefUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/commodity/date-prefs`;
  static unitTrainCarTypesMPCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/car-types/multi-permit`;
  static unitTrainDocksNonVesselCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/event-docks/non-vessel`;
  static unitTrainForeignShipmentsCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/calendar/foreign-shipment`;
  static unitTrainForeignRoadsCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/calendar/foreign-road-types`;
  static unitTrainSearchMonthsCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/calendar/months`;
  static unitTrainSearchYearsCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/calendar/years`;
  static unitTrainFilterValuesCatalogUrl = `${ NSUrlsConstants.unitTrainCatalogBaseUrl }/filter/values`;
  static unitTrainDetentionControlValuesCatalogUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/detention/control-values`;
  static unitTrainDetentionReasonCodesCatalogUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/detention/reasons`;
  static unitTrainDefaultCarTypeUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/default-car-type`;
  static unitTrainDisputeDetentionUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/detention/dispute`;

  static unitTrainShippingListingUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/shipment-listing/report`;
  static unitTrainFleetInquiryUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/fleet-inquiry/report`;
  static unitTrainShipmentStatusListUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/shipment-status/report`;
  static unitTrainLoadTrainUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/load-train/data`;
  static unitTrainLoadTrainShipperProfilesUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/load-train/shipper-profile`;
  static unitTrainShipmentsCatalogUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/load-train/shipments`;
  static unitTrainShipmentsSaveUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/load-train/save`;
  static unitTrainReleaseEmptiesGetCarsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/cars`;
  static unitTrainTrainSetInquiryUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/train-set-inquiry/report`;
  static unitTrainHopperRunUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/hopper-run/report`;
  static unitTrainTrainSizePerformanceUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/size/performance`;
  static unitTrainMonthlyEventsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/calendar/events`;
  static unitTrainWeeklyEventsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/calendar/weekly`;
  static unitTrainVesselEventsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/calendar/vessel/calendar-events`;
  static unitTrainVesselEventByIdUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/calendar/vessel/event`;
  static unitTrainCreatePermitForVesselEventUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train//permit/for-vessel-event`;
  static unitTrainMoveShipmentHasPermissionUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/calendar/has-permission`;
  static unitTrainMoveShipmentMultipleUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/calendar/shipments/move-multiple`;
  static unitTrainEmptyPermitForShipmentUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/calendar/shipment`;
  static unitTrainCoalOrderMetricsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/order-fulfillment/report`;
  static unitTrainCycleReportMetricsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/cycle-report/metric`;
  static unitTrainCarMovementInquiryUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/car-movement/inquiry`;
  static unitTrainMappingUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/mapping/report`;
  static unitTrain48hReportUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/forty-eight-hour/report`;
  static unitTrainWaybillDetailsListUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/waybill-inquiry`;
  static unitTrainWaybillDetailsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/waybill-inquiry/serial`;
  static unitTrainWaybillCarsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/waybill-inquiry/shipmentId`;
  static unitTrainTrainRouteUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/route/details`;
  static unitTrainPermitTrainConsistUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/train-consist/trace`;
  static unitTrainTrainConsistByConsistUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/train-consist/consist`;
  static unitTrainFleetDetailsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/fleet-inquiry/permit/car-info`;
  static unitTrainDetentionDisputesUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/detention/disputes`;
  static unitTrainDetentionHistoryUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/detention/history`;
  static unitTrainDetentionDisputeDetailsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/detention/dispute-details`;

  static unitTrainSearchPermitsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/permit/search`;
  static unitTrainPermitUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/permit`;
  static unitTrainPermitMultipleUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/permit/save/multiple`;
  static unitTrainPermitFindUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/permit/find`;
  static unitTrainClonePermitCommentsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/comments/permit`;
  static unitTrainReleaseCarsUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/release-empty`;
  static unitTrainTraceCarsByPermitUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/train-consist/permit`;
  static unitTrainTrainConsistByCarUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/train-consist`;
  static unitTrainTrainConsistByOriginDateUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/train-consist/train`;
  static unitTrainOriginBatchCoalDensityUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/origin-batch-weigh-external/coal-density`;
  static unitTrainOriginBatchReportUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/origin-batch-weigh-external/report`;

  static unitTrainMapBaseUrl = `${ NSUrlsConstants.unitTrainBaseUrl }/unit-train/map`;
  static unitTrainMapTraceUrl = `${ NSUrlsConstants.unitTrainMapBaseUrl }/trace`;
  static unitTrainMapSummaryUrl = `${ NSUrlsConstants.unitTrainMapBaseUrl }/summary`;

  // Receiving Inventory
  static readonly receivingInventoryUrl = `${ NSUrlsConstants.pipelineBaseUrl }/industrial-product/receiving-detail`;
  static readonly receivingQuantityOrdersUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/quantity-order`;

  // Release Switch
  static readonly releaseSwitchPreValidateUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/release-switch/pre-validation`;
  static readonly releaseSwitchSubmitUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/release-switch/submit`;
  static readonly releaseSwitchRejectCodesUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/release-switch/reject-codes`;

  // Order
  static readonly orderPreValidateUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/order-validate`;
  static readonly orderSubmitUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/order`;

  // Quantity Order
  static readonly quantityOrderPreValidateUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/quantity-order-validate`;
  static readonly quantityOrderSubmitUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/onsite-quantity-order`;

  // Historical Inventory
  static readonly historicalUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/historical-billing-summary`;
  static readonly historicalPCSUrl = `${ NSUrlsConstants.inventoryBaseUrl }/inventory/billing/private-car-historical-summary`;

  // Service Window
  static readonly serviceWindowUrl = `${ NSUrlsConstants.pipelineBaseUrl }/industrial-product/service-window`;

  // Check Redirect User Status
  static readonly checkRedirectToLegacyUserStatusUrl = `${ NSUrlsConstants.baseUrl }/rollout/user-status`;

  // Impersonate
  static readonly searchUsersUrl = `${ NSUrlsConstants.baseUrl }/user-management/user/search-active-external`;
  static readonly impersonateUserUrl = `${ NSUrlsConstants.baseUrl }/impersonate/user`;

  // Revenue Accounting
  static readonly revenueAccountingBaseUrl = `${ NSUrlsConstants.baseUrl }/revenue`;
  static readonly revenueContractComplianceBaseUrl = `${ NSUrlsConstants.revenueAccountingBaseUrl }/contract-compliance`;
  static readonly revenueContractsSummaryUrl = `${ NSUrlsConstants.revenueContractComplianceBaseUrl }/contracts`;
  static readonly revenueContractDetailsUrl = `${ NSUrlsConstants.revenueContractComplianceBaseUrl }/periods`;
  static readonly revenueContractDetailsTotalsUrl = `${ NSUrlsConstants.revenueContractComplianceBaseUrl }/period-details`;
  static readonly revenueCasesBaseUrl = `${ NSUrlsConstants.baseUrl }/revenue/cases`;
  static readonly revenueContractsStatusesSummaryUrl = `${ NSUrlsConstants.revenueAccountingBaseUrl }/contract/summary`;
  static readonly revenueContractsUrl = `${ NSUrlsConstants.revenueAccountingBaseUrl }/contract`;
  static readonly revenueContractDetailsCasesUrl = `${ NSUrlsConstants.revenueContractComplianceBaseUrl }/contract-details/cases`;

  static readonly revenueContractVolumeBaseUrl = `${ NSUrlsConstants.revenueAccountingBaseUrl }/contract-volumes`;
  static readonly revenueContractVolumeSummaryUrl = `${ NSUrlsConstants.revenueContractVolumeBaseUrl }/contracts`;
  static readonly revenueContractVolumesDetailsUrl = `${ NSUrlsConstants.revenueContractVolumeBaseUrl }/periods`;
  static readonly revenueContractVolumesDetailsTotalsUrl = `${ NSUrlsConstants.revenueContractVolumeBaseUrl }/period-details`;

  // Tracing
  static readonly tracingBaseUrl = `${ NSUrlsConstants.baseUrl }/tracing`;
  static readonly tracingCommoditiesUrl = `${ NSUrlsConstants.tracingBaseUrl }/commodities`;
  static readonly tracingStationsUrl = `${ NSUrlsConstants.tracingBaseUrl }/stations`;
  static readonly tracingAarTypesUrl = `${ NSUrlsConstants.tracingBaseUrl }/umler/aar-types`;
  static readonly tracingUmlerDataUrl = `${ NSUrlsConstants.tracingBaseUrl }/umler`;

  // Car Load Traffic
  static readonly carTrafficUrl = `${ NSUrlsConstants.tracingBaseUrl }/carload`;
  static readonly carTraffic3YearsUrl = `${ NSUrlsConstants.carTrafficUrl }/traffic-history-3-year-summary`;

  // Emission Report
  static readonly emissionReportUrl = `${ NSUrlsConstants.baseUrl }/emissions-report`;
  static readonly emissionReportFilterUrl = `${ NSUrlsConstants.emissionReportUrl }/filters`;
  static readonly emissionReportAuditHistoryUrl = `${ NSUrlsConstants.emissionReportUrl }/audit-history`;
  static readonly emissionReportAuditHistoryLogDownloadUrl = `${ NSUrlsConstants.emissionReportUrl }/audit-history/log-download`;

  // Holds
  static readonly unitHoldsUrl = `${ NSUrlsConstants.pipelineBaseUrl }/intermodal/holds`;

  // Revenue Claims
  static readonly revenueClaimsBaseUrl = `${ NSUrlsConstants.baseUrl }/revenue-claims/claim`;
  static readonly revenueClaimsCompanyUrl = `${ NSUrlsConstants.baseUrl }/revenue-claims/claim/company`;
  static readonly revenueNatureOfClaimUrl = `${ NSUrlsConstants.revenueClaimsBaseUrl }/nature-of-claim`;
  static readonly revenueClaimsStatusesUrl = `${ NSUrlsConstants.revenueClaimsBaseUrl }/claim-statuses`;
  static readonly revenueGetValidFreightBillUrl = `${ NSUrlsConstants.baseUrl }/revenue-claims/freight-bill`;

  // Order Fill
  static readonly orderFillUrl = `${ NSUrlsConstants.baseUrl }/strategic-order-fill`;

  // Intermodal Report
  static readonly intermodalReportUrl = `${ NSUrlsConstants.baseUrl }/intermodal-report`;

  // Left Behinds
  static readonly leftBehindsDetailsUrl = `${ NSUrlsConstants.intermodalReportUrl }/left-behind/details`;
  static readonly leftBehindsReasonCodesUrl = `${ NSUrlsConstants.catalogsBaseUrl }/catalogs/12`;
  static readonly leftBehindsSummaryUrl = `${ NSUrlsConstants.intermodalReportUrl }/left-behind/loadEmpty`;

  // Company management
  static readonly companyManagementUrl = `${ NSUrlsConstants.baseUrl }/company`;

  // Context
  static readonly applicationVersionUrl = `/assets/context/version`;
  static readonly applicationEnvironmentUrl = `/assets/context/environment`;

  // Marketing Reports
  static readonly marketingReportsUrl = `${ NSUrlsConstants.baseUrl }/mktg-reports`;
  static readonly marketingReportTestFtpUrl = `${ NSUrlsConstants.marketingReportsUrl }/ftp/test`;
  static readonly marketingReportsDeliveryConfigUrl = `${ NSUrlsConstants.marketingReportsUrl }/delivery-config`;
  static readonly marketingReportsEditUrl = `${ NSUrlsConstants.marketingReportsDeliveryConfigUrl }/edit-view`;
  static readonly marketingReportsActiveStatusUrl = `${ NSUrlsConstants.marketingReportsUrl }/performance`;

  //Driver Activity
  static driverBaseUrl = `${ NSUrlsConstants.baseUrl }/driver-activity`;
  static readonly driverActivitySearchUrl = `${ NSUrlsConstants.driverBaseUrl }/activities`;

  //IARS
  static readonly iarsBaseUrl = `${ NSUrlsConstants.baseUrl }/iars`;
  static readonly iarsAllocationsUrl = `${ NSUrlsConstants.iarsBaseUrl }/allocations`;
  static readonly iarsAllocationsSummaryUrl = `${ NSUrlsConstants.iarsAllocationsUrl }/summary`;
  static readonly iarsAllocationsDetailUrl = `${ NSUrlsConstants.iarsAllocationsUrl }/detail`;
  static readonly iarsReservationsBaseUrl = `${ NSUrlsConstants.iarsBaseUrl }/reservations`;
  static readonly iarsUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/details`;
  static readonly iarsMonthlySummaryUrl =`${ NSUrlsConstants.iarsReservationsBaseUrl }/monthly-summary`;
  static readonly iarsDailySummaryUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/daily-summary`;
  static readonly iarsReservationsOverTimeUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/over-time`;
  static readonly iarsReserveUnitUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/reserve-unit`;
  static readonly iarsSubmitUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/create-reservation`;
  static readonly iarsCancelReservationUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/cancel-reservation`;
  static readonly iarsCancelReservationsUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/cancel-reservations`;
  static readonly iarsRemoveUnitUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/remove-unit`;
  static readonly iarsRemoveMultipleUnitsUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/remove-multiple-units`;
  static readonly iarsRescheduleReservationUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/reschedule-reservation`;
  static readonly iarsCatalogUrl = `${ NSUrlsConstants.iarsBaseUrl }/catalog`;
  static readonly iarsTerminalsUrl = `${ NSUrlsConstants.iarsCatalogUrl }/terminals`;
  static readonly iarsUnitTypesUrl = `${ NSUrlsConstants.iarsCatalogUrl }/unit-types`;
  static readonly iarsFillOrderUnitUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/unit-reservation/fill`;
  static readonly iarsSwapUnitUrl = `${ NSUrlsConstants.iarsReservationsBaseUrl }/swap/unit-numbers`;
  static readonly iarsLineUtilizationUrl = `${ NSUrlsConstants.iarsBaseUrl }/line-utilization`;
  static readonly iarsLineUtilizationWidgetUrl = `${ NSUrlsConstants.iarsLineUtilizationUrl }/widget`;
  static readonly iarsAllocationsChangesOverTimeUrl = `${ NSUrlsConstants.iarsAllocationsUrl }/allocations-changes-over-time`;
}
