export enum ECRParameterType {
  BODY = 'BODY_JSON',
  QUERY = 'QUERY'
}

export enum ECRFilterComparator {
  EQUALS = 'EQUALS',
  LESSTHAN = 'LESSTHAN',
  GREATERTHAN = 'GREATERTHAN',
  INRANGE = 'INRANGE',
  NOTEQUAL = 'NOTEQUAL'
}

export enum ECRParseStrategy {
  DEFAULT = 'DEFAULT',
  QUICK_SEARCH = 'QUICK_SEARCH',
  STORAGE_GUARANTEE = 'STORAGE_GUARANTEE',
  UT_LOADED = 'UT_LOADED',
  UT_CONSIST = 'UT_CONSIST',
  UT_RELEASE_EMPTIES = 'UT_RELEASE_EMPTIES',
  UT_COAL_ORDER_FULFILLMENT = 'UT_COAL_ORDER_FULFILLMENT',
  IP_AVAILABLE = 'IP_AVAILABLE',
  IP_RECEIVING = 'IP_RECEIVING',
  IP_IN_PROGRESS = 'IP_IN_PROGRESS',
  IP_LEASE = 'IP_LEASE',
  IP_QUANTITY_ORDER = 'IP_QUANTITY_ORDER',
  IP_ON_TRACK = 'IP_ON_TRACK',
  IP_SHIPPING = 'IP_SHIPPING',
  IP_ACCESSORIAL_INTRA_PLANT_SWITCH = 'IP_ACCESSORIAL_INTRA_PLANT_SWITCH',
  IP_ACCESSORIAL_INTRA_TERMINAL_SWITCH = 'IP_ACCESSORIAL_INTRA_TERMINAL_SWITCH',
  IP_ACCESSORIAL_PULL_TURN = 'IP_ACCESSORIAL_PULL_TURN',
  IP_ACCESSORIAL_PULL_WEIGHT = 'IP_ACCESSORIAL_PULL_WEIGHT',
  IP_ACCESSORIAL_ORDER_NOT_USED = 'IP_ACCESSORIAL_ORDER_NOT_USED',
  IP_ACCESSORIAL_CHARGEABLE_REASON_CODE = 'IP_ACCESSORIAL_CHARGEABLE_REASON_CODE',
  IP_ACCESSORIAL_LATE_SURRENDER = 'IP_ACCESSORIAL_LATE_SURRENDER',
  IP_ACCESSORIAL_LEASE_TRACK_OVERAGES = 'IP_ACCESSORIAL_LEASE_TRACK_OVERAGES',
  IP_CANDIDATE_DETAIL_OPEN = 'IP_CANDIDATE_DETAIL_OPEN',
  IP_CANDIDATE_DETAIL_CLOSE = 'IP_CANDIDATE_DETAIL_CLOSE',
  IP_STORAGE_DISPUTE = 'IP_STORAGE_DISPUTE',
  IP_DEMURRAGE_DISPUTE = 'IP_DEMURRAGE_DISPUTE',
  IP_BILLING_HISTORY = 'IP_BILLING_HISTORY',
  IP_ACCESSORIAL_HISTORY_CHARGEABLE_CODES = 'IP_ACCESSORIAL_HISTORY_CHARGEABLE_CODES',
  IP_ACCESSORIAL_HISTORY_INTRA_PLANTS = 'IP_ACCESSORIAL_HISTORY_INTRA_PLANTS',
  IP_ACCESSORIAL_HISTORY_INTRA_TERMINALS = 'IP_ACCESSORIAL_HISTORY_INTRA_TERMINALS',
  IP_ACCESSORIAL_HISTORY_LATE_SURRENDER = 'IP_ACCESSORIAL_HISTORY_LATE_SURRENDER',
  IP_ACCESSORIAL_HISTORY_LEASE_TRACK_OVERAGES = 'IP_ACCESSORIAL_HISTORY_LEASE_TRACK_OVERAGES',
  IP_ACCESSORIAL_HISTORY_PULL_TURN = 'IP_ACCESSORIAL_HISTORY_PULL_TURN',
  IP_ACCESSORIAL_HISTORY_PULL_WEIGHT = 'IP_ACCESSORIAL_HISTORY_PULL_WEIGHT',
  IP_ACCESSORIAL_HISTORY_UNUSED_ORDER = 'IP_ACCESSORIAL_HISTORY_UNUSED_ORDER',
  MAP_ACTIVE_SHIPMENTS = 'MAP_ACTIVE_SHIPMENTS'
}

export enum ECRFolderStructureSubtype {
  USER = 'USER',
  COMPANY = 'COMPANY'
}

export enum ECRFolderStructureType {
  USER_FOLDER = 'USER_FOLDER',
  COMPANY_FOLDER = 'COMPANY_FOLDER'
}

export enum ECRStructureType {
  FOLDER = 'FOLDER',
  REPORT = 'REPORT'
}

export enum ECRScheduleType {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  INTRADAY = 'INTRADAY'
}

export enum ECRDataType {
  STRING = 'STRING',
  DATE = 'DATE'
}

export enum ECRFieldFormat {
  ISO = 'ISO',
  NONE = 'NONE'
}

export enum ECRFormat {
  PDF = 'PDF',
  EXCEL = 'EXCEL',
  CSV = 'CSV',
  HTML = 'HTML',
  TEXT = 'TEXT'
}

export enum ECRStatusType {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  DELETED = 'DELETED'
}

export enum ECRDeliveryType {
  EMAIL = 'EMAIL',
  FTP = 'FTP'
}

export enum ECRRecentlyReportType {
  ACTIVE = 'ACTIVE',
  UPDATED = 'UPDATED',
  DELETED = 'DELETED'
}

export enum ECRRootStructureType {
  PERSONAL_FOLDER = 'PERSONAL FOLDER',
  COMPANIES_FOLDER = 'COMPANIES FOLDER'
}

export enum ECRActiveReports {
  ACTIVE_PERSONAL = 'ACTIVE PERSONAL',
  ACTIVE_COMPANY = 'ACTIVE COMPANY'
}

export enum ECRReportType {
  GRID = 'GRID',
  CALENDAR = 'CALENDAR',
  CHART = 'CHART',
  MAP = 'MAP'
}

export enum ECRAttachmentType {
  SINGLE_FILE = 'SINGLE_FILE',
  MULTIPLE_FILES = 'MULTIPLE_FILES'
}
