import { createSelector } from '@ngrx/store';
import { EPersona } from '@norfolk-southern/accessns-components';

import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { selectFeatureState, State } from '@ruby/modules/customer-select/store/reducers';
import * as fromCustomer from '@ruby/modules/customer-select/store/reducers/customers.reducer';

export const selectCustomerState = createSelector(
  selectFeatureState,
  (customerState: State) => customerState[fromCustomer.featureKey]
);

export const selectCurrentCustomerSelected = createSelector(
  selectFeatureState,
  (customerState: State) => customerState[fromCustomer.featureKey].selectedCustomer
);

export const hasIpCustomers = createSelector(
  selectCustomerState,
  (state: fromCustomer.State) =>
    !!state.customers.find((customer: ICustomer) => customer.type.name === EPersona.IP && customer.locations.length > 0)
);

export const selectParentCustomer = createSelector(
  selectCustomerState,
  (state: fromCustomer.State) =>
    state.customers.find((customer: ICustomer) => customer.customerIdentifier === state.selectedCustomer?.customerIdentifier)
);
