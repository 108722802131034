import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@ruby/core/guards/auth.guard';
import { LoginGuard } from '@ruby/core/guards/login.guard';
import { SecurityLayoutComponent } from '@ruby/core/layout/security-layout/security.layout';

const routes: Routes = [
  {
    path: 'redirect',
    component: SecurityLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('@ruby/modules/security/security.module').then(m => m.SecurityModule)
      }
    ]
  },
  {
    path: 'auth',
    component: SecurityLayoutComponent,
    children: [
      {
        path: 'report-unsubscribe/:reportId',
        loadChildren: () => import('./modules/customized-reporting-unsubscribe/customized-reporting-unsubscribe.module').then(m => m.CustomizedReportingUnsubscribeModule)
      },
      {
        path: 'ens-unsubscribe/:notificationId',
        loadChildren: () => import('./modules/unsubscribe-notification/unsubscribe-notification.module').then(m => m.UnsubscribeNotificationModule)
      }
    ],
    canActivate: [LoginGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('@ruby/core/layout/app-layout/app-layout.module').then(m => m.AppLayoutModule),
    canActivate: [AuthGuard]
  },
  { path: '**', redirectTo: '/redirect/main', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
