import { createAction, props } from '@ngrx/store';

import { IUnitTripPlan } from '@ruby/modules/unit-details/models/unit-details.interface';
import { ITransaction } from '@ruby/shared/models/commons/transaction.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const resetUnitDetail = createAction(
  '[Unit Details] Reset Unit Details'
);

export const loadEquipmentTripPlan = createAction(
  '[Unit Details] Load Equipment Trip Plan',
  props<{ data: { initial: string; number: string; waybill?: string } }>()
);

export const loadEquipmentTripPlanSuccess = createAction(
  '[Unit Details] Load Equipment Trip Plan Success',
  props<{ data: IUnitTripPlan }>()
);

export const loadEquipmentTripPlanFailure = createAction(
  '[Unit Details] Load Equipment Trip Plan Failure',
  props<{ error: IError }>()
);

export const saveCriteria = createAction(
  '[Unit Details] Save Criteria',
  props<{ data: ITransaction }>()
);

export const saveCriteriaSuccess = createAction(
  '[Unit Details] Save Criteria Success'
);

export const saveCriteriaFailure = createAction(
  '[Unit Details] Save Criteria Failure',
  props<{ error: IError }>()
);
