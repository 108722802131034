import { Action, createReducer, on } from '@ngrx/store';

import { ICustomer, ILocation } from '@ruby/modules/customer-select/models/customer.interface';
import * as SLCustomersActions from '@ruby/modules/customer-select/store/actions/sl-customers.actions';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'slCustomers';

export interface State {
  customers: Array<ICustomer>;
  locations: Array<ILocation>;
  loading: boolean;
  error?: IError;
}

export const initialState: State = {
  customers: [],
  locations: [],
  loading: false,
  error: undefined
};

const shortLineCustomersReducer = createReducer(
  initialState,
  on(SLCustomersActions.loadShortLineCustomers, state => ({
    ...state,
    loading: true,
    locations: [],
    error: undefined
  })),
  on(SLCustomersActions.loadShortLineCustomersSuccess, (state, action) => ({
    ...state,
    loading: false,
    customers: action.data,
    locations: [],
    error: undefined
  })),
  on(SLCustomersActions.loadShortLineCustomersFailure, (state, action) => ({
    ...state,
    loading: false,
    customers: [],
    locations: [],
    error: action.error
  })),

  on(SLCustomersActions.loadShortLineLocations, state => ({
    ...state,
    loading: true,
    locations: [],
    error: undefined
  })),
  on(SLCustomersActions.loadShortLineLocationsSuccess, (state, action) => ({
    ...state,
    loading: false,
    locations: action.data,
    error: undefined
  })),
  on(SLCustomersActions.loadShortLineLocationsFailure, (state, action) => ({
    ...state,
    loading: false,
    locations: [],
    error: action.error
  }))
);

export const reducer = (state: State | undefined, action: Action) => shortLineCustomersReducer(state, action);
