import { Action, createReducer, on } from '@ngrx/store';

import * as SecurityActions from '@ruby/modules/security/store/actions/security.actions';
import { IAccessToken } from '@ruby/shared/models/commons/authentication.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'security';

export interface State {
  isAuthenticated: boolean;
  token?: IAccessToken;
  loading: boolean;
  loginWithCredentials: boolean;
  error?: IError;
}

export const initialState: State = {
  isAuthenticated: false,
  token: undefined,
  loading: false,
  loginWithCredentials: false,
  error: undefined
};

const userReducer = createReducer(
  initialState,
  on(SecurityActions.getAccessToken, state => ({
    ...state,
    loading: true,
    error: undefined
  })),
  on(SecurityActions.getAccessTokenSuccess, (state, action) => ({
    ...state,
    isAuthenticated: true,
    token: action.data,
    loading: false
  })),
  on(SecurityActions.getAccessTokenFailure, (state, action) => ({
    ...state,
    isAuthenticated: false,
    loading: false,
    error: action.error
  })),
  on(SecurityActions.checkExistingSessionSuccess, (state, action) => ({
    ...state,
    isAuthenticated: true,
    token: action.auth
  })),
  on(SecurityActions.refreshTokenSuccess, (state, action) => ({
    ...state,
    isAuthenticated: true,
    token: action.auth
  })),
  on(SecurityActions.logout, () => (initialState))
);

export const reducer = (state: State | undefined, action: Action) => userReducer(state, action);
