import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NSCustomizedReportingGridConstants } from '@ruby/configs/customized-reporting-grid.constants';
import { ICRRouteMap } from '@ruby/modules/customized-reporting/models/report.interface';
import { GridRequestCustomizedReportingService } from '@ruby/shared/services/grid-request-customized-reporting.service';

@Injectable({
  providedIn: 'root'
})
export class CustomizedReportingRequestInterceptor implements HttpInterceptor {

  constructor(private gridRequestService: GridRequestCustomizedReportingService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const grid: ICRRouteMap | Array<ICRRouteMap> = NSCustomizedReportingGridConstants.gridNameRouteMap[request.url];

    if (Array.isArray(grid)) {
      grid.forEach((g: ICRRouteMap) => {
        this.saveRequest(request, g);
      });
    } else {
      this.saveRequest(request, grid);
    }
    return next.handle(request);
  }

  private saveRequest(request: HttpRequest<any>, grid: ICRRouteMap): void {
    if (grid && grid.method === request.method) {
      const gridName = grid.gridNamePropertyFromPayload ? request.body[grid.gridNamePropertyFromPayload] : grid.name;
      this.gridRequestService.saveGridHttpRequest(gridName, request, grid.parseStrategy, grid.isFormData);
    }
  }
}
