import { Injectable } from '@angular/core';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { IContact } from '@ruby/modules/account/models/contact.interface';
import { ICustomer, ILocation } from '@ruby/modules/customer-select/models/customer.interface';
import { IAuthorizations } from '@ruby/shared/models/commons/authentication.interface';
import { AppConfigService } from '@ruby/shared/services/app-config.service';

@Injectable({
  providedIn: 'root'
})
export class LaunchDarklyService {
  client?: LDClient.LDClient = undefined;
  clientSideId = '';
  userContext?: LDClient.LDContext;
  isReady = false;
  isClientInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  clientInitialized = this.isClientInitialized.asObservable();

  constructor(private appConfig: AppConfigService) {}

  /**
   * Initialize LaunchDarkly
   *
   * @summary Initialize Pendo
   * @param data IAuthorizations
   * @returns void
   */
  initializeLaunchDarkly(data: IAuthorizations): void {
    this.clientSideId = this.appConfig.getConfig().launchDarklyClientId;

    this.userContext = {
      kind: 'user',
      key: data.userRacf,
      role: data.roles,
      authorizations: data.authorizations,
      _meta: {
        privateAttributes: []
      }
    };
    this.client = LDClient.initialize(this.clientSideId, this.userContext);
    this.isClientInitialized.next(true);

    this.client.waitUntilReady().then(() => {
      this.isReady = true;
    });
  }

  /**
   * Stop LaunchDarkly
   *
   * @summary Stop LaunchDarkly
   * @returns Promise<void>
   */
  async stopLaunchDarkly(): Promise<void> {
    if (this.client) {
      return await this.client.close();
    }
  }

  /**
   * Get Flag Value
   *
   * @summary Get Flag Value
   * @returns Promise<LDClient.LDFlagValue>
   */
  async getFlagValue(key: string, defaultValue: LDClient.LDFlagValue = false): Promise<LDClient.LDFlagValue> {
    let flagValue: LDClient.LDFlagValue;
    if (this.client) {
      flagValue = await this.client.variation(key, defaultValue);
      return flagValue;
    }
    return Promise.reject(`LD Client no initialized get flag: ${ key }`);
  }

  /**
   * Get Flag Value Observable
   *
   * @summary Get Flag Value Observable
   * @returns Observable<LDClient.LDFlagValue>
   */
  getFlagValueObservable(key: string, defaultValue: LDClient.LDFlagValue = false): Observable<LDClient.LDFlagValue> {
    const fetchFlag = new Subject<void>();
    if (this.client) {
      this.client.on(`change:${ key }`, () => {
        fetchFlag.next();
      });
      this.client.waitUntilReady().then(() => {
        fetchFlag.next();
      });
      return fetchFlag.pipe(
        map(() => this.client!.variation(key, defaultValue) as LDClient.LDFlagValue)
      );
    }
    return throwError(() => `LD Client no initialized get obs flag: ${ key }`);
  }

  /**
   * Check Add Contact Metadata
   *
   * @summary Check Add Contact Metadata
   * @param data IContact
   * @returns void
   */
  checkAddContactMetadata(data: IContact): void {
    setTimeout(() => this.addContactMetadata(data), 5000);
  }

  /**
   * Add Contact Metadata
   *
   * @summary Add Contact Metadata
   * @param data IContact
   * @returns void
   */
  addContactMetadata(data: IContact): void {
    this.userContext = {
      ...this.userContext,
      kind: 'user',
      key: data.racf,
      role: (this.userContext as any).role,
      authorizations: (this.userContext as any).authorizations,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName
    };
    this.client!.identify(this.userContext).then(response => {});
  }

  /**
   * Check Add Customers Metadata
   *
   * @summary Check Add Customers Metadata
   * @param customers Array<ICustomer>
   * @param selectedCustomer?: ICustomer
   * @returns void
   */
  checkCustomersMetadata(customers: Array<ICustomer>, selectedCustomer?: ICustomer): void {
    setTimeout(() => this.addCustomersMetadata(customers, selectedCustomer), 5000);
  }

  /**
   * Change selected customer
   *
   * @summary will update user context with selected customer and request flags with new context
   * @param selectedCustomer: ICustomer
   * @returns void
   */
  changeSelectedCustomer(selectedCustomer: ICustomer): void {
    this.userContext = {
      ...this.userContext,
      selectedCustomer: selectedCustomer.locations.map((d: ILocation) => `${ d.classCode }-${ d.stationCode }`).join(', ')
    } as LDClient.LDContext;
    this.client!.identify(this.userContext).then(response => {});
  }

  /**
   * Get Locations Metadata
   *
   * @summary Get Locations Metadata
   * @param locations Array<ILocation>
   * @returns string
   */
  getLocationsMetadata(locations: Array<ILocation>): string {
    if (locations && locations.length) {
      return locations.filter((location: ILocation) => location.classCode || location.stationCode)
        .map((location: ILocation) => `${ location.classCode || '' }-${ location.stationCode || '' }`)
        .join(',');
    }
    return '';
  }

  /**
   * Add Customers Metadata
   *
   * @summary Add Customers Metadata
   * @param customers Array<ICustomer>
   * @param selectedCustomer?: ICustomer
   * @returns void
   */
  addCustomersMetadata(customers: Array<ICustomer>, selectedCustomer?: ICustomer): void {
    const customersRaw: Array<string> = customers.map((customer: ICustomer) =>
      // eslint-disable-next-line max-len
      `| p6:${ customer.masterPrimarySix || '' } | name:${ customer.customerName || '' } | scac:${ customer.scac || '' } | type:${ customer.type?.name || '' } |` +
      ` cmlP6:${ customer.parentCustomer?.commercialPrimarySix || '' } | cmlName:${ customer.parentCustomer?.commercialName || '' } |` +
      ` cmlSeg:${ customer.parentCustomer?.commercialSegmentation || '' } | cmlTier:${ customer.parentCustomer?.commercialTier || '' } |` +
      ` cmlType:${ customer.parentCustomer?.commercialType || '' } |` +
      ` comP6:${ customer.parentCustomer?.commonPrimarySix || '' } | comName:${ customer.parentCustomer?.commonName || '' } |` +
      ` comSeg:${ customer.parentCustomer?.commonSegmentation || '' } | comTier:${ customer.parentCustomer?.commonTier || '' } |` +
      ` comType:${ customer.parentCustomer?.commonType || '' } | validIMP6:${ customer.isValidIMP6 || '' } | validDrayScac:${ customer.isValidDrayScac || '' } |` +
      ` locations:${ this.getLocationsMetadata(customer.locations) } |`
    );
    this.userContext = {
      ...this.userContext,
      customers: customersRaw
    } as LDClient.LDContext;
    if (selectedCustomer) {
      this.userContext = {
        ...this.userContext,
        selectedCustomer: selectedCustomer.locations.map((d: ILocation) => `${ d.classCode }-${ d.stationCode }`).join(', ')
      } as LDClient.LDContext;
    }
    this.client!.identify(this.userContext).then(response => {});
  }
}
