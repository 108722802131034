import { Action, createReducer, on } from '@ngrx/store';

import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import * as IPCustomersActions from '@ruby/modules/customer-select/store/actions/ip-customers.actions';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'ipCustomers';

export interface State {
  customers: Array<ICustomer>;
  loading: boolean;
  error?: IError;
}

export const initialState: State = {
  customers: [],
  loading: false,
  error: undefined
};

const carLoadCustomersReducer = createReducer(
  initialState,
  on(IPCustomersActions.searchCarLoadCustomers, state => ({
    ...state,
    loading: true,
    error: undefined
  })),
  on(IPCustomersActions.searchCarLoadCustomersSuccess, (state, action) => ({
    ...state,
    loading: false,
    customers: action.data,
    error: undefined
  })),
  on(IPCustomersActions.searchCarLoadCustomersFailure, (state, action) => ({
    ...state,
    loading: false,
    customers: [],
    error: action.error
  })),
  on(IPCustomersActions.clearCarLoadCustomers, (state, action) => ({
    ...initialState
  }))
);

export const reducer = (state: State | undefined, action: Action) => carLoadCustomersReducer(state, action);
