import { HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ICRGridRequest, ICRRequestParameter } from '@ruby/modules/customized-reporting/models/report.interface';
import * as GridRequestActions from '@ruby/modules/customized-reporting/store/actions/grid-request-customized-reporting.actions';
import * as FavoriteActions from '@ruby/shared/cr-favorites/store/actions/cr-favorites.actions';
import { ECRParameterType, ECRParseStrategy } from '@ruby/shared/enums/customized-reporting.enums';

@Injectable({
  providedIn: 'root'
})
export class GridRequestCustomizedReportingService {

  constructor(private store: Store) {}

  /**
   * Save Grid Http Request
   *
   * @summary Save grid http request
   * @param gridName: string
   * @param request: HttpRequest<any>
   * @param parseStrategy: ECRParseStrategy
   * @param isFormData?: boolean
   * @returns ICRGridRequest
   */
  saveGridHttpRequest(gridName: string, request: HttpRequest<any>, parseStrategy: ECRParseStrategy, isFormData?: boolean): void {
    const gridRequest = {
      endpoint: request.url,
      requestType: request.method,
      parameters: this.generateParameters(request),
      parseStrategy
    } as ICRGridRequest;

    if (isFormData) {
      gridRequest.formData = this.stringifyAndReplace(request.body);
    }

    this.dispatch(gridName, gridRequest);
  }

  /**
   * Save Grid Request
   *
   * @summary Save Grid Request
   * @param gridName: string
   * @param gridRequest: ICRGridRequest
   * @returns void
   */
  saveGridRequest(gridName: string, gridRequest: ICRGridRequest): void {
    const request = { ...gridRequest };
    if (gridRequest.formData) {
      request.formData = this.stringifyAndReplace(gridRequest.formData);
      request.parameters = [{
        field: ECRParameterType.BODY,
        value: request.formData,
        type: ECRParameterType.BODY
      }];
    }
    this.dispatch(gridName, gridRequest);
  }

  /**
   * Clear Favorites
   *
   * @summary This function clear the selected favorite
   * @param gridName: string
   * @returns void
   */
  clearFavorite(gridName: string): void {
    this.store.dispatch(FavoriteActions.clearSelected({ data: { gridName } }));
  }

  private dispatch(gridName: string, gridRequest: ICRGridRequest): void {
    this.store.dispatch(GridRequestActions.saveGridRequest({ data: { name: gridName, request: gridRequest } }));
  }

  private stringifyAndReplace(data: any): string {
    return JSON.stringify(data).replace(/\\n/g, ' ');
  }

  private generateParameters(request: HttpRequest<any>): Array<ICRRequestParameter> {
    let parameters: Array<ICRRequestParameter> = [];
    if (request.body) {
      parameters.push({
        field: ECRParameterType.BODY,
        value: this.stringifyAndReplace(request.body),
        type: ECRParameterType.BODY
      } as ICRRequestParameter);
    }
    if (request.params) {
      parameters = parameters.concat(this.generateQueryParam(request.params, ECRParameterType.QUERY));
    }
    return parameters;
  }

  private generateQueryParam(params: HttpParams, type: ECRParameterType): Array<ICRRequestParameter> {
    return params.keys().map((key: string) => ({
      field: key,
      value: params.get(key)!,
      type
    }));
  }
}
