import { INSGridReportData } from '@norfolk-southern/accessns-components';
import { AgGridAngular } from 'ag-grid-angular';

import { NSUrlsConstants } from '@ruby/configs/urls.constants';
import { ICRGridNameRouteMap, ICRTab } from '@ruby/modules/customized-reporting/models/report.interface';
import { IUnitTrainFilters } from '@ruby/modules/unit-train/models/unit-train.interface';
import { ERestMethod } from '@ruby/shared/enums/common.enums';
import { ECRParameterType, ECRParseStrategy, ECRReportType } from '@ruby/shared/enums/customized-reporting.enums';
import { EExceptionsGridType } from '@ruby/shared/enums/exceptions.enums';
import { EGridType } from '@ruby/shared/enums/grid.enums';

export class NSCustomizedReportingGridConstants {

  static readonly gridNameRouteMap: ICRGridNameRouteMap = {
    [NSUrlsConstants.eventCodesUrl]: {
      name: EGridType.RC_EVENT_CODE,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.tracingCommoditiesUrl]: {
      name: EGridType.RC_COMMODITY,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.tracingStationsUrl]: {
      name: EGridType.RC_STATION,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.tracingUmlerDataUrl]: {
      name: EGridType.RC_UMLER,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.emissionReportAuditHistoryUrl]: {
      name: EGridType.RC_AUDIT_HISTORY,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.carTraffic3YearsUrl]: {
      name: EGridType.CAR_TRAFFIC_THREE_YEAR_HISTORY,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.iBolShipmentInstructionsUrl]: {
      name: EGridType.IBOL_CREATED_BILLS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.unitTrainShippingListingUrl]: {
      name: EGridType.UT_SHIPPING_LISTING,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT,
      isFormData: true
    },
    [NSUrlsConstants.watchUrl]: {
      name: EGridType.WATCHLIST,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.quickSearchBaseUrl }/${ EGridType.BAD_ORDERS_SUMMARY }`]: {
      name: EGridType.BAD_ORDERS_SUMMARY,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.QUICK_SEARCH
    },
    [`${ NSUrlsConstants.quickSearchBaseUrl }/${ EGridType.TRACK_AND_TRACE }`]: {
      name: EGridType.TRACK_AND_TRACE,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.QUICK_SEARCH
    },
    [`${ NSUrlsConstants.quickSearchBaseUrl }/${ EGridType.TRACK_AND_TRACE_UMLER }`]: {
      name: EGridType.TRACK_AND_TRACE_UMLER,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.QUICK_SEARCH
    },
    [`${ NSUrlsConstants.quickSearchBaseUrl }/${ EGridType.SCALE_WEIGHTS }`]: {
      name: EGridType.SCALE_WEIGHTS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.QUICK_SEARCH
    },
    [`${ NSUrlsConstants.quickSearchBaseUrl }/${ EGridType.QUICK_DRAY }`]: {
      name: EGridType.QUICK_DRAY,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.QUICK_SEARCH
    },
    [`${ NSUrlsConstants.quickSearchBaseUrl }/${ EGridType.UMLER }`]: {
      name: EGridType.UMLER,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.QUICK_SEARCH
    },
    [`${ NSUrlsConstants.quickSearchBaseUrl }/${ EGridType.WAYBILL_VERIFICATION }`]: {
      name: EGridType.WAYBILL_VERIFICATION,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.QUICK_SEARCH
    },
    [`${ NSUrlsConstants.quickSearchBaseUrl }/${ EGridType.WAYBILL_HISTORY }`]: {
      name: EGridType.WAYBILL_HISTORY,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.QUICK_SEARCH
    },
    [`${ NSUrlsConstants.quickSearchBaseUrl }/${ EGridType.EVENT_HISTORY }`]: {
      name: EGridType.EVENT_HISTORY,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.QUICK_SEARCH
    },
    [NSUrlsConstants.onsiteInventoryUrl]: {
      name: EGridType.ON_TRACK,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.pipelineBaseUrl }/offline/details`]: {
      gridNamePropertyFromPayload: 'baseDetail',
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.pipelineBaseUrl }/intermodal/details`]: {
      gridNamePropertyFromPayload: 'baseDetail',
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.pipelineBaseUrl }/intermodal/details-all`]: {
      name: EGridType.IM_ACTIVE_SHIPMENT,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.pipelineBaseUrl }/industrial-product/details-all`]: {
      name: EGridType.IP_ACTIVE_SHIPMENT,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.pipelineBaseUrl }/short-line/details`]: {
      gridNamePropertyFromPayload: 'baseDetail',
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.pipelineBaseUrl }/dray/details`]: {
      gridNamePropertyFromPayload: 'baseDetail',
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.pipelineBaseUrl }/unit-train/details`]: {
      gridNamePropertyFromPayload: 'baseDetail',
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.reservationsByStatusUrl]: {
      gridNamePropertyFromPayload: 'status',
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.storageGuaranteeBaseUrl]: {
      gridNamePropertyFromPayload: 'guaranteeType',
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.STORAGE_GUARANTEE
    },
    [`${ NSUrlsConstants.exceptionUrl }/im/details/bad_orders`]: {
      name: EExceptionsGridType.BAD_ORDERS_IM,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/im/details/storage`]: {
      name: EExceptionsGridType.STORAGE,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/sl/details/bad_orders`]: {
      name: EExceptionsGridType.BAD_ORDERS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/sl/details/overload`]: {
      name: EExceptionsGridType.OVERLOAD,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/sl/details/statement`]: {
      name: EExceptionsGridType.STATEMENT_EXCEPTION,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/sl/details/no_bills`]: {
      name: EExceptionsGridType.NO_BILLS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/ut/details/bad_orders`]: {
      name: EExceptionsGridType.BAD_ORDERS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/ut/details/no_bills`]: {
      name: EExceptionsGridType.NO_BILLS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/ut/details/overload`]: {
      name: EExceptionsGridType.OVERLOAD,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/ip/details/bad_orders`]: {
      name: EExceptionsGridType.BAD_ORDERS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/ip/details/demurrage`]: {
      name: EExceptionsGridType.DEMURRAGE,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/ip/details/no_bills`]: {
      name: EExceptionsGridType.NO_BILLS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.exceptionUrl }/ip/details/overload`]: {
      name: EExceptionsGridType.OVERLOAD,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.receivingInventoryUrl]: [
      {
        name: EGridType.AVAILABLE_FOR_PLACEMENT,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_AVAILABLE
      },
      {
        name: EGridType.RECEIVING_PIPELINE,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_RECEIVING
      },
      {
        name: EGridType.SERVICE_IN_PROGRESS,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_IN_PROGRESS
      },
      {
        name: EGridType.LEASE_TRACK,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_LEASE
      }
    ],
    [NSUrlsConstants.receivingQuantityOrdersUrl]: {
      name: EGridType.RECEIVING_QUANTITY_ORDER,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.IP_QUANTITY_ORDER
    },
    [NSUrlsConstants.onsiteInventoryUrl]: {
      name: EGridType.ON_TRACK,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.IP_ON_TRACK
    },
    [NSUrlsConstants.shippingInventoryUrl]: {
      name: EGridType.SHIPPING_INVENTORY,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.IP_SHIPPING
    },
    [NSUrlsConstants.accessorialUrl]: [
      {
        name: EGridType.INTRA_PLANT_SWITCH,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_INTRA_PLANT_SWITCH
      },
      {
        name: EGridType.INTRA_TERMINAL_SWITCH,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_INTRA_TERMINAL_SWITCH
      },
      {
        name: EGridType.PULL_TURN,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_PULL_TURN
      },
      {
        name: EGridType.PULL_WEIGHT,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_PULL_WEIGHT
      },
      {
        name: EGridType.ORDER_NOT_USED,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_ORDER_NOT_USED
      },
      {
        name: EGridType.CHARGEABLE_REASON_CODE,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_CHARGEABLE_REASON_CODE
      },
      {
        name: EGridType.LATE_SURRENDER,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_LATE_SURRENDER
      },
      {
        name: EGridType.LEASE_TRACK_OVERAGES,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_LEASE_TRACK_OVERAGES
      },
      {
        name: EGridType.HISTORY_INTRA_PLANT_SWITCH,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_HISTORY_INTRA_PLANTS
      },
      {
        name: EGridType.HISTORY_INTRA_TERMINAL_SWITCH,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_HISTORY_INTRA_TERMINALS
      },
      {
        name: EGridType.HISTORY_PULL_TURN,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_HISTORY_PULL_TURN
      },
      {
        name: EGridType.HISTORY_PULL_WEIGHT,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_HISTORY_PULL_WEIGHT
      },
      {
        name: EGridType.HISTORY_ORDER_NOT_USED,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_HISTORY_UNUSED_ORDER
      },
      {
        name: EGridType.HISTORY_CHARGEABLE_REASON_CODE,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_HISTORY_CHARGEABLE_CODES
      },
      {
        name: EGridType.HISTORY_LATE_SURRENDER,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_HISTORY_LATE_SURRENDER
      },
      {
        name: EGridType.HISTORY_LEASE_TRACK_OVERAGES,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_ACCESSORIAL_HISTORY_LEASE_TRACK_OVERAGES
      }
    ],
    [NSUrlsConstants.demurrageDisputeHistoryUrl]: [
      {
        name: EGridType.DISPUTE_HISTORY,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_STORAGE_DISPUTE
      },
      {
        name: EGridType.DEMURRAGE_DISPUTE_HISTORY,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_DEMURRAGE_DISPUTE
      }
    ],
    [NSUrlsConstants.demurragePendingDisputeUrl]: [
      {
        name: EGridType.PENDING_DISPUTES,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_STORAGE_DISPUTE
      },
      {
        name: EGridType.DEMURRAGE_PENDING_DISPUTES,
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_DEMURRAGE_DISPUTE
      }
    ],
    [NSUrlsConstants.demurrageUrl]: [
      {
        gridNamePropertyFromPayload: 'gridName',
        method: ERestMethod.POST,
        parseStrategy: ECRParseStrategy.IP_CANDIDATE_DETAIL_CLOSE
      }
    ],
    [NSUrlsConstants.serviceWindowUrl]: {
      gridNamePropertyFromPayload: 'gridName',
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.historicalUrl]: {
      gridNamePropertyFromPayload: 'gridName',
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.IP_BILLING_HISTORY
    },
    [NSUrlsConstants.historicalPCSUrl]: {
      name: EGridType.HISTORICAL_PRIVATE_CAR_STORAGE,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.IP_BILLING_HISTORY
    },
    [NSUrlsConstants.activeShipmentSummaryUrl]: {
      name: EGridType.ACTIVE_SHIPMENTS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.MAP_ACTIVE_SHIPMENTS
    },
    [NSUrlsConstants.damageNotificationResultsUrl]: {
      name: EGridType.DAMAGE_FREIGHT,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.ecrUserDataUrl]: {
      name: EGridType.ECR_PATTERNS,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.thirdPartyLOASUrl }/tpl-user`]: {
      name: EGridType.THIRD_PARTY,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.thirdPartyLOASUrl }/user`]: {
      name: EGridType.THIRD_PARTY,
      method: ERestMethod.GET,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.leftBehindsDetailsUrl]: {
      name: EGridType.LEFT_BEHINDS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [`${ NSUrlsConstants.iarsReservationsBaseUrl }/details`]: {
      name: EGridType.IARS_DETAILS,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.iarsMonthlySummaryUrl]: {
      name: EGridType.MONTHLY_SUMMARY,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.iarsDailySummaryUrl]: {
      name: EGridType.IARS_DAILY_SUMMARY,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.iarsAllocationsChangesOverTimeUrl]: {
      name: EGridType.IARS_ALLOCATIONS_CHANGES_OVER_TIME,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    },
    [NSUrlsConstants.iarsReservationsOverTimeUrl]: {
      name: EGridType.IARS_RESERVATIONS_OVER_TIME,
      method: ERestMethod.POST,
      parseStrategy: ECRParseStrategy.DEFAULT
    }
  };

  static getGridOptions(type: EGridType, clientFormData: string, requestValue: string): Array<Partial<ICRTab>> {
    switch (type) {
      case EGridType.UT_SHIPPING_LISTING:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainShippingListingUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: ECRParseStrategy.DEFAULT,
          requestType: ERestMethod.POST,
          type: ECRReportType.GRID
        }];
      case EGridType.UT_FLEET_INQUIRY:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainFleetInquiryUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: ECRParseStrategy.DEFAULT,
          requestType: ERestMethod.POST,
          type: ECRReportType.GRID
        }];
      case EGridType.UT_LOAD_TRAIN:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainLoadTrainUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: ECRParseStrategy.UT_LOADED,
          requestType: ERestMethod.POST,
          type: ECRReportType.GRID
        }];
      case EGridType.UT_TRAIN_CONSIST:
        const filters: IUnitTrainFilters = JSON.parse(clientFormData) as IUnitTrainFilters;
        if (filters.carId) {
          const splitedCarId: Array<string> = filters.carId.split(' ');
          return [{
            clientFormData,
            endpoint: NSUrlsConstants.unitTrainTrainConsistByCarUrl,
            parameters: [
              { field: 'initial', type: ECRParameterType.QUERY, value: splitedCarId[0] },
              { field: 'number', type: ECRParameterType.QUERY, value: splitedCarId[1] },
              { field: 'scanner', type: ECRParameterType.QUERY, value: String(!!filters.scannerValue) }
            ],
            parseStrategy: ECRParseStrategy.UT_CONSIST,
            requestType: ERestMethod.GET,
            type: ECRReportType.GRID
          }];
        } else {
          return [{
            clientFormData,
            endpoint: NSUrlsConstants.unitTrainTrainConsistByOriginDateUrl,
            parameters: [
              { field: 'originDate', type: ECRParameterType.QUERY, value: String(filters.trainOriginDate) },
              { field: 'trainSymbol', type: ECRParameterType.QUERY, value: filters.trainSymbol || '' }
            ],
            parseStrategy: ECRParseStrategy.UT_CONSIST,
            requestType: ERestMethod.GET,
            type: ECRReportType.GRID
          }];
        }
      case EGridType.UT_RELEASE_EMPTIES:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainReleaseEmptiesGetCarsUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: ECRParseStrategy.UT_RELEASE_EMPTIES,
          requestType: ERestMethod.POST,
          type: ECRReportType.GRID
        }];
      case EGridType.UT_SHIPMENT_STATUS:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainShipmentStatusListUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: ECRParseStrategy.DEFAULT,
          requestType: ERestMethod.POST,
          type: ECRReportType.GRID
        }];
      case EGridType.UT_TRAIN_SET_INQUIRY:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainTrainSetInquiryUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: ECRParseStrategy.DEFAULT,
          requestType: ERestMethod.POST,
          type: ECRReportType.GRID
        }];
      case EGridType.UT_HOPPER_RUN:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainHopperRunUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: ECRParseStrategy.DEFAULT,
          requestType: ERestMethod.POST,
          type: ECRReportType.GRID
        }];
      case EGridType.UT_TRAIN_SIZE_PERFORMANCE:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainTrainSizePerformanceUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: ECRParseStrategy.DEFAULT,
          requestType: ERestMethod.POST,
          type: ECRReportType.GRID
        }];
      case EGridType.UT_COAL_ORDER_FULFILLMENT:
        const coalOrderFilters: IUnitTrainFilters = JSON.parse(clientFormData) as IUnitTrainFilters;
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainCoalOrderMetricsUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: coalOrderFilters.displayType === 'GRID' ? ECRParseStrategy.UT_COAL_ORDER_FULFILLMENT : undefined,
          requestType: ERestMethod.POST,
          type: coalOrderFilters.displayType === 'GRID' ? ECRReportType.GRID : ECRReportType.CHART
        }];
      case EGridType.UT_CYCLE_REPORT:
        const cycleReportFilters: IUnitTrainFilters = JSON.parse(clientFormData) as IUnitTrainFilters;
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainCycleReportMetricsUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: cycleReportFilters.displayType === 'GRID' ? ECRParseStrategy.DEFAULT : undefined,
          requestType: ERestMethod.POST,
          type: cycleReportFilters.displayType === 'GRID' ? ECRReportType.GRID : ECRReportType.CHART
        }];
      case EGridType.UT_REVIEW_DETENTION:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainDetentionHistoryUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: ECRParseStrategy.DEFAULT,
          requestType: ERestMethod.POST,
          type: ECRReportType.GRID
        }];
      case EGridType.UT_REVIEW_DISPUTES:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainDetentionDisputesUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          parseStrategy: ECRParseStrategy.DEFAULT,
          requestType: ERestMethod.POST,
          type: ECRReportType.GRID
        }];
      case EGridType.UT_MAPPING:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainMapTraceUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          requestType: ERestMethod.POST,
          type: ECRReportType.MAP
        }];
      case EGridType.UT_MONTHLY_CALENDAR:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainMonthlyEventsUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          requestType: ERestMethod.POST,
          type: ECRReportType.CALENDAR
        }];
      case EGridType.UT_WEEKLY_CALENDAR:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainWeeklyEventsUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          requestType: ERestMethod.POST,
          type: ECRReportType.CALENDAR
        }];
      case EGridType.UT_VESSEL_CALENDAR:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrainVesselEventsUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          requestType: ERestMethod.POST,
          type: ECRReportType.CALENDAR
        }];
      case EGridType.UT_48_HOUR_REPORT:
        return [{
          clientFormData,
          endpoint: NSUrlsConstants.unitTrain48hReportUrl,
          parameters: [{
            field: ECRParameterType.BODY,
            type: ECRParameterType.BODY,
            value: requestValue
          }],
          requestType: ERestMethod.POST,
          type: ECRReportType.CALENDAR
        }];
      default:
        return [];
    }
  }

  static getGridData(name: string, grid?: AgGridAngular): INSGridReportData {
    return {
      name,
      grid,
      gridPreferences: {
        gridGroupBy: grid?.columnApi.getRowGroupColumns().map((column) => column.getColId()) || [],
        columnState: grid?.columnApi.getColumnState() || [],
        gridFilter: grid?.api.getFilterModel() || []
      }
    };
  }
}
