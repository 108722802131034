import { createAction, props } from '@ngrx/store';
import { EPersona } from '@norfolk-southern/accessns-components';

import {
  ICaseCreation,
  ICaseCreationFormState,
  ICaseCreationResult,
  IInteractionCatalogItem
} from '@ruby/modules/case-management/models/case-create.interface';
import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { ICaseContact } from '@ruby/shared/models/case-management/case.interface';
import { IGridCriteria, IGridResult } from '@ruby/shared/models/commons/grid.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadCustomers = createAction(
  '[Case Create] Load Customers',
  props<{ customerPersona: EPersona }>()
);

export const loadCustomersSuccess = createAction(
  '[Case Create] Load Customers Success',
  props<{ data: Array<ICustomer>; primaryCustomer: ICustomer | undefined }>()
);

export const loadCustomersFailure = createAction(
  '[Case Create] Load Customers Failure',
  props<{ error: IError }>()
);

export const loadInteractionCategories = createAction(
  '[Case Create] Load Interaction Categories',
  props<{ data: EPersona }>()
);

export const loadInteractionCategoriesSuccess = createAction(
  '[Case Create] Load Interaction Categories Success',
  props<{ data: Array<IInteractionCatalogItem> }>()
);

export const loadInteractionCategoriesFailure = createAction(
  '[Case Create] Load Interaction Categories Failure',
  props<{ error: IError }>()
);

export const loadInteractionReasons = createAction(
  '[Case Create] Load Interaction Reasons',
  props<{ data: EPersona }>()
);

export const loadInteractionReasonsSuccess = createAction(
  '[Case Create] Load Interaction Reasons Success',
  props<{ data: Array<IInteractionCatalogItem> }>()
);

export const loadInteractionReasonsFailure = createAction(
  '[Case Create] Load Interaction Reasons Failure',
  props<{ error: IError }>()
);

export const loadInteractionTypes = createAction(
  '[Case Create] Load Interaction Types',
  props<{ data: EPersona }>()
);

export const loadInteractionTypesSuccess = createAction(
  '[Case Create] Load Interaction Types Success',
  props<{ data: Array<IInteractionCatalogItem> }>()
);

export const loadInteractionTypesFailure = createAction(
  '[Case Create] Load Interaction Types Failure',
  props<{ error: IError }>()
);

export const loadCarTypes = createAction(
  '[Case Create] Load Car Types',
  props<{ data: EPersona }>()
);

export const loadCarTypesSuccess = createAction(
  '[Case Create] Load Car Types Success',
  props<{ data: Array<IInteractionCatalogItem> }>()
);

export const loadCarTypesFailure = createAction(
  '[Case Create] Load Car Types Failure',
  props<{ error: IError }>()
);

export const emailValidation = createAction(
  '[Case Create] Email Validation',
  props<{ data: string }>()
);

export const emailValidationSuccess = createAction(
  '[Case Create] Email Validation success',
  props<{ data: ICaseContact }>()
);

export const emailValidationFailure = createAction(
  '[Case Create] Email Validation failure',
  props<{ error: IError }>()
);

export const removeContact = createAction(
  '[Case Create] Remove Contact',
  props<{ data: string }>()
);

export const submitCaseCreation = createAction(
  '[Case Create] Submit Case Creation',
  props<{ data: { request: ICaseCreation; attachmentsNumber: number } }>()
);

export const submitCaseCreationSuccess = createAction(
  '[Case Create] Submit Case Creation Success',
  props<{ data: ICaseCreationResult }>()
);

export const submitCaseCreationFailure = createAction(
  '[Case Create] Submit Case Creation Failure',
  props<{ error: IError }>()
);

export const resetState = createAction(
  '[Case Create] Reset State',
  props<{ preserveData: boolean }>()
);

export const updateForm = createAction(
  '[Case Create] Update Form',
  props<{ data: ICaseCreationFormState }>()
);

export const getGridDataOCM = createAction(
  '[Case Create] Get Grid Data OCM',
  props<{ data: IGridCriteria }>()
);

export const getGridDataOCMSuccess = createAction(
  '[Case Create] Get Grid Data OCM Success',
  props<{ data: IGridResult}>()
);

export const getGridDataOCMFailure = createAction(
  '[Case Create] Get Grid Data OCM Failure',
  props<{ data: { error: IError; invalidEquipments: Array<string> } }>()
);
