import { createAction, props } from '@ngrx/store';

import { IUnitWaybill } from '@ruby/modules/unit-details/models/unit-details.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadHistoricalWaybill = createAction(
  '[Unit Waybill] Load Historical Waybill',
  props<{ data: { initial: string; number: string; waybillSerialNumber?: string } }>()
);

export const loadHistoricalWaybillSuccess = createAction(
  '[Unit Waybill] Load Historical Waybill Success',
  props<{ data: Array<IUnitWaybill> }>()
);

export const loadHistoricalWaybillFailure = createAction(
  '[Unit Waybill] Load Historical Waybill Failure',
  props<{ error: IError }>()
);

export const loadUnitWaybillPDF = createAction(
  '[Unit Waybill] Load Unit Waybill PDF',
  props<{ data: { initial: string; number: number; waybill: number } }>()
);

export const loadUnitWaybillPDFSuccess = createAction(
  '[Unit Waybill] Load Unit Waybill PDF Success'
);

export const loadUnitWaybillPDFFailure = createAction(
  '[Unit Waybill] Load Unit Waybill PDF Failure',
  props<{ error: IError }>()
);

export const loadActiveWaybill = createAction(
  '[Unit Waybill] Load Active Waybill',
  props<{ data: { initial: string; number: string } }>()
);

export const loadActiveWaybillSuccess = createAction(
  '[Unit Waybill] Load Active Waybill Success',
  props<{ data: IUnitWaybill }>()
);

export const loadActiveWaybillFailure = createAction(
  '[Unit Waybill] Load Active Waybill Failure',
  props<{ error: IError }>()
);

export const updateWaybill = createAction(
  '[Unit Waybill] Update Waybill',
  props<{ data: { waybillSerialNumber: string; equipmentId: string } }>()
);

export const updateWaybillSuccess = createAction(
  '[Unit Waybill] Update Waybill Success',
  props<{ data: IUnitWaybill }>()
);

export const updateWaybillFailure = createAction(
  '[Unit Waybill] Update Waybill Failure',
  props<{ error: IError }>()
);

export const resetUnitWaybill = createAction(
  '[Unit Waybill] Reset Unit Waybill'
);
