import { createAction, props } from '@ngrx/store';

import { IIPAuthorizations, IIPCustomer } from '@ruby/modules/customer-select/models/ip-authorization.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadIpCustomerAuthorizations = createAction(
  '[Customer] Load IP Customer Authorizations',
  props<{ data: IIPCustomer }>()
);

export const loadIpCustomerAuthorizationsSuccess = createAction(
  '[Customer] Load IP Customer Authorizations Success',
  props<{ data: IIPAuthorizations }>()
);

export const loadIpCustomerAuthorizationsFailure = createAction(
  '[Customer] Load IP Customer Authorizations Failure',
  props<{ error: IError }>()
);
