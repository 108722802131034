import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NSUrlsConstants } from '@ruby/configs/urls.constants';
import { IAuthorizations } from '@ruby/shared/models/commons/authentication.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationsService {

  constructor(private http: HttpClient) { }

  /**
   * Get Authorizations
   *
   * @summary Get user authorizations
   * @returns Observable<IAuthorizations | never>
   */
  getAuthorizations(): Observable<IAuthorizations | never> {
    return this.http.get(NSUrlsConstants.authorizationsUrl).pipe(map(response => response as IAuthorizations));
  }
}
