import { createAction, props } from '@ngrx/store';

import { ICRFavoriteReport } from '@ruby/modules/customized-reporting/models/report.interface';
import { ECRActiveReports } from '@ruby/shared/enums/customized-reporting.enums';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadFavorites = createAction(
  '[CR Favorites Report] Load Favorites',
  props<{ data: { gridName: string; type: ECRActiveReports } }>()
);

export const loadFavoritesSuccess = createAction(
  '[CR Favorites Report] Load Favorites Success',
  props<{ data: { reports: Array<ICRFavoriteReport>; gridName: string; type: ECRActiveReports } }>()
);

export const loadFavoritesFailure = createAction(
  '[CR Favorites Report] Load Favorites Failure',
  props<{ data: { error: IError; gridName: string } }>()
);

export const clear = createAction(
  '[CR Favorites Report] Clear Favorites',
  props<{ data: { gridName: string } }>()
);

export const selectFavorite = createAction(
  '[CR Favorites Report] Select Favorites',
  props<{ data: { gridName: string; favorite: ICRFavoriteReport } }>()
);

export const clearSelected = createAction(
  '[CR Favorites Report] Clear Selected Favorites',
  props<{ data: { gridName: string } }>()
);
