export enum EColors {
  GOLD = '#F4BA05',
  DARK_GREY = '#2C2D28',
  BLACK = '#1D1D1D',
  MEDIUM_GRAY = '#555650',
  LIGHT_GOLD = '#FAE39B',
  DARK_GOLD = '#C39404',
  TEAL = '#027B74',
  LIGHT_GREEN = '#98dc5b',
  MEDIUM = '#969696',
  LIGHT = '#C8C8C9',
  SUPER_LIGHT = '#EFEFEF',
  BEIGE = '#F7F7F3',
  DARK_BEIGE = '#E0E0E0',
  WHITE = '#FFFFFF',
  TRAVERTINE = '#E2DDC7',
  LAVENDER = '#BDCEFA',
  ASTRONAUT_BLUE = '#034764',
  DANGER_DARK = '#7a0000',
  DANGER = '#e30202',
  UPDATE = '#0F4749',
  WARNING = '#E07600',
  WARNING_LIGHT = '#fb6901',
  SUCCESS = '#4F772C',
  CHART_LIGHT_BLUE = '#8DDDD0',
  CHART_BLUE = '#29B7DE',
  CHART_LIGHT_PURPLE = '#D5A3E9',
  CHART_PURPLE = '#D95CFC',
  CHART_RED = '#F26C54',
  CHART_LIGHT_RED = '#FE9090',
  CHART_YELLOW = '#FFC656',
  CHART_GREEN = '#9DD866',
  CHART_LIGHT_GREEN = '#C9E7AC',
  CHART_LIGHTER_BLUE = '#8FD7E8',
  TRANSPARENT = 'transparent'
}
