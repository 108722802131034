import { animate, style, transition, trigger } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { SwalComponent, SwalPortalTargets } from '@sweetalert2/ngx-sweetalert2';
import { combineLatest, timer } from 'rxjs';

import { NSCommonConstants } from '@ruby/configs/common.constants';
import { SWCheckForUpdateService } from '@ruby/core/services/sw-check-for-update.service';
import * as VersionStatusActions from '@ruby/modules/admin/store/actions/version-status.actions';
import * as SecurityActions from '@ruby/modules/security/store/actions/security.actions';
import { DataDogService } from '@ruby/shared/services/analytics/datadog.service';
import { SvgIconsService } from '@ruby/shared/services/svg-icons.service';
import { AppState } from '@ruby/store';

@Component({
  selector: 'ng-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeTrigger', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('swalie') warningAlert!: SwalComponent;
  @ViewChild('swalmobile') warningAlertMobile!: SwalComponent;
  browsers = NSCommonConstants.browsers;
  mobileApps = NSCommonConstants.mobileApps;
  showMask = true;

  constructor(
    private store: Store<AppState>,
    readonly swalTargets: SwalPortalTargets,
    private swCheck: SWCheckForUpdateService,
    private svgIconsService: SvgIconsService,
    private http: HttpClient,
    private datadogService: DataDogService
  ) {
    swCheck.start();
    this.setupVersionAndEnv();
  }

  get isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent.toLocaleLowerCase());
  }

  get isIOS(): boolean {
    return /iPhone|iPad|iPod/i.test(window.navigator.userAgent.toLocaleLowerCase());
  }

  ngOnInit(): void {
    this.store.dispatch(SecurityActions.checkExistingSession());
  }

  ngAfterViewInit(): void {
    timer(1500).subscribe(() => {
      this.checkBrowser();
      this.checkMobileBrowser();
    });
  }

  /* istanbul ignore next */
  private checkBrowser(): void {
    this.showMask = false;

    if (/msie\s|trident\//i.test(window.navigator.userAgent.toLocaleLowerCase())) {
      this.warningAlert.fire();
    }
  }

  /* istanbul ignore next */
  private checkMobileBrowser(): void {
    this.showMask = false;

    if (this.isMobileDevice) {
      this.warningAlertMobile.fire();
    }
  }

  /* istanbul ignore next */
  private setupVersionAndEnv(): void {
    combineLatest([
      this.http.get('/assets/context/version', { responseType: 'text' }),
      this.http.get('/assets/context/environment', { responseType: 'text' })
    ]).subscribe(([version, env]: Array<string>) => {
      this.datadogService.initialize(env, version);

      this.store.dispatch(VersionStatusActions.getVersionSuccess({ data: version }));
      this.store.dispatch(VersionStatusActions.setEnvironment({ data: env }));
    });
  }
}
