import { createAction, props } from '@ngrx/store';

import { IAccessToken } from '@ruby/shared/models/commons/authentication.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const getAccessToken = createAction(
  '[Auth] Get Access Token',
  props<{ code: string }>()
);

export const getAccessTokenSuccess = createAction(
  '[Auth] Get Access Token Success',
  props<{ data: IAccessToken }>()
);

export const getAccessTokenFailure = createAction(
  '[Auth] Get Access Token Failure',
  props<{ error?: IError }>()
);

export const logout = createAction(
  '[Auth] Logout',
  props<{ removeToken: boolean }>()
);

export const checkExistingSession = createAction(
  '[Auth] Check Existing Session'
);

export const checkExistingSessionSuccess = createAction(
  '[Auth] Check Existing Session Success',
  props<{ auth: IAccessToken }>()
);

export const checkExistingSessionFailure = createAction(
  '[Auth] Check Existing Session Failure'
);

export const refreshToken = createAction(
  '[Auth] Refresh Token',
  props<{ refreshToken: string }>()
);

export const refreshTokenSuccess = createAction(
  '[Auth] Refresh Token Success',
  props<{ auth: IAccessToken }>()
);

export const refreshTokenFailure = createAction(
  '[Auth] Refresh Token Failure',
  props<{ error: IError }>()
);

export const setupCompleted = createAction(
  '[Auth] Setup Completed'
);

export const navigateHome = createAction(
  '[Auth] Navigate Home',
  props<{ isUserInternal: boolean }>()
);
