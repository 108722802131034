import { Action, createReducer, on } from '@ngrx/store';

import * as AuthorizationsActions from '@ruby/modules/security/store/actions/authorizations.actions';
import { EAuthorization } from '@ruby/shared/enums/authorizations.enums';
import { ERole } from '@ruby/shared/enums/roles.enums';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'authorizations';

export interface State {
  userRacf?: string;
  roles: Array<ERole>;
  authorizations: Array<EAuthorization>;
  loading: boolean;
  error?: IError;
}

export const initialState: State = {
  userRacf: undefined,
  roles: [],
  authorizations: [],
  loading: false,
  error: undefined
};

const authorizationsReducer = createReducer(
  initialState,
  on(AuthorizationsActions.getAuthorizations, state => ({
    ...state,
    userRacf: undefined,
    roles: [],
    authorizations: [],
    loading: true,
    error: undefined
  })),
  on(AuthorizationsActions.getAuthorizationsSuccess, (state, { data }) => ({
    ...state,
    userRacf: data.userRacf,
    roles: data.roles,
    authorizations: data.authorizations,
    loading: false
  })),
  on(AuthorizationsActions.getAuthorizationsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    error
  }))
);

export const reducer = (state: State | undefined, action: Action) => authorizationsReducer(state, action);
