import { createAction, props } from '@ngrx/store';

import { IContact } from '@ruby/modules/customer-select/models/contact.interface';
import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const searchUnitTrainCustomersUser = createAction(
  '[Customer] Search UnitTrain Customers User',
  props<{ data: { userRacf: string } }>()
);

export const searchUnitTrainCustomersUserSuccess = createAction(
  '[Customer] Search UnitTrain Customers User Success',
  props<{ data: Array<ICustomer> }>()
);

export const searchUnitTrainCustomersUserFailure = createAction(
  '[Customer] Search UnitTrain Customers User Failure',
  props<{ error: IError }>()
);

export const searchContacts = createAction(
  '[Customer] Search Contacts',
  props<{ data: { firstName?: string; lastName?: string } }>()
);

export const searchContactsSuccess = createAction(
  '[Customer] Search Contacts Success',
  props<{ data: Array<IContact> }>()
);

export const searchContactsFailure = createAction(
  '[Customer] Search Contacts Failure',
  props<{ error: IError }>()
);

export const searchUnitTrainUserCustomerLocations = createAction(
  '[Customer] Search UnitTrain User Customer Locations',
  props<{ data: { userRacf: string; customerIdentifier: string } }>()
);

export const searchUnitTrainUserCustomerLocationsSuccess = createAction(
  '[Customer] Search UnitTrain User Customer Locations Success',
  props<{ data: ICustomer }>()
);

export const searchUnitTrainUserCustomerLocationsFailure = createAction(
  '[Customer] Search UnitTrain User Customer Locations Failure',
  props<{ error: IError }>()
);

export const clearSelection = createAction(
  '[Customer] Clear Unit Train Selection'
);
