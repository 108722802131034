import { createAction, props } from '@ngrx/store';
import { EPersona } from '@norfolk-southern/accessns-components';

import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadRoleCustomers = createAction(
  '[Customer] Load Role Customers',
  props<{ data: EPersona }>()
);

export const loadRoleCustomersSuccess = createAction(
  '[Customer] Load Role Customers Success',
  props<{ data: Array<ICustomer> }>()
);

export const loadRoleCustomersFailure = createAction(
  '[Customer] Load Role Customers Failure',
  props<{ error: IError }>()
);

export const clearRoleCustomers = createAction(
  '[Customer] Clear Role Customers'
);
