import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { TokenService } from '@ruby/core/authentication/token.service';
import * as SecurityActions from '@ruby/modules/security/store/actions/security.actions';

@Injectable({
  providedIn: 'root'
})
export class AuthRefreshTokenService {
  private refreshTokenTimeout!: number;

  constructor(private tokenService: TokenService, private store: Store) { }

  /**
   * Start Refresh Token Timer
   *
   * @summary Start refresh token timer using token service to get expiration and token
   * @returns void
   */
  startRefreshTokenTimer(): void {
    const refreshToken = this.tokenService.getRefreshToken();
    const creation = this.tokenService.getTokenCreation() || 0;
    const exp = this.tokenService.getTokenExpiration();

    const currentTime = Math.floor(new Date().getTime() / 1000);
    const timeSinceCreation = currentTime - creation;

    const expMil = (exp - timeSinceCreation) * 1000;
    const timeout = expMil - (180 * 1000);
    this.refreshTokenTimeout = window.setTimeout(() => this.store.dispatch(SecurityActions.refreshToken({ refreshToken })), timeout);
  }

  /**
   * Stop Refresh Token Timer
   *
   * @summary Stop refresh token timer
   * @returns void
   */
  stopRefreshTokenTimer(): void {
    clearTimeout(this.refreshTokenTimeout);
  }
}
