import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromCaseCreate from '@ruby/modules/case-management/store/reducers/case-create.reducer';
import * as fromCaseDashboard from '@ruby/modules/case-management/store/reducers/case-dashboard.reducer';
import * as fromCaseSummary from '@ruby/modules/case-management/store/reducers/case-summary.reducer';

export const featureKey = 'caseManagement';

export interface State {
  [fromCaseDashboard.featureKey]: fromCaseDashboard.State;
  [fromCaseSummary.featureKey]: fromCaseSummary.State;
  [fromCaseCreate.featureKey]: fromCaseCreate.State;
}

export const reducer: ActionReducerMap<State> = {
  [fromCaseDashboard.featureKey]: fromCaseDashboard.reducer,
  [fromCaseSummary.featureKey]: fromCaseSummary.reducer,
  [fromCaseCreate.featureKey]: fromCaseCreate.reducer
};

export const selectCMState = createFeatureSelector<State>(featureKey);
