import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, first, mergeMap } from 'rxjs/operators';

import * as SecurityActions from '@ruby/modules/security/store/actions/security.actions';
import * as fromSecurity from '@ruby/modules/security/store/reducers/security.reducer';
import * as SecuritySelectors from '@ruby/modules/security/store/selectors/security.selectors';
import { IAccessToken } from '@ruby/shared/models/commons/authentication.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

@Injectable({
  providedIn: 'root'
})
export class NsTokenInterceptor implements HttpInterceptor {

  constructor(private store: Store<fromSecurity.State>) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(SecuritySelectors.selectAccessToken).pipe(
      first(),
      mergeMap((token?: IAccessToken) => {
        const isNotLoginUrl = !request.url.match(/\/api\/security\/access-token/);
        const requestWithToken = isNotLoginUrl && token?.accessToken ? this.addToken(request, token.accessToken) : request;
        return next.handle(requestWithToken).pipe(
          catchError((error: IError) => {
            if (token && error && error.code === '401') {
              this.store.dispatch(SecurityActions.logout({ removeToken: false }));
            }
            return throwError(error);
          })
        );
      })
    );
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    if (/_IMP_\w+$/.test(token)) {
      const tokenItems: Array<string> = token.split('_IMP_');
      return req.clone({
        setHeaders: {
          Authorization: `Bearer ${ tokenItems[0] }`,
          'X-ANS-Impersonate': tokenItems[1]
        }
      });
    }
    return req.clone({ setHeaders: { Authorization: `Bearer ${ token }` } });
  }
}
