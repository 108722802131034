import { createAction, props } from '@ngrx/store';

import { ICustomer, ILocation } from '@ruby/modules/customer-select/models/customer.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadShortLineCustomers = createAction(
  '[Customer] Load ShortLine Customers'
);

export const loadShortLineCustomersSuccess = createAction(
  '[Customer] Load ShortLine Customers Success',
  props<{ data: Array<ICustomer> }>()
);

export const loadShortLineCustomersFailure = createAction(
  '[Customer] Load Short Line Customers Failure',
  props<{ error: IError }>()
);

export const loadShortLineLocations = createAction(
  '[Customer] Load ShortLine Locations',
  props<{ data: { scac: string } }>()
);

export const loadShortLineLocationsSuccess = createAction(
  '[Customer] Load ShortLine Locations Success',
  props<{ data: Array<ILocation> }>()
);

export const loadShortLineLocationsFailure = createAction(
  '[Customer] Load ShortLine Locations Failure',
  props<{ error: IError }>()
);
