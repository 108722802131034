import { createAction, props } from '@ngrx/store';

import { IAuthorizations } from '@ruby/shared/models/commons/authentication.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const getAuthorizations = createAction(
  '[Authorizations] Get Authorizations'
);

export const getAuthorizationsSuccess = createAction(
  '[Authorizations] Get Authorizations Success',
  props<{ data: IAuthorizations }>()
);

export const getAuthorizationsFailure = createAction(
  '[Authorizations] Get Authorizations Failure',
  props<{ error: IError }>()
);
