import { createAction, props } from '@ngrx/store';

import { IContact } from '@ruby/modules/account/models/contact.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const getUserInformation = createAction(
  '[User Information] Get User information'
);

export const getUserInformationSuccess = createAction(
  '[User Information] Get User information success',
  props<{ data: IContact }>()
);

export const getUserInformationFailure = createAction(
  '[User Information] Get User information failure',
  props<{ error: IError }>()
);
