import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromCustomer from '@ruby/modules/customer-select/store/reducers/customers.reducer';
import * as fromIMCustomer from '@ruby/modules/customer-select/store/reducers/im-customers.reducer';
import * as fromIPCustomerAuth from '@ruby/modules/customer-select/store/reducers/ip-customer-auth.reducer';
import * as fromIPCustomer from '@ruby/modules/customer-select/store/reducers/ip-customers.reducer';
import * as fromOfflineCustomer from '@ruby/modules/customer-select/store/reducers/offline-customers.reducer';
import * as fromRoleCustomer from '@ruby/modules/customer-select/store/reducers/role-customers.reducer';
import * as fromSLCustomer from '@ruby/modules/customer-select/store/reducers/sl-customers.reducer';
import * as fromUTCustomer from '@ruby/modules/customer-select/store/reducers/ut-customers.reducer';

export const customerSelectFeatureKey = 'customerSelect';

export interface State {
  [fromCustomer.featureKey]: fromCustomer.State;
  [fromIPCustomer.featureKey]: fromIPCustomer.State;
  [fromIMCustomer.featureKey]: fromIMCustomer.State;
  [fromSLCustomer.featureKey]: fromSLCustomer.State;
  [fromUTCustomer.featureKey]: fromUTCustomer.State;
  [fromOfflineCustomer.featureKey]: fromOfflineCustomer.State;
  [fromRoleCustomer.featureKey]: fromRoleCustomer.State;
  [fromIPCustomerAuth.featureKey]: fromIPCustomerAuth.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromCustomer.featureKey]: fromCustomer.reducer,
  [fromIPCustomer.featureKey]: fromIPCustomer.reducer,
  [fromIMCustomer.featureKey]: fromIMCustomer.reducer,
  [fromSLCustomer.featureKey]: fromSLCustomer.reducer,
  [fromUTCustomer.featureKey]: fromUTCustomer.reducer,
  [fromOfflineCustomer.featureKey]: fromOfflineCustomer.reducer,
  [fromRoleCustomer.featureKey]: fromRoleCustomer.reducer,
  [fromIPCustomerAuth.featureKey]: fromIPCustomerAuth.reducer
};

export const selectFeatureState = createFeatureSelector<State>(customerSelectFeatureKey);

export {
  fromCustomer,
  fromIPCustomer,
  fromIMCustomer,
  fromSLCustomer,
  fromUTCustomer,
  fromOfflineCustomer,
  fromRoleCustomer,
  fromIPCustomerAuth
};
