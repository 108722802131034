import { createAction, props } from '@ngrx/store';

import { ICRGridRequest } from '@ruby/modules/customized-reporting/models/report.interface';

export const saveGridRequest = createAction(
  '[Customized Reporting] Save Grid Request',
  props<{ data: { name: string; request: ICRGridRequest } }>()
);

export const clearGridRequest = createAction(
  '[Customized Reporting] Clear Grid Request'
);
