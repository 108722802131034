import { createAction, props } from '@ngrx/store';

import { IError } from '@ruby/shared/models/request/error.interface';
import { IUserPreference } from '@ruby/shared/models/user-preferences/user-preference.interface';

export const loadUserPreference = createAction(
  '[User Preference] Load User Preference',
  props<{ data: { screenName: string; preferenceName: string } }>()
);

export const loadUserPreferenceSuccess = createAction(
  '[User Preference] Load User Preference Success',
  props<{ data: IUserPreference }>()
);

export const loadUserPreferenceFailure = createAction(
  '[User Preference] Load User Preference Failure',
  props<{ data: { screenName: string; preferenceName: string }; error: IError }>()
);

export const saveUserPreference = createAction(
  '[User Preference] Save User Preference',
  props<{ data: { screenName: string; preferenceName: string; data: any } }>()
);

export const saveUserPreferenceSuccess = createAction(
  '[User Preference] Save User Preference Success',
  props<{ data: IUserPreference }>()
);

export const saveUserPreferenceFailure = createAction(
  '[User Preference] Save User Preference Failure',
  props<{ data: IUserPreference; error: IError }>()
);

export const removeUserPreference = createAction(
  '[User Preference] Remove User Preference',
  props<{ data: { screenName: string; preferenceName: string } }>()
);

export const removeUserPreferenceSuccess = createAction(
  '[User Preference] Remove User Preference Success',
  props<{ data: IUserPreference }>()
);

export const removeUserPreferenceFailure = createAction(
  '[User Preference] Save User Preference Failure',
  props<{ data: IUserPreference; error: IError }>()
);

export const loadAllUserPreferences = createAction(
  '[User Preference] Load All User Preferences'
);

export const loadAllUserPreferencesSuccess = createAction(
  '[User Preference] Load All User Preferences Success',
  props<{ data: Array<IUserPreference> }>()
);

export const loadAllUserPreferencesFailure = createAction(
  '[User Preference] Load All User Preferences Failure',
  props<{ error: IError }>()
);

export const removeAllUserPreferences = createAction(
  '[User Preference] Remove All User Preferences'
);

export const removeAllUserPreferencesSuccess = createAction(
  '[User Preference] Remove All User Preferences Success'
);

export const removeAllUserPreferencesFailure = createAction(
  '[User Preference] Remove All User Preferences Failure',
  props<{ error: IError }>()
);
