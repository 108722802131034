import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';

import { AppConfig } from '@ruby/shared/models/app-config/app-config.interface';

@Injectable()
export class AppConfigService {
  loaded = false;
  private config: AppConfig = {} as AppConfig;

  constructor(private http: HttpClient) {}

  /**
   * Load Config
   *
   * @summary load configuration
   * @returns Promise<void>
   */
  async loadConfig(): Promise<void> {
    try {
      const response: AppConfig = await firstValueFrom(this.http.get<AppConfig>('/assets/context/app.config.json'));
      this.config = response;
      this.loaded = true;
    } catch (err) {
      this.loaded = true;
      this.config = { launchDarklyClientId: '' };
    }
  }

  /**
   * Get Config
   *
   * @summary get configuration
   * @returns AppConfig
   */
  getConfig(): AppConfig {
    return this.config;
  }
}
