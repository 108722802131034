import { Action, createReducer, on } from '@ngrx/store';

import { IContact } from '@ruby/modules/customer-select/models/contact.interface';
import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import * as UTCustomersActions from '@ruby/modules/customer-select/store/actions/ut-customers.actions';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'utCustomers';

export interface State {
  contacts: Array<IContact>;
  customers: Array<ICustomer>;
  customerContact?: ICustomer;
  loading: boolean;
  isLoadingLocations: boolean;
  error?: IError;
}

export const initialState: State = {
  contacts: [],
  customers: [],
  customerContact: undefined,
  loading: false,
  isLoadingLocations: false,
  error: undefined
};

const unitTrainCustomersReducer = createReducer(
  initialState,
  on(UTCustomersActions.searchContacts, state => ({
    ...state,
    loading: true,
    contacts: [],
    customers: [],
    customerContact: undefined,
    error: undefined
  })),
  on(UTCustomersActions.searchContactsSuccess, (state, action) => ({
    ...state,
    loading: false,
    contacts: action.data,
    customers: [],
    customerContact: undefined,
    error: undefined
  })),
  on(UTCustomersActions.searchContactsFailure, (state, action) => ({
    ...state,
    loading: false,
    contacts: [],
    customers: [],
    customerContact: undefined,
    error: action.error
  })),
  on(UTCustomersActions.searchUnitTrainCustomersUser, state => ({
    ...state,
    loading: true,
    contacts: [],
    customers: [],
    customerContact: undefined,
    error: undefined
  })),
  on(UTCustomersActions.searchUnitTrainCustomersUserSuccess, (state, action) => ({
    ...state,
    loading: false,
    contacts: [],
    customers: action.data,
    customerContact: undefined,
    error: undefined
  })),
  on(UTCustomersActions.searchUnitTrainCustomersUserFailure, (state, action) => ({
    ...state,
    loading: false,
    contacts: [],
    customers: [],
    customerContact: undefined,
    error: action.error
  })),
  on(UTCustomersActions.searchUnitTrainUserCustomerLocations, state => ({
    ...state,
    isLoadingLocations: true,
    customers: [],
    customerContact: undefined,
    error: undefined
  })),
  on(UTCustomersActions.searchUnitTrainUserCustomerLocationsSuccess, (state, action) => ({
    ...state,
    isLoadingLocations: false,
    customers: [],
    customerContact: action.data,
    error: undefined
  })),
  on(UTCustomersActions.searchUnitTrainUserCustomerLocationsFailure, (state, action) => ({
    ...state,
    isLoadingLocations: false,
    customers: [],
    customerContact: undefined,
    error: action.error
  })),
  on(UTCustomersActions.clearSelection, (state, action) => ({
    ...initialState
  }))
);

export const reducer = (state: State | undefined, action: Action) => unitTrainCustomersReducer(state, action);
