import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NSUrlsConstants } from '@ruby/configs/urls.constants';
import { IUserPreference } from '@ruby/shared/models/user-preferences/user-preference.interface';

@Injectable({
  providedIn: 'root'
})
export class PreferencesService {

  constructor(private http: HttpClient) { }

  /**
   * Get User Preferences
   *
   * @summary Fetch the user preferences
   * @returns Observable<Array<IUserPreference> | never>
   */
  getUserPreferences(): Observable<Array<IUserPreference> | never> {
    return this.http.get(NSUrlsConstants.userPreferencesUrl).pipe(map(response => response as Array<IUserPreference>));
  }

  /**
   * Get User Preferences by screenName and preferenceName
   *
   * @param screenName: string
   * @param preferenceName: string
   * @returns Observable<IUserPreference | never>
   */
  getUserPreferenceByScreenAndName(screenName: string, preferenceName: string): Observable<IUserPreference | never> {
    return this.http.get(`${ NSUrlsConstants.userPreferencesUrl }/screen/${ screenName }/preference/${ preferenceName }`).pipe(
      map(response => response as IUserPreference)
    );
  }

  /**
   * Save User Preference
   *
   * @summary The will create specific user preference details by racf and screen name and pref name
   * @param userPreference: IUserPreference
   * @returns Observable<IUserPreference | never>
   */
  saveUserPreference(userPreference: IUserPreference): Observable<IUserPreference | never> {
    return this.http.post(NSUrlsConstants.userPreferencesUrl, userPreference).pipe(map(response => response as IUserPreference));
  }

  /**
   * Update User Preference
   *
   * @summary The will update specific user preference details by racf and screen name and pref name
   * @param userPreference: IUserPreference
   * @returns Observable<IUserPreference | never>
   */
  updateUserPreference(userPreference: IUserPreference): Observable<IUserPreference | never> {
    return this.http.put(NSUrlsConstants.userPreferencesUrl, userPreference).pipe(
      map(response => response as IUserPreference)
    );
  }

  /**
   * Remove User Preferences by screenName and preferenceName
   *
   * @param screenName: string
   * @param preferenceName: string
   * @returns Observable<void | never>
   */
  removeUserPreferenceByScreenAndName(screenName: string, preferenceName: string): Observable<void | never> {
    return this.http.delete(`${ NSUrlsConstants.userPreferencesUrl }/screen/${ screenName }/preference/${ preferenceName }`).pipe(
      map(() => undefined)
    );
  }

  /**
   * Delete User Preferences
   *
   * @summary Delete the user preferences
   * @returns Observable<void | never>
   */
  deleteUserPreferences(): Observable<void | never> {
    return this.http.delete(NSUrlsConstants.userPreferencesUrl).pipe(map(response => undefined));
  }
}
