import { createAction, props } from '@ngrx/store';

import { ERole } from '@ruby/shared/enums/roles.enums';
import { ITransaction } from '@ruby/shared/models/commons/transaction.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const checkPermissions = createAction(
  '[Party To] Check Permissions',
  props<{ data: { equipmentId: string; waybill?: string; redirect?: boolean; viewTab?: string } }>()
);

export const checkPermissionsSuccess = createAction(
  '[Party To] Check Permissions Success',
  props<{ data: boolean; roles: Array<ERole>; equipmentId: string; waybill?: string; redirect?: boolean; viewTab?: string }>()
);

export const checkPermissionsFailure = createAction(
  '[Party To] Check Permissions Failure',
  props<{ error: IError }>()
);

export const saveCriteria = createAction(
  '[Party To] Save Criteria',
  props<{ data: ITransaction }>()
);

export const saveCriteriaSuccess = createAction(
  '[Party To] Save Criteria Success'
);

export const saveCriteriaFailure = createAction(
  '[Party To] Save Criteria Failure',
  props<{ error: IError }>()
);

export const resetPartyToCount = createAction(
  '[Party To] Reset Party To Count'
);
