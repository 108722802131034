import { createAction, props } from '@ngrx/store';

import { IError } from '@ruby/shared/models/request/error.interface';

export const getVersionSuccess = createAction(
  '[Admin] Get Version Success',
  props<{ data: string }>()
);

export const getVersionFailure = createAction(
  '[Admin] Set Version Failure',
  props<{ error: IError }>()
);

export const setVersionAvailable = createAction(
  '[Admin] Set Version Available',
  props<{ data: string }>()
);

export const setEnvironment = createAction(
  '[Admin] Set Environment',
  props<{ data: string }>()
);
