import { createReducer, on } from '@ngrx/store';

import * as CaseSummaryActions from '@ruby/modules/case-management/store/actions/case-summary.actions';
import { ICase, ICaseActivity, ICaseAttachment, ICaseContact, ICaseEquipment, IInterestedPartyStatus } from '@ruby/shared/models/case-management/case.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'caseSummary';

export interface State {
  loadingActivities: boolean;
  loadingCase: boolean;
  loadingParties: boolean;
  loadingMedia: boolean;
  loadingAddInterested: boolean;
  loadingAttachments: boolean;
  loadingEquipmentEdit: boolean;
  loadingCaseReopen: boolean;
  loadingSubjectEdit: boolean;
  selectedCase?: ICase;
  contacts: Array<ICaseContact>;
  loadingContact: boolean;
  interestedParties: Array<ICaseContact>;
  activities: Array<ICaseActivity>;
  progress: number;
  media?: ICaseAttachment;
  error?: IError;
  isValidEmail: boolean;
  loadingValidation: boolean;
  equipments: Array<ICaseEquipment>;
  loadingEquipment: boolean;
  isValidEquipment: boolean;
  acceptingRefund: boolean;
  updatingRefundCase: boolean;
}

export const initialState: State = {
  loadingActivities: false,
  loadingCase: false,
  loadingAddInterested: false,
  loadingEquipmentEdit: false,
  loadingParties: true,
  loadingMedia: false,
  loadingContact: false,
  loadingAttachments: false,
  loadingCaseReopen: false,
  loadingSubjectEdit: false,
  selectedCase: undefined,
  media: undefined,
  progress: 0,
  interestedParties: [],
  activities: [],
  contacts: [],
  isValidEmail: false,
  loadingValidation: false,
  equipments: [],
  loadingEquipment: false,
  isValidEquipment: false,
  acceptingRefund: false,
  updatingRefundCase: false
};

export const reducer = createReducer(
  initialState,
  on(CaseSummaryActions.loadCase, state => ({
    ...state,
    selectedCase: undefined,
    loadingCase: true
  })),
  on(CaseSummaryActions.loadCaseSuccess, (state, action) => ({
    ...state,
    loadingCase: false,
    selectedCase: action.data
  })),
  on(CaseSummaryActions.loadCaseFailure, (state, action) => ({
    ...state,
    loadingCase: false,
    error: action.error
  })),
  on(CaseSummaryActions.loadInterestedParties, state => ({
    ...state,
    loadingParties: true,
    interestedParties: [],
    error: undefined
  })),
  on(CaseSummaryActions.loadInterestedPartiesSuccess, (state, action) => ({
    ...state,
    interestedParties: action.data,
    loadingParties: false
  })),
  on(CaseSummaryActions.loadInterestedPartiesFailure, (state, action) => ({
    ...state,
    loadingParties: false,
    error: action.error
  })),
  on(CaseSummaryActions.uploadFile, state => ({
    ...state,
    progress: 0
  })),
  on(CaseSummaryActions.uploadFileSuccess, state => ({
    ...state,
    progress: 0
  })),
  on(CaseSummaryActions.uploadFileFailure, (state, action) => ({
    ...state,
    progress: 0,
    error: action.error
  })),
  on(CaseSummaryActions.uploadFileProgress, (state, action) => ({
    ...state,
    progress: action.data
  })),
  on(CaseSummaryActions.loadActivities, state => ({
    ...state,
    activities: [],
    loadingActivities: true
  })),
  on(CaseSummaryActions.loadActivitiesSuccess, (state, action) => ({
    ...state,
    loadingActivities: false,
    activities: [...action.data.activity].reverse()
  })),
  on(CaseSummaryActions.loadActivitiesFailure, (state, action) => ({
    ...state,
    loadingActivities: false,
    error: action.error
  })),
  on(CaseSummaryActions.createComment, state => ({
    ...state,
    loadingActivities: true
  })),
  on(CaseSummaryActions.createCommentSuccess, state => ({
    ...state,
    loadingActivities: false
  })),
  on(CaseSummaryActions.createCommentFailure, (state, action) => ({
    ...state,
    loadingActivities: false,
    error: action.error
  })),
  on(CaseSummaryActions.addInterestedParty, state => ({
    ...state,
    loadingAddInterested: true,
    error: undefined
  })),
  on(CaseSummaryActions.addInterestedPartySuccess, state => ({
    ...state,
    loadingAddInterested: false
  })),
  on(CaseSummaryActions.addInterestedPartyFailure, (state, action) => ({
    ...state,
    loadingAddInterested: false,
    error: action.error
  })),
  on(CaseSummaryActions.fetchMedia, state => ({
    ...state,
    media: undefined,
    loadingMedia: true,
    error: undefined
  })),
  on(CaseSummaryActions.fetchMediaSuccess, (state, action) => ({
    ...state,
    loadingMedia: false,
    media: action.data
  })),
  on(CaseSummaryActions.fetchMediaFailure, (state, action) => ({
    ...state,
    loadingMedia: false,
    error: action.error
  })),
  on(CaseSummaryActions.downloadFile, state => ({
    ...state,
    error: undefined,
    loadingMedia: true
  })),
  on(CaseSummaryActions.downloadFileSuccess, (state, action) => ({
    ...state,
    loadingMedia: false
  })),
  on(CaseSummaryActions.downloadFileFailure, (state, action) => ({
    ...state,
    loadingMedia: false,
    error: action.error
  })),
  on(CaseSummaryActions.clearMedia, state => ({
    ...state,
    media: undefined
  })),
  on(CaseSummaryActions.loadAttachments, state => ({
    ...state,
    loadingAttachments: true
  })),
  on(CaseSummaryActions.loadAttachmentsSuccess, (state, action) => ({
    ...state,
    loadingAttachments: false,
    selectedCase: { ...state.selectedCase!, attachments: action.data }
  })),
  on(CaseSummaryActions.loadAttachmentsFailure, (state, action) => ({
    ...state,
    loadingAttachments: false,
    error: action.error
  })),
  on(CaseSummaryActions.emailValidation, state => ({
    ...state,
    isValidEmail: false,
    loadingValidation: true,
    error: undefined
  })),
  on(CaseSummaryActions.emailValidationSuccess, (state, action) => ({
    ...state,
    isValidEmail: true,
    loadingValidation: false,
    contacts: [...state.contacts, action.data]
  })),
  on(CaseSummaryActions.emailValidationFailure, (state, action) => ({
    ...state,
    isValidEmail: false,
    loadingValidation: false,
    error: action.error
  })),
  on(CaseSummaryActions.refreshInterestedPartyState, state => ({
    ...state,
    isValidEmail: false,
    contacts: state.interestedParties,
    error: undefined
  })),
  on(CaseSummaryActions.removeInterestedParty, state => ({
    ...state,
    loadingAddInterested: true,
    error: undefined
  })),
  on(CaseSummaryActions.removeInterestedPartySuccess, (state, action) => ({
    ...state,
    contacts: state.contacts.filter((contact: ICaseContact) => !action.data.find((
      iInterestedPartyStatus: IInterestedPartyStatus) => iInterestedPartyStatus.interestedPartyId === contact.interestedPartyId
    )),
    interestedParties: state.interestedParties.filter((contact: ICaseContact) => !action.data.find((
      iInterestedPartyStatus: IInterestedPartyStatus) => iInterestedPartyStatus.interestedPartyId === contact.interestedPartyId
    )),
    loadingAddInterested: false
  })),
  on(CaseSummaryActions.removeInterestedPartyFailure, (state, action) => ({
    ...state,
    loadingAddInterested: false,
    error: action.error
  })),
  on(CaseSummaryActions.removeContact, (state, action) => ({
    ...state,
    contacts: state.contacts.filter((contact: ICaseContact) => contact.racf !== action.data)
  })),
  on(CaseSummaryActions.setSelectedCase, (state, action) => ({
    ...state,
    selectedCase: action.data
  })),
  on(CaseSummaryActions.removeAttachment, state => ({
    ...state,
    loadingAttachments: true
  })),
  on(CaseSummaryActions.removeAttachmentSuccess, state => ({
    ...state,
    loadingAttachments: false
  })),
  on(CaseSummaryActions.removeAttachmentFailure, (state, action) => ({
    ...state,
    loadingAttachments: false,
    error: action.error
  })),
  on(CaseSummaryActions.refreshEquipmentsState, state => ({
    ...state,
    isValidEquipment: false,
    equipments: state.equipments,
    error: undefined
  })),
  on(CaseSummaryActions.removeEquipment, state => ({
    ...state,
    loadingEquipmentEdit: true,
    error: undefined
  })),
  on(CaseSummaryActions.removeEquipmentSuccess, (state, action) => ({
    ...state,
    loadingEquipmentEdit: false,
    error: undefined
  })),
  on(CaseSummaryActions.removeEquipmentFailure, (state, action) => ({
    ...state,
    loadingEquipmentEdit: false,
    error: action.error
  })),
  on(CaseSummaryActions.addEquipment, state => ({
    ...state,
    loadingEquipmentEdit: true,
    error: undefined
  })),
  on(CaseSummaryActions.addEquipmentSuccess, (state, action) => ({
    ...state,
    loadingEquipmentEdit: false,
    error: undefined
  })),
  on(CaseSummaryActions.addEquipmentFailure, (state, action) => ({
    ...state,
    loadingEquipmentEdit: false,
    error: action.error
  })),
  on(CaseSummaryActions.editCaseStatus, state => ({
    ...state,
    loadingCaseReopen: true,
    error: undefined
  })),
  on(CaseSummaryActions.editCaseStatusSuccess, state => ({
    ...state,
    loadingCaseReopen: false
  })),
  on(CaseSummaryActions.editCaseStatusFailure, (state, action) => ({
    ...state,
    loadingCaseReopen: false,
    error: action.error
  })),
  on(CaseSummaryActions.updateSubject, state => ({
    ...state,
    loadingSubjectEdit: true,
    error: undefined
  })),
  on(CaseSummaryActions.updateSubjectSuccess, state => ({
    ...state,
    loadingSubjectEdit: false
  })),
  on(CaseSummaryActions.updateSubjectFailure, (state, action) => ({
    ...state,
    loadingSubjectEdit: false,
    error: action.error
  })),
  on(CaseSummaryActions.selectNextCase, (state, action) => ({
    ...state,
    loadingCase: true
  })),
  on(CaseSummaryActions.selectNextCaseSuccess, (state, action) => ({
    ...state,
    loadingCase: false,
    selectedCase: action.data
  })),
  on(CaseSummaryActions.updateCaseCommentNotification, (state, action) => ({
    ...state,
    selectedCase: { ...state.selectedCase!, notificationCommentIds: undefined }
  })),
  on(CaseSummaryActions.clearContactList, (state, action) => ({
    ...state,
    isValidEmail: true,
    loadingValidation: false,
    contacts: []
  }))
);
