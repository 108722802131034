import { Action, createReducer, on } from '@ngrx/store';

import { IIPAuthorizations, IIPCustomer } from '@ruby/modules/customer-select/models/ip-authorization.interface';
import * as CustomerActions from '@ruby/modules/customer-select/store/actions/customers.actions';
import * as IPCustomerAuthActions from '@ruby/modules/customer-select/store/actions/ip-customer-authorizations.actions';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'ip-customer-auth';

export interface State {
  customer?: IIPCustomer;
  loading: boolean;
  error?: IError;
  authorizations?: IIPAuthorizations;
}

export const initialState: State = {
  customer: undefined,
  loading: false,
  error: undefined,
  authorizations: undefined
};

const ipCustomerAuthReducer = createReducer(
  initialState,
  on(IPCustomerAuthActions.loadIpCustomerAuthorizations, (state, action) => ({
    ...state,
    customer: action.data,
    loading: true,
    authorizations: undefined,
    error: undefined
  })),
  on(IPCustomerAuthActions.loadIpCustomerAuthorizationsSuccess, (state, action) => ({
    ...state,
    loading: false,
    authorizations: action.data,
    error: undefined
  })),
  on(IPCustomerAuthActions.loadIpCustomerAuthorizationsFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error
  })),
  on(CustomerActions.selectCustomer, (state, action) => ({
    ...initialState
  }))
);

export const reducer = (state: State | undefined, action: Action) => ipCustomerAuthReducer(state, action);
