import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';

import { PreferencesService } from '@ruby/core/http/preferences/preferences.service';
import { IError } from '@ruby/shared/models/request/error.interface';
import { IUserPreference } from '@ruby/shared/models/user-preferences/user-preference.interface';
import * as UserPreferenceActions from '@ruby/store/actions/user-preference.actions';

@Injectable()
export class UserPreferenceEffects {

  getUserPreference$ = createEffect(() =>
    this.actions$.pipe(ofType(UserPreferenceActions.loadUserPreference),
      mergeMap(({ data }) => this.preferencesService.getUserPreferenceByScreenAndName(data.screenName, data.preferenceName).pipe(
        map((userPreference: IUserPreference) => {
            if (userPreference) {
              return UserPreferenceActions.loadUserPreferenceSuccess({ data: userPreference });
            }
            return UserPreferenceActions.loadUserPreferenceSuccess({ data: { ...data } });
          }),
        catchError((error: IError) => of(UserPreferenceActions.loadUserPreferenceFailure({ data, error })))
      ))
    ));

  saveUserPreferences$ = createEffect(() =>
    this.actions$.pipe(ofType(UserPreferenceActions.saveUserPreference),
      map(({ data }) => ({
        screenName: data.screenName,
        preferenceName: data.preferenceName,
        preferenceValue: JSON.stringify(data.data)
      })),
      mergeMap(preference => this.preferencesService.saveUserPreference(preference)
        .pipe(
          map((userPreference: IUserPreference) => UserPreferenceActions.saveUserPreferenceSuccess({ data: userPreference })),
          catchError((error: IError) => of(UserPreferenceActions.saveUserPreferenceFailure({
            data: preference,
            error
          })))
        )
      )
    )
  );

  removeUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.removeUserPreference),
      mergeMap(({ data }) => this.preferencesService.removeUserPreferenceByScreenAndName(data.screenName, data.preferenceName)
        .pipe(
          map(() => UserPreferenceActions.removeUserPreferenceSuccess({ data: { ...data } })),
          catchError((error: IError) => of(UserPreferenceActions.removeUserPreferenceFailure({ data, error })))
        )
      )
    )
  );

  getAllUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.loadAllUserPreferences),
      mergeMap(() => this.preferencesService.getUserPreferences().pipe(
        map((userPreferences: Array<IUserPreference>) => UserPreferenceActions.loadAllUserPreferencesSuccess({ data: userPreferences })),
        catchError((error: IError) => of(UserPreferenceActions.loadAllUserPreferencesFailure({ error })))
      ))
    )
  );

  removeAllUserPreferences$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.removeAllUserPreferences),
      mergeMap(() => this.preferencesService.deleteUserPreferences().pipe(
        map(() => UserPreferenceActions.removeAllUserPreferencesSuccess()),
        catchError((error: IError) => of(UserPreferenceActions.removeAllUserPreferencesFailure({ error })))
      ))
    )
  );

  removeAllUserPreferencesSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserPreferenceActions.removeAllUserPreferencesSuccess),
      tap(() => this.document.location.replace('home/dashboard'))
    ), { dispatch: false }
  );

  constructor(private actions$: Actions, private preferencesService: PreferencesService, @Inject(DOCUMENT) private document: Document) { }
}
