import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';

import { environment } from '@ruby/env/environment';
import { IContact } from '@ruby/modules/account/models/contact.interface';
import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { IAuthorizations } from '@ruby/shared/models/commons/authentication.interface';

@Injectable({
  providedIn: 'root'
})
export class DataDogService {

  constructor() {}

  /**
   * Initialize
   *
   * @summary initialize Datadog
   * @param env string
   * @param version string
   * @returns void
   */
  initialize(env: string, version: string): void {
    datadogRum.init({ ...environment.dataDogCredentials, env, version });
    datadogRum.startSessionReplayRecording();
  }

  /**
   * Set User Metadata
   *
   * @summary Set User Metadata for Datadog
   * @param data IContact
   * @returns void
   */
  setUserMetadata(data: IContact): void {
    datadogRum.setUser({
      email: data.email,
      id: data.racf,
      name: `${ data.firstName } ${ data.lastName }`
    });
  }

  /**
   * Set Authorizations Metadata
   *
   * @summary Set Authorizations Metadata for Datadog
   * @param data IAuthorizations
   * @returns void
   */
  setAuthorizationsMetadata(data: IAuthorizations): void {
    datadogRum.setGlobalContext({ roles: data.roles, authorizations: data.authorizations });
  }


  /**
   * Add Customers Metadata
   *
   * @summary Add Customers Metadata
   * @param customers Array<ICustomer>
   * @returns void
   */
  addCustomersMetadata(customers: Array<ICustomer>): void {
    const pCompany: ICustomer | undefined = customers.find((c: ICustomer) => c.primary);

    if (pCompany) {
      const locations = pCompany.locations.map((loc) => ({ classCode: loc.classCode, stationCode: loc.stationCode, name: loc.locationName, lid: loc.tenLevelId }));
      datadogRum.setGlobalContextProperty('pCompany', {
        p6: pCompany.masterPrimarySix,
        name: pCompany.customerName,
        scac: pCompany.scac,
        type: pCompany.type.name,
        locations
      });
    }

    const customersRaw = customers.map((customer: ICustomer) => ({
        p6: customer.masterPrimarySix,
        name: customer.customerName,
        scac: customer.scac,
        type: customer.type?.name,
        cmlP6: customer.parentCustomer?.commercialPrimarySix,
        cmlName: customer.parentCustomer?.commercialName,
        cmlSeg: customer.parentCustomer?.commercialSegmentation,
        cmlTier: customer.parentCustomer?.commercialTier,
        cmlType: customer.parentCustomer?.commercialType,
        comP6: customer.parentCustomer?.commonPrimarySix,
        comName: customer.parentCustomer?.commonName,
        comSeg: customer.parentCustomer?.commonSegmentation,
        comTier: customer.parentCustomer?.commonTier,
        comType: customer.parentCustomer?.commonType,
        validIMP6: customer.isValidIMP6,
        validDrayScac: customer.isValidDrayScac
      })
    );

    datadogRum.setGlobalContextProperty('companies', customersRaw);
  }

  /**
   * Log Error Details
   *
   * @summary Log Error Details
   * @param message string
   * @param details object
   * @returns void
   */
  logErrorDetails(message: string, details: object): void {
    const error = new Error(message);
    datadogRum.addError(error, details);
  }
}
