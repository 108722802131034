import { createSelector } from '@ngrx/store';

import { selectFeatureState, State } from '@ruby/modules/customer-select/store/reducers';
import * as fromOfflineCustomer from '@ruby/modules/customer-select/store/reducers/offline-customers.reducer';

export const selectOfflineCustomerState = createSelector(
  selectFeatureState,
  (customerState: State) => customerState[fromOfflineCustomer.featureKey]
);

export const selectAssignedCustomers = createSelector(
  selectOfflineCustomerState,
  (state: fromOfflineCustomer.State) => state.customers
);
