import { Action, createReducer, on } from '@ngrx/store';

import { ICustomer, ILocation } from '@ruby/modules/customer-select/models/customer.interface';
import * as IMCustomersActions from '@ruby/modules/customer-select/store/actions/im-customers.actions';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'imCustomers';

export interface State {
  customers: Array<ICustomer>;
  locations: Array<ILocation>;
  loading: boolean;
  error?: IError;
}

export const initialState: State = {
  customers: [],
  locations: [],
  loading: false,
  error: undefined
};

const intermodalCustomersReducer = createReducer(
  initialState,
  on(IMCustomersActions.loadIntermodalCustomers, state => ({
    ...state,
    loading: true,
    locations: [],
    error: undefined
  })),
  on(IMCustomersActions.loadIntermodalCustomersSuccess, (state, action) => ({
    ...state,
    loading: false,
    customers: action.data,
    locations: [],
    error: undefined
  })),
  on(IMCustomersActions.loadIntermodalCustomersFailure, (state, action) => ({
    ...state,
    loading: false,
    customers: [],
    locations: [],
    error: action.error
  })),
  on(IMCustomersActions.loadIntermodalLocations, state => ({
    ...state,
    loading: true,
    locations: [],
    error: undefined
  })),
  on(IMCustomersActions.loadIntermodalLocationsSuccess, (state, action) => ({
    ...state,
    loading: false,
    locations: action.data,
    error: undefined
  })),
  on(IMCustomersActions.loadIntermodalLocationsFailure, (state, action) => ({
    ...state,
    loading: false,
    locations: [],
    error: action.error
  }))
);

export const reducer = (state: State | undefined, action: Action) => intermodalCustomersReducer(state, action);
