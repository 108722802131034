export enum EAuthorization {
  INVENTORY_MANAGEMENT = 'inventory_management',
  DAMAGE_CLAIM_SUBMISSION = 'damage_claim_submission',
  UNITTRAIN_MANAGEMENT = 'unittrain_management',
  WAYBILL_HISTORY = 'waybill_history',
  HLJS_CONTACTS_ADMIN = 'hljs_contact_admin',
  SEARCH_WB_VERIFICATION = 'search_wb_verification',
  SEARCH_SEARCHTRACK_TRACE = 'search_searchtrack_trace',
  TPL_AUTHORIZE = '3pl_authorize',
  ENROUTE_VIEW = 'enroute_view',
  BILL_OF_LADING_SUBMISSION_NG = 'bill_of_lading_submission_ng',
  IM_LIFT_VIEW = 'im_lift_view',
  ALERTS_VIEW = 'alerts_view',
  SEARCH_UMLER = 'search_umler',
  CUSTOMIZED_REPORTING_ADMIN_FNS = 'customized_reporting_admin_fns',
  MERCH_PRICE_INQUIRY = 'merch_price_inquiry',
  SEARCH_STEELROADS = 'search_steelroads',
  BILL_OF_LADING_SUBMISSION = 'bill_of_lading_submission',
  SL_PROFILEEDIT = 'sl_profile_edit',
  SL_PROFILE_VIEW = 'sl_profile_view',
  SL_PROFILE_ADD_DELETE = 'sl_profile_add_delete',
  SL_PROFILE_SPONSOR = 'sls_sponsor',
  BILL_OF_LADING_PATTERNS_EDIT = 'bill_of_lading_patterns_edit',
  PIPELINE_VIEW = 'pipeline_view',
  INTERMODAL_RATE_INQUIRY = 'intermodal_rate_inquiry',
  DIVERSIONS = 'diversions',
  QUICKLINKS_VIEW = 'quicklinks_view',
  ECR_SPONSOR = 'ecr_sponsor',
  EFREIGHT_BILLING = 'efreight_billing',
  PROFILE_EDIT = 'profile_edit',
  IM_STORAGE_VIEW = 'im_storage_view',
  IM_STORAGE_GUARANTEE = 'im_storage_guarantee',
  BILL_OF_LADING_PATTERNS_VIEW = 'bill_of_lading_patterns_view',
  MERCH_RATE_INQUIRY = 'merch_rate_inquiry',
  IM_LIFTGUARANTEE = 'im_liftguarantee',
  MILEAGE_INQUIRIES = 'mileage_inquiries',
  PIPELINE_MENUONLY = 'pipeline_menuonly',
  SEARCH_TRACKNTRACE = 'search_trackntrace',
  EFREIGHTBILLNG = 'efreightbillng',
  DAMAGE_NOTIFCATION_SUBMISSION = 'damage_notifcation_submission',
  EFREIGHTBILL_SPONSOR = 'efreightbill_sponsor',
  SL_HLRECONCILIATION_SUBMISSION = 'sl_hlreconciliation_submission',
  EMPTY_CAR_REQUEST_CREATION = 'empty_car_request_creation',
  CUSTOMIZED_REPORTING_FNS = 'customized_reporting_fns',
  WAIVE_DIVERSION_CHARGES = 'waive_diversion_charges',
  FEATURE2_FNS = 'feature2_fns',
  AUTOMOTIVE_USER = 'automotive_user',
  CASE_MANAGEMENT_USER = 'case_management_user',
  CONTRACT_USER = 'contract_user',
  CONTRACT_USER_VOLUME = 'contract_user_volume',
  TRPS_OTH = 'trps_oth',
  TRPS_OPS = 'trps_ops',
  TRPS_MGMT = 'trps_mgmt',
  TRPS_FILL_ORDER = 'trps_fill_order',
  TRPS_REQ_RESERVATIONS = 'trps_request_reservations',
  TRPS_CAN_RESERVATION = 'trps_cancel_reservation',
  TRPS_MGM_ALLOCATIONS = 'trps_manage_allocations',
  TRPS_RESEV_UNIT = 'trps_reserve_unit',
  TRPS_RESCH_UNIT = 'trps_reschedule_unit',
  TRPS_SWAP_UNIT = 'trps_swap_unit',
  TRPS_RMVE_UNIT = 'trps_remove_unit',


  /** Local Authorizations **
   ** local authorizations are UI calculated permission **/
  RUBY_HAS_OCM = 'ruby_has_ocm'
}
