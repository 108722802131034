import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INSAutoCompleteGroup, INSAutoCompleteOption } from '@norfolk-southern/accessns-components';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { NSUrlsConstants } from '@ruby/configs/urls.constants';
import { ICatalogDetailItem, ICatalogItem } from '@ruby/shared/models/commons/catalog-item.interface';
import { IRoad } from '@ruby/shared/models/commons/commons.interface';
import { IStation, IStationData, IStationDetail } from '@ruby/shared/models/commons/station.interface';
import { ITransaction, ITransactionToken } from '@ruby/shared/models/commons/transaction.interface';
import { ICarType, ICarTypeData } from '@ruby/shared/models/rate-inquiry/cartype.interface';
import { IStcc, IStccData } from '@ruby/shared/models/rate-inquiry/stcc.interface';

@Injectable({
  providedIn: 'root'
})
export class CommonsService {
  constructor(private http: HttpClient) { }

  /**
   * Compress Payload
   *
   * @param payload: ITransaction
   * @returns Observable<ITransactionToken | never>
   */
  compressPayload(payload: ITransaction): Observable<ITransactionToken | never> {
    return this.http.post(NSUrlsConstants.compressionUrl, payload, { responseType: 'text' }).pipe(
      map(response => ({ transactionId: response.toString() }))
    );
  }

  /**
   * Compress string
   *
   * @param text: string
   * @returns Observable<string | never>
   */
  compressString(text: string): Observable<string | never> {
    return this.http.post(NSUrlsConstants.compressionUrl, text, { responseType: 'text' });
  }

  /**
   * Decompress Payload
   *
   * @param compressedPayload: string
   * @returns Observable<string | never>
   */
  decompressPayload(compressedPayload: string): Observable<ITransaction | never> {
    return this.http.post(NSUrlsConstants.decompressionUrl, compressedPayload).pipe(
      map(response => response as ITransaction),
      catchError((error: HttpErrorResponse) => {
        const transaction = { parameters: {} } as ITransaction;
        return of(transaction);
      })
    );
  }

  /**
   * Fetch Terminals
   *
   * @summary Fetches Terminal DMN data
   * @returns Observable<Array<string> | never>
   */
  fetchTerminals(): Observable<Array<string> | never> {
    return this.http.get(NSUrlsConstants.gateTerminalsUrl).pipe(map(response => response as Array<string>));
  }

  /**
   * Fetch States
   *
   * @summary Fetches States data
   * @param sortByTitle: boolean
   * @returns Observable<Array<INSAutoCompleteOption> | never>
   */
  fetchStates(sortByTitle: boolean = false): Observable<Array<INSAutoCompleteOption> | never> {
    const params = sortByTitle ? new HttpParams().set('sortColumnType', 'TITLE') : undefined;
    return this.http.get(NSUrlsConstants.statesUrl, { params }).pipe(
      map(resp => (resp as Array<ICatalogItem>).map((element: ICatalogItem) => ({ key: element.title, value: element.title }))),
      map((response: Array<INSAutoCompleteOption>) => response)
    );
  }

  /**
   * Fetch States Catalog
   *
   * @summary Fetches States Catalog data
   * @param sortByTitle: boolean
   * @returns Observable<Array<ICatalogItem> | never>
   */
  fetchStatesCatalog(sortByTitle: boolean = false): Observable<Array<ICatalogItem> | never> {
    const params = sortByTitle ? new HttpParams().set('sortColumnType', 'TITLE') : undefined;
    return this.http.get(NSUrlsConstants.statesUrl, { params }).pipe(
      map(response => response as Array<ICatalogItem>)
    );
  }

  /**
   * Fetch States Catalog
   *
   * @summary Fetches States Catalog data by ids
   * @param ids: Array<number>
   * @param sortByTitle: boolean
   * @returns Observable<Array<ICatalogItem> | never>
   */
  fetchStatesCatalogByIds(ids: Array<number>, sortByTitle: boolean = false): Observable<Array<ICatalogDetailItem> | never> {
    const params = sortByTitle ? new HttpParams().set('sortColumnType', 'TITLE') : undefined;
    return this.http.post(NSUrlsConstants.statesMultipleCountriesUrl,  ids , {params}).pipe(
      map(response => response as Array<ICatalogDetailItem>)
    );
  }

  /**
   * Fetch States V2
   *
   * @summary Fetch States data
   * @returns Observable<Array<INSAutoCompleteOption> | never>
   */
  fetchStatesV2(): Observable<Array<INSAutoCompleteGroup> | never> {
    return this.http.get(NSUrlsConstants.ibolStatesUrl).pipe(
      map(response => {
        const groups = response as Array<INSAutoCompleteGroup>;

        groups.forEach((group: INSAutoCompleteGroup) => group.options = group.options.map((option: INSAutoCompleteOption) => ({
          ...option,
          value: `${ option.value } (${ option.key })`,
          text: group.name
        })));

        return groups;
      })
    );
  }

  /**
   * Fetch Station
   *
   * @summary Fetches Stations data
   * @param request: IStation
   * @param doMapping: boolean
   * @returns Observable<Array<IStationData | INSAutoCompleteOption> | never>
   */
  fetchStation(request: IStation, doMapping: boolean = true): Observable<Array<IStationData | INSAutoCompleteOption> | never> {
    return this.http.post(NSUrlsConstants.stationUrl, request).pipe(
      map(resp => {
        const uniqueStations: Array<string> = [];
        let stations: Array<IStationData | INSAutoCompleteOption> = [];
        if (doMapping) {
          stations = (resp as { stations: Array<IStationData> }).stations.filter((item: IStationData) => {
            let result = false;
            if (uniqueStations.indexOf(item.stationName) === -1) {
              uniqueStations.push(item.stationName);
              result = true;
            }
            return result;
          });
          stations = stations.map(data => ({
            key: (data as IStationData).stationName, value: (data as IStationData).stationName
          })).sort((a, b) => {
            const stationNameA = a.value.toUpperCase();
            const stationNameB = b.value.toUpperCase();
            let result = 0;
            if (stationNameA > stationNameB) {
              result = 1;
            }
            if (stationNameB > stationNameA) {
              result = -1;
            }
            return result;
          });
        }
        return stations && stations.length > 0 ? stations : (resp as { stations: Array<IStationData> }).stations;
      })
    );
  }

  /**
   * Fetch STCC
   *
   * @summary Fetches STCC data
   * @param request: IStcc
   * @returns Observable<Array<INSAutoCompleteOption> | never>
   */
  fetchStcc(request: IStcc): Observable<Array<INSAutoCompleteOption> | never> {
    return this.http.post(NSUrlsConstants.stccUrl, request).pipe(
      map(resp =>
        (resp as { stcclists: Array<IStccData> }).stcclists.map((data: IStccData) => ({
          key: data.stcc,
          value: `${ data.stcc } ${ data.nsstccdescription.trim() }`
        }))
      )
    );
  }

  /**
   * Fetch Car Types
   *
   * @summary Fetches Car Types data
   * @param stcc: string
   * @returns Observable<Array<ICarType> | never>
   */
  fetchCarTypes(stcc: string): Observable<Array<ICarType> | never> {
    return this.http.get(`${ NSUrlsConstants.carTypesUrl }/${ stcc }`).pipe(
      map(resp =>
        (resp as { carTypes: Array<ICarTypeData> }).carTypes.map((data: ICarTypeData) => ({
          key: {
            carTypeCode: data.carTypeCode,
            shipmentCondition: data.shipmentCondition
          },
          value: data.carDescription
        }))
      )
    );
  }

  /**
   * Fetch Party Permissions
   *
   * @summary calls the 'is party to the waybill' service
   * @params equipmentId: string
   * @params waybillSerial: string
   * @returns Observable<boolean | never>
   */
  fetchPartyPermissions(equipmentId: string, waybillSerial?: string): Observable<boolean | never> {
    let params: HttpParams = new HttpParams();
    if (waybillSerial) {
      params = params.set('waybillSerial', waybillSerial);
    }
    const [initial, equipNumber] = equipmentId.split('-');
    return this.http.get(`${ NSUrlsConstants.unitDetailUrl }/${ initial }-${ equipNumber }/is-party-to`, { params }).pipe(
      map(response => response as boolean)
    );
  }

  /**
   * Fetch Party Permissions
   *
   * @summary calls the 'is party to the waybill' service
   * @params waybillSerialNumber: string
   * @params equipmentId: string
   * @returns Observable<boolean | never>
   */
  fetchWaybillPartyToStatus(waybillSerialNumber: string, equipmentId: string): Observable<boolean | never> {
    const [initial, number] = equipmentId.split('-');
    return this.http.get(
      NSUrlsConstants.unitDetailWaybillPartyToUrl,
      { params: { waybillSerialNumber, initial, number } }
    ).pipe(
      map(response => response as boolean)
    );
  }

  /**
   * Fetches Roads
   *
   * @summary Retrieves Roads
   * @returns Observable<Array<INSAutoCompleteOption> | never>
   */
  fetchRoads(): Observable<Array<INSAutoCompleteOption> | never> {
    return this.http.get(NSUrlsConstants.roadsUrl)
      .pipe(
        map(resp => (resp as Array<IRoad>).map((element: IRoad) => ({
          key: element.road,
          value: element.roadName,
          text: `${ element.roadName } (${ element.road })`
        })))
      );
  }

  /**
   * Fetch Cities
   *
   * @summary Retrieves cities belonging to a state.
   * @param station: IStation
   * @returns Observable<Array<IStationDetail> | never>
   */
  fetchCities(station: IStation): Observable<Array<IStationDetail> | never> {
    let params = new HttpParams();
    params = params.set('state', station.stateAbbreviation);
    params = params.set('station', station.station);

    return this.http.get(`${ NSUrlsConstants.citiesUrl }`, { params }).pipe(
      map(response => response as Array<IStationDetail>)
    );
  }

  /**
   * Fetch States Full Name
   *
   * @summary Fetches States with full name data
   * @param sortByTitle: boolean
   * @returns Observable<Array<INSAutoCompleteOption> | never>
   */
  fetchStatesFullName(sortByTitle: boolean = false): Observable<Array<INSAutoCompleteOption> | never> {
    const params = sortByTitle ? new HttpParams().set('sortColumnType', 'TITLE') : undefined;
    return this.http.get(NSUrlsConstants.statesUrl, { params }).pipe(
      map(resp => (resp as Array<ICatalogItem>).map((element: ICatalogItem) => ({ key: element.title, value: `${ element.value.toUpperCase() } (${ element.title })` }))),
      map((response: Array<INSAutoCompleteOption>) => response)
    );
  }
}
