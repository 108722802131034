import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of, tap } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, withLatestFrom } from 'rxjs/operators';

import { AuthRefreshTokenService } from '@ruby/core/authentication/auth-refresh-token.service';
import { SecurityService } from '@ruby/core/authentication/security.service';
import * as AuthorizationsActions from '@ruby/modules/security/store/actions/authorizations.actions';
import * as SecurityActions from '@ruby/modules/security/store/actions/security.actions';
import { IAccessToken } from '@ruby/shared/models/commons/authentication.interface';
import { IError } from '@ruby/shared/models/request/error.interface';
import { LaunchDarklyService } from '@ruby/shared/services/analytics/launch-darkly.service';
import { PendoService } from '@ruby/shared/services/analytics/pendo.service';
import { selectQueryParam } from '@ruby/store/selectors/root.selectors';
import { isInternalRole } from '@ruby/store/selectors/user-account.selectors';

@Injectable()
export class SecurityEffects {

  getAccessToken$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.getAccessToken),
    exhaustMap(action => this.securityService.getAccessToken(action.code).pipe(
      map((data: IAccessToken) => SecurityActions.getAccessTokenSuccess({ data })),
      catchError((error: IError) => of(SecurityActions.getAccessTokenFailure({ error })))
    ))
  ));

  logOut$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.logout),
    tap((action) => {
      this.dialogRef.closeAll();
      if (action.removeToken) {
        this.securityService.removeAccessToken().subscribe();
      }
      this.securityService.purgeAuth();
      this.authRefreshTokenService.stopRefreshTokenTimer();
      this.router.navigateByUrl('/redirect/main');

      this.pendoService.stopPendo();
      this.launchDarklyService.stopLaunchDarkly().then(response => {});
    })
  ), { dispatch: false });

  checkExistingSession$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.checkExistingSession),
    exhaustMap(() => {
      const auth = this.securityService.populate();
      return auth ?
        of(SecurityActions.checkExistingSessionSuccess({ auth })) :
        of(SecurityActions.checkExistingSessionFailure());
    })
  ));

  checkExistingSessionSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.checkExistingSessionSuccess),
    tap(() => this.authRefreshTokenService.startRefreshTokenTimer())
  ), { dispatch: false });

  checkExistingSessionFailure$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.checkExistingSessionFailure),
    tap(() => {
      if (this.securityService.isAuthenticated) {
        this.store.dispatch(SecurityActions.logout({ removeToken: true }));
      }
    })
  ), { dispatch: false });

  refreshToken$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.refreshToken),
    exhaustMap(action => this.securityService.refreshToken(action.refreshToken).pipe(
      tap((auth: IAccessToken) => {
        this.authRefreshTokenService.stopRefreshTokenTimer();
        this.authRefreshTokenService.startRefreshTokenTimer();
      }),
      map((auth: IAccessToken) => SecurityActions.refreshTokenSuccess({ auth })),
      catchError((error: IError) => of(SecurityActions.refreshTokenFailure({ error })))
    ))
  ));

  refreshTokenFailure$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.refreshTokenFailure),
    map(() => SecurityActions.logout({ removeToken: true }))
  ));

  authorizationsOnLoginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.getAccessTokenSuccess, SecurityActions.checkExistingSessionSuccess),
    map(() => AuthorizationsActions.getAuthorizations())
  ));

  setupCompleted$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.setupCompleted),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store.select(isInternalRole))
    )),
    tap(([action, isInternal]) => {
      const selectUrlValue: string = this.document.location.pathname;
      if (selectUrlValue?.startsWith('/redirect')) {
        this.store.dispatch(SecurityActions.navigateHome({ isUserInternal: isInternal }));
      }
    })
  ), { dispatch: false });

  navigateHome$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.navigateHome),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store.select(selectQueryParam('state')))
    )),
    tap(([action, returnParam]) => {
      returnParam = (returnParam && /^\//.test(returnParam))? returnParam : undefined;
      const navigateUrl = returnParam || '/home';
      const extraParams = action.isUserInternal && !returnParam ? '?dialog=customer-select' : '';
      this.router.navigateByUrl(navigateUrl + extraParams);
    })
  ), { dispatch: false });

  handleAuthFailure$ = createEffect(() => this.actions$.pipe(
    ofType(SecurityActions.getAccessTokenFailure),
    tap(() => {
      this.router.navigateByUrl(`/redirect/main`);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private securityService: SecurityService,
    private authRefreshTokenService: AuthRefreshTokenService,
    private router: Router,
    private dialogRef: MatDialog,
    private store: Store,
    private pendoService: PendoService,
    private launchDarklyService: LaunchDarklyService,
    @Inject(DOCUMENT) private document: Document
  ) {}
}
