import { InjectionToken } from '@angular/core';
import * as fromRouter from '@ngrx/router-store';
import { Action, ActionReducerMap, MetaReducer } from '@ngrx/store';

import { PartyToEffects } from '@ruby/store/effects/party-to.effects';
import { UserPreferenceEffects } from '@ruby/store/effects/user-preference.effects';
import { logoutMetaReducer } from '@ruby/store/metaReducers/clear-store.reducer';
import * as fromPartyTo from '@ruby/store/reducers/party-to.reducer';
import * as fromUserPreference from '@ruby/store/reducers/user-preference.reducer';

export interface AppState {
  router: fromRouter.RouterReducerState<any>;
  [fromUserPreference.featureKey]: fromUserPreference.State;
  [fromPartyTo.featureKey]: fromPartyTo.State;
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<AppState, Action>>('Root reducers', {
  factory: /* istanbul ignore next */ () => ({
    router: fromRouter.routerReducer,
    [fromUserPreference.featureKey]: fromUserPreference.reducer,
    [fromPartyTo.featureKey]: fromPartyTo.reducer
  })
});

export const metaReducers: Array<MetaReducer<any>> = [logoutMetaReducer];

export const rootEffects = [PartyToEffects, UserPreferenceEffects];
