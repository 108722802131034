import { createAction, props } from '@ngrx/store';
import { EStatus } from '@norfolk-southern/accessns-components';

import { EOnBehalfFilter } from '@ruby/shared/enums/ocm.enums';
import { ICase } from '@ruby/shared/models/case-management/case.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadCases = createAction(
  '[Case Dashboard] Load Cases',
  props<{ data: EStatus; onBehalfOf?: EOnBehalfFilter }>()
);

export const loadCasesSuccess = createAction(
  '[Case Dashboard] Load Cases Success',
  props<{ data: Array<ICase> }>()
);

export const loadCasesFailure = createAction(
  '[Case Dashboard] Load Cases Failure',
  props<{ error: IError }>()
);

export const loadCompanyCases = createAction(
  '[Case Dashboard] Load Company Cases',
  props<{ data: EStatus; onBehalfOf?: EOnBehalfFilter }>()
);

export const loadCompanyCasesSuccess = createAction(
  '[Case Dashboard] Load Company Cases Success',
  props<{ data: Array<ICase> }>()
);

export const loadCompanyCasesFailure = createAction(
  '[Case Dashboard] Load Company Cases Failure',
  props<{ error: IError }>()
);

export const loadInterestedPartyCases = createAction(
  '[Case Dashboard] Load Cases Interested Party',
  props<{ data: EStatus; onBehalfOf?: EOnBehalfFilter }>()
);

export const loadInterestedPartyCasesSuccess = createAction(
  '[Case Dashboard] Load Cases Interested Party Success',
  props<{ data: Array<ICase> }>()
);

export const loadInterestedPartyCasesFailure = createAction(
  '[Case Dashboard] Load Cases Interested Party Failure',
  props<{ error: IError }>()
);

export const setSelectedIndex = createAction(
  '[Case Dashboard] setSelectedIndex',
  props<{ data: number }>()
);

export const toggleSummary = createAction(
  '[Case Dashboard] Toggle Case Summary',
  props<{ data: boolean }>()
);

export const loadQuickSearchCases = createAction(
  '[Case Dashboard] Load Quick Search Cases',
  props<{ data: Array<string>; status: EStatus }>()
);

export const loadQuickSearchCasesSuccess = createAction(
  '[Case Dashboard] Load Quick Search Cases Success',
  props<{ data: Array<ICase> }>()
);

export const loadQuickSearchCasesFailure = createAction(
  '[Case Dashboard] Load Quick Search Cases Failure',
  props<{ error: IError }>()
);

export const updateCaseData = createAction(
  '[Case Dashboard] Update Case Data',
  props<{ data: ICase }>()
);

export const updateCaseDataNotification = createAction(
'[Case Dashboard] Update Case Data Notification',
  props<{ caseId: string }>()
);
