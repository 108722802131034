import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';

import { AccountService } from '@ruby/core/http/account/account.service';
import { IContact } from '@ruby/modules/account/models/contact.interface';
import * as UserInformationActions from '@ruby/modules/security/store/actions/user-information.actions';
import { IError } from '@ruby/shared/models/request/error.interface';
import { DataDogService } from '@ruby/shared/services/analytics/datadog.service';
import { LaunchDarklyService } from '@ruby/shared/services/analytics/launch-darkly.service';
import { PendoService } from '@ruby/shared/services/analytics/pendo.service';
import { isInternalRole } from '@ruby/store/selectors/user-account.selectors';

@Injectable()
export class UserInformationEffects {

  getUserInformation$ = createEffect(() => this.actions$.pipe(
    ofType(UserInformationActions.getUserInformation),
    concatMap(action => of(action).pipe(withLatestFrom(this.store.select(isInternalRole)))),
    exhaustMap(([action, isInternal]) => (
      isInternal ? this.accountSvc.getUserInformationInternal() : this.accountSvc.getUserInformationExternal()
    ).pipe(
      map((contact: IContact) => UserInformationActions.getUserInformationSuccess({ data: contact })),
      catchError((error: IError) => of(UserInformationActions.getUserInformationFailure({ error })))
    ))
  ));

  getUserInformationSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(UserInformationActions.getUserInformationSuccess),
    tap((action) => this.setupAnalyticsInformation(action.data))
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private accountSvc: AccountService,
    private store: Store,
    private dataDigService: DataDogService,
    private pendoService: PendoService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  private setupAnalyticsInformation(data: IContact): void {
    this.dataDigService.setUserMetadata(data);
    this.pendoService.checkAddContactMetadata(data);
    this.launchDarklyService.checkAddContactMetadata(data);
  }
}
