import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Params, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap, withLatestFrom } from 'rxjs/operators';
import Swal, { SweetAlertResult } from 'sweetalert2';

import { NSMessages } from '@ruby/configs/messages.constants';
import { CommonsService } from '@ruby/core/http/commons/commons.service';
import { selectRoles } from '@ruby/modules/security/store/selectors/authorizations.selectors';
import * as UnitWaybillActions from '@ruby/modules/unit-details/store/actions/unit-waybill.actions';
import * as UnitActions from '@ruby/modules/unit-details/store/actions/unit.actions';
import { EColors } from '@ruby/shared/enums/colors.enums';
import { EGridTimeFrame } from '@ruby/shared/enums/common.enums';
import { EGridType } from '@ruby/shared/enums/grid.enums';
import { ERole } from '@ruby/shared/enums/roles.enums';
import { IGridCriteria } from '@ruby/shared/models/commons/grid.interface';
import { ITransactionToken } from '@ruby/shared/models/commons/transaction.interface';
import { IError } from '@ruby/shared/models/request/error.interface';
import { UtilsService } from '@ruby/shared/services/utils/utils.service';
import * as PartyToActions from '@ruby/store/actions/party-to.actions';

@Injectable()
export class PartyToEffects {
  checkPermissions$ = createEffect(() => this.actions$.pipe(
    ofType(PartyToActions.checkPermissions),
    withLatestFrom(this.store.select(selectRoles)),
    exhaustMap(([action, roles]) => (action.data.waybill ?
      this.commonSvc.fetchWaybillPartyToStatus(action.data.waybill, action.data.equipmentId) :
      this.commonSvc.fetchPartyPermissions(action.data.equipmentId, action.data.waybill)).pipe(
      map((isPartyTo: boolean) => PartyToActions.checkPermissionsSuccess({
        data: isPartyTo,
        roles,
        equipmentId: action.data.equipmentId,
        waybill: action.data.waybill,
        redirect: action.data.redirect,
        viewTab: action.data.viewTab
      })),
      catchError((error: IError) => of(PartyToActions.checkPermissionsFailure({ error })))
    ))
  ));

  permissionInterceptor$ = createEffect(() => this.actions$.pipe(
    ofType(PartyToActions.checkPermissions),
    tap(() => {
      Swal.fire({
        title: NSMessages.accessPending,
        html: NSMessages.partyToWaybill,
        icon: 'info',
        iconColor: EColors.GOLD,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        heightAuto: false,
        willOpen: () => {
          Swal.showLoading();
        }
      }).then();
    })
  ), { dispatch: false });

  successManager$ = createEffect(() => this.actions$.pipe(
    ofType(PartyToActions.checkPermissionsSuccess),
    tap(action => {
      Swal.close();
      const intermodalRoles: Array<ERole> = [ERole.ROLE_IM, ERole.ROLE_FF, ERole.ROLE_Dray].filter((value: ERole) => action.roles.includes(value));
      if (action.data || intermodalRoles.length > 0) {
        this.dialogRef.closeAll();

        const queryParams: Params = {
          equipmentId: action.equipmentId
        };
        if (action.waybill) {
          queryParams.waybill = action.waybill;
        }
        queryParams.viewTab = this.utilsSvc.getUnitDetailTab(action.viewTab as EGridType);
        this.router.navigate(['/home/unit-details'], { queryParams, state: { skipCall: true } });
        this.dispatchUnitWaybill(queryParams, action.equipmentId, action.waybill);
      } else {
        this.notPartyTo(action.equipmentId, action.redirect);
      }
    })
  ), { dispatch: false });

  failureManager$ = createEffect(() => this.actions$.pipe(
    ofType(PartyToActions.checkPermissionsFailure),
    tap(action => {
      Swal.close();
      this.utilsSvc.showErrorToastr(action.error.title, action.error.message);
    })
  ), { dispatch: false });

  saveTransactionCriteria$ = createEffect(() => this.actions$.pipe(
    ofType(PartyToActions.saveCriteria),
    exhaustMap(action => this.commonSvc.compressPayload(action.data).pipe(
      map((token: ITransactionToken) => {
        this.router.navigate(['home/results'], { queryParams: { transaction: token.transactionId } });

        return PartyToActions.saveCriteriaSuccess();
      }),
      catchError((error: IError) => of(PartyToActions.saveCriteriaFailure({ error })))
    ))
  ));

  constructor(
    private actions$: Actions,
    private commonSvc: CommonsService,
    private location: Location,
    private router: Router,
    private store: Store,
    private utilsSvc: UtilsService,
    private dialogRef: MatDialog
  ) {}

  private notPartyTo(equipmentId: string, redirect?: boolean): void {
    Swal.fire({
      title: NSMessages.accessDenied,
      text: `Records indicate you are not a party to this shipment, therefore access to ${ equipmentId } details has been denied.`,
      icon: 'warning',
      confirmButtonText: 'Historical Waybills',
      cancelButtonText: 'OK',
      confirmButtonColor: EColors.GOLD,
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      heightAuto: false
    }).then((result: SweetAlertResult) => {
      if (!result.isDismissed) {
        const quickSearch: IGridCriteria = {
          type: EGridType.WAYBILL_HISTORY,
          query: equipmentId,
          timePeriod: EGridTimeFrame.LAST_SIX_MONTHS
        };
        this.store.dispatch(PartyToActions.saveCriteria({
          data: {
            durationInMinutes: 360,
            parameters: { quickSearch: JSON.stringify(quickSearch) }
          }
        }));
      } else {
        if (redirect) {
          this.location.back();
        } else {
          Swal.close();
        }
      }
    });
  }

  private dispatchUnitWaybill(queryParams: Params, equipmentId: string, waybill: string | undefined): void {
    const equip: Array<string> = equipmentId.split('-');
    this.store.dispatch(UnitActions.loadEquipmentTripPlan({
      data: {
        initial: equip[0],
        number: equip[1],
        waybill
      }
    }));
    this.store.dispatch(waybill ? UnitWaybillActions.updateWaybill({
      data: {
        waybillSerialNumber: waybill,
        equipmentId: queryParams.equipmentId.replace('-', ' ')
      }
    }) : UnitWaybillActions.loadActiveWaybill({ data: { initial: equip[0], number: equip[1] } }));
  }
}
