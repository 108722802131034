<ng-container *ngIf="showMask; else outlet">
  <ns-mask @fadeTrigger [fullScreen]="true"></ns-mask>
</ng-container>
<ng-template #outlet>
  <router-outlet></router-outlet>
</ng-template>

<swal #swalie title="Unsupported Browser Detected" icon="warning" width="36rem" [heightAuto]="false" [showConfirmButton]="false"
      [allowOutsideClick]="false">
  <div class="container" *swalPortal="swalTargets.content">
    <p class="text-justify">
      Please use one of the following supported browsers as Internet Explorer is no longer supported by Microsoft.
    </p>
    <div class="d-flex justify-content-between w-100">
      <a class="text-decoration-none" [href]="browser.link" target="_blank" rel="noopener"
         *ngFor="let browser of browsers">
        <img [src]="browser.img" height="78px" width="78px" [alt]="browser.name"/>
        <div class="text-dark">{{ browser.name }}</div>
      </a>
    </div>
  </div>
</swal>

<swal #swalmobile title="Mobile Warning" icon="warning" width="36rem" [heightAuto]="false" [confirmButtonText]="'Continue'"
      [input]="'checkbox'" [inputPlaceholder]="'Do not Show this Message again'">
  <div class="container" *swalPortal="swalTargets.content">
    <p class="text-justify">
      You are currently on a mobile device. Please consider using one of the mobile applications listed below.
    </p>
    <div *ngFor="let app of mobileApps" class="mt-3">
      <div class="d-flex justify-content-between w-100">
        <a class="text-decoration-none" [href]="isIOS ? app.linkIOS : app.linkAndroid" target="_blank" rel="noopener">
          <img [src]="app.img" height="78px" width="78px" [alt]="app.name"/>
        </a>
        <div class="p-2 text-left">
          <div class="font-weight-bold">{{ app.name }}</div>
          <p>{{ app.description }}</p>
        </div>
      </div>
    </div>
  </div>
</swal>
