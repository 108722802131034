import { createSelector } from '@ngrx/store';

import { ICaseCreationFormState } from '@ruby/modules/case-management/models/case-create.interface';
import { selectCMState, State } from '@ruby/modules/case-management/store';
import { featureKey } from '@ruby/modules/case-management/store/reducers/case-create.reducer';
import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { EAuthorization } from '@ruby/shared/enums/authorizations.enums';
import { ERole } from '@ruby/shared/enums/roles.enums';
import { hasAuthorization, hasIpNoTyesPermissions, hasOCMAccess, hasRole, selectCurrentCustomerSelected } from '@ruby/store/selectors/user-account.selectors';

export const selectManagementWithCustomerInfoState = createSelector(
  selectCMState,
  hasRole(ERole.ROLE_3PL),
  hasAuthorization(EAuthorization.AUTOMOTIVE_USER),
  hasIpNoTyesPermissions,
  hasOCMAccess,
  selectCurrentCustomerSelected,
  (state: State, is3PL: boolean, isAutomotive: boolean,
   isIPNoTyesPermissions: boolean, hasOCM: boolean, customer?: ICustomer) => ({
    state: state[featureKey],
    is3PL,
    isAutomotive,
    isIPNoTyesPermissions,
    hasOCM,
    customer
  })
);

export const selectInteractionCategories = createSelector(
  selectCMState,
  (managerState: State) => managerState[featureKey].interactionCategories
);

export const selectInteractionReasons = createSelector(
  selectCMState,
  (managerState: State) => managerState[featureKey].interactionReasons
);

export const selectInteractionTypes = createSelector(
  selectCMState,
  (managerState: State) => managerState[featureKey].interactionTypes
);

export const selectCarTypes = createSelector(
  selectCMState,
  (managerState: State) => managerState[featureKey].carTypes
);

export const selectDraftCase = createSelector(
  selectCMState,
  (managerState: State) => managerState ? managerState[featureKey].savedForm : undefined);

export const hasDraftCase = createSelector(
  selectDraftCase,
  (draftCase?: ICaseCreationFormState ) => draftCase !== undefined
);

export const selectCustomersWithPrimaryCompany = createSelector(
  selectCMState,
  (managerState: State) => ({
    customers: managerState[featureKey].customers,
    primaryCompany: managerState[featureKey].primaryCompany
  })
);

export const selectCaseCreationResult = createSelector(
  selectCMState,
  (managerState: State) => managerState[featureKey].caseCreationResult
);

export const selectGridEquipmentResult = createSelector(
  selectCMState,
  (managerState: State) => managerState[featureKey].gridResult
);
