import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SecurityLayoutComponent } from '@ruby/core/layout/security-layout/security.layout';
import * as fromSecurity from '@ruby/modules/security/store';
import { effects } from '@ruby/modules/security/store/effects/index.effects';

@NgModule({
  declarations: [SecurityLayoutComponent],
  imports: [
    MatDialogModule,
    EffectsModule.forFeature(effects),
    RouterModule,
    StoreModule.forFeature(fromSecurity.featureKey, fromSecurity.reducer),
    CommonModule
  ],
  exports: [SecurityLayoutComponent]
})
export class SecurityLayoutModule {}
