<ng-case-create *ngIf="store$ | async as store"
                [selectedCustomer]="selectedCustomer!"
                [interactionCategories]="store.state.interactionCategories"
                [interactionReasons]="store.state.interactionReasons"
                [interactionTypes]="store.state.interactionTypes"
                [carTypes]="store.state.carTypes"
                [customersTopPreferences]="(storePreference$ | async)!"
                [customers]="store.state.customers"
                [customerSelectedPersona]="customerSelectedPersona"
                [isAutomotive]="store.isAutomotive"
                [is3PLCustomer]="store.is3PL"
                [isIPNoTyesPermissions]="store.isIPNoTyesPermissions"
                [primaryCompany]="store.state.primaryCompany"
                [interestedPartiesAdded]="store.state.contacts"
                [isValidEmail]="store.state.isValidEmail"
                [loadingEmailValidation]="store.state.loadingEmailValidation"
                [errorMessageInterestedParty]="store.state.emailValidationError?.message"
                [savedForm]="store.state.savedForm"
                [loading]="store.state.loadingCaseCreation"
                [hasOCM]="store.hasOCM"
                [equipments]="(equipments$ | async)!"
                [gridResults]="(gridResults$ | async)!"
                [caseCreated$]="caseCreated$"
                (saveCustomerTopPreferences)="dispatchSaveCustomerTopPreferences($event)"
                (emailValidation)="dispatchEmailValidation($event)"
                (removeContact)="dispatchRemoveContact($event)"
                (submitCase)="submitCase($event)"
                (resetState)="resetState($event)"
                (loadCatalogs)="loadCatalogs($event)"
                (closeMinimize)="closeMinimize($event)"
                (equipmentGridCellClicked)="partyToEquipmentCheck($event)"
                (searchGridCriteria)="dispatchCriteria($event)"
                [loadingGridResult]="store.state.loadingGridResult">
</ng-case-create>
