import { createSelector } from '@ngrx/store';

import { EUserPreferences } from '@ruby/shared/enums/user-preferences.enums';
import { AppState } from '@ruby/store';
import * as fromUserPreference from '@ruby/store/reducers/user-preference.reducer';

export const selectUserPreferencesFeature = (state: AppState) => state[fromUserPreference.featureKey];

export const selectUserPreferenceState = (preference: EUserPreferences) => createSelector(
  selectUserPreferencesFeature,
  (state: fromUserPreference.State) =>
    state.userPreferences[preference] && state.userPreferences[preference].preferenceValue
);

export const selectUserPreferenceValue = (preference: EUserPreferences) => createSelector(
  selectUserPreferencesFeature,
  (state: fromUserPreference.State) => ({
    preferenceValue: state.userPreferences[preference] && state.userPreferences[preference].preferenceValue,
    loadCompleted: state.userPreferences[preference] && state.userPreferences[preference].loadCompleted
  })
);

export const selectUserPreferencesList = createSelector(
  selectUserPreferencesFeature,
  (state: fromUserPreference.State) => ({
    preferences: Object.values(state.userPreferences),
    loading: state.loading,
    errors: state.errors
  })
);
