import { createReducer, on } from '@ngrx/store';

import {
  ICaseCreationFormState,
  ICaseCreationResult,
  IInteractionCatalogItem
} from '@ruby/modules/case-management/models/case-create.interface';
import * as CaseCreateActions from '@ruby/modules/case-management/store/actions/case-create.actions';
import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { ICaseContact } from '@ruby/shared/models/case-management/case.interface';
import { IGridResult } from '@ruby/shared/models/commons/grid.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const featureKey = 'caseCreate';

export interface State {
  loading: boolean;
  error?: IError;
  emailValidationError?: IError;
  customers: Array<ICustomer>;
  primaryCompany?: ICustomer;
  interactionReasons: Array<IInteractionCatalogItem>;
  interactionCategories: Array<IInteractionCatalogItem>;
  interactionTypes: Array<IInteractionCatalogItem>;
  carTypes: Array<IInteractionCatalogItem>;
  loadingInteractionReasons: boolean;
  loadingInteractionCategories: boolean;
  loadingInteractionTypes: boolean;
  loadingCarTypes: boolean;
  loadingCaseCreation: boolean;
  loadingEmailValidation: boolean;
  caseCreationResult?: ICaseCreationResult;
  contacts: Array<ICaseContact>;
  isValidEmail: boolean;
  savedForm?: ICaseCreationFormState;
  gridResult?: IGridResult;
  loadingGridResult: boolean;
}

export const initialState: State = {
  loading: false,
  error: undefined,
  emailValidationError: undefined,
  customers: [],
  primaryCompany: undefined,
  interactionCategories: [],
  interactionReasons: [],
  interactionTypes: [],
  carTypes: [],
  loadingInteractionCategories: false,
  loadingInteractionReasons: false,
  loadingInteractionTypes: false,
  loadingCarTypes: false,
  loadingCaseCreation: false,
  loadingEmailValidation: false,
  caseCreationResult: undefined,
  contacts: [],
  isValidEmail: false,
  savedForm: undefined,
  gridResult: undefined,
  loadingGridResult: false
};

export const reducer = createReducer(
  initialState,
  on(CaseCreateActions.loadCustomers, state => ({
    ...state,
    loading: true
  })),
  on(CaseCreateActions.loadCustomersSuccess, (state, action) => ({
    ...state,
    loading: false,
    customers: action.data,
    primaryCompany: action.primaryCustomer
  })),
  on(CaseCreateActions.loadCustomersFailure, (state, action) => ({
    ...state,
    loading: false,
    error: action.error
  })),
  on(CaseCreateActions.loadInteractionCategories, state => ({
    ...state,
    interactionCategories: [],
    loadingInteractionCategories: true,
    error: undefined
  })),
  on(CaseCreateActions.loadInteractionCategoriesSuccess, (state, action) => ({
    ...state,
    interactionCategories: action.data,
    loadingInteractionCategories: false
  })),
  on(CaseCreateActions.loadInteractionCategoriesFailure, (state, action) => ({
    ...state,
    loadingInteractionCategories: false,
    error: action.error
  })),
  on(CaseCreateActions.loadInteractionReasons, state => ({
    ...state,
    interactionReasons: [],
    loadingInteractionReasons: true,
    error: undefined
  })),
  on(CaseCreateActions.loadInteractionReasonsSuccess, (state, action) => ({
    ...state,
    interactionReasons: action.data,
    loadingInteractionReasons: false
  })),
  on(CaseCreateActions.loadInteractionReasonsFailure, (state, action) => ({
    ...state,
    loadingInteractionReasons: false,
    error: action.error
  })),
  on(CaseCreateActions.loadInteractionTypes, state => ({
    ...state,
    interactionTypes: [],
    loadingInteractionTypes: true,
    error: undefined
  })),
  on(CaseCreateActions.loadInteractionTypesSuccess, (state, action) => ({
    ...state,
    interactionTypes: action.data,
    loadingInteractionTypes: false
  })),
  on(CaseCreateActions.loadInteractionTypesFailure, (state, action) => ({
    ...state,
    loadingInteractionTypes: false,
    error: action.error
  })),
  on(CaseCreateActions.loadCarTypes, state => ({
    ...state,
    carTypes: [],
    loadingCarTypes: true,
    error: undefined
  })),
  on(CaseCreateActions.loadCarTypesSuccess, (state, action) => ({
    ...state,
    carTypes: action.data,
    loadingCarTypes: false
  })),
  on(CaseCreateActions.loadCarTypesFailure, (state, action) => ({
    ...state,
    loadingCarTypes: false,
    error: action.error
  })),
  on(CaseCreateActions.emailValidation, state => ({
    ...state,
    isValidEmail: false,
    loadingEmailValidation: true,
    emailValidationError: undefined
  })),
  on(CaseCreateActions.emailValidationSuccess, (state, action) => ({
    ...state,
    isValidEmail: true,
    loadingEmailValidation: false,
    contacts: [...state.contacts, action.data]
  })),
  on(CaseCreateActions.emailValidationFailure, (state, action) => ({
    ...state,
    isValidEmail: false,
    loadingEmailValidation: false,
    emailValidationError: action.error
  })),
  on(CaseCreateActions.removeContact, (state, action) => ({
    ...state,
    contacts: state.contacts.filter((contact: ICaseContact) => contact.racf !== action.data)
  })),
  on(CaseCreateActions.submitCaseCreation, state => ({
    ...state,
    caseCreationResult: undefined,
    loadingCaseCreation: true,
    error: undefined
  })),
  on(CaseCreateActions.submitCaseCreationSuccess, (state, action) => ({
    ...state,
    caseCreationResult: action.data,
    loadingCaseCreation: false,
    gridResult: undefined
  })),
  on(CaseCreateActions.submitCaseCreationFailure, (state, action) => ({
    ...state,
    loadingCaseCreation: false,
    error: action.error
  })),
  on(CaseCreateActions.resetState, (state, action) => ({
    ...initialState,
    customers: action.preserveData ? state.customers : [],
    primaryCompany: action.preserveData ? state.primaryCompany : undefined,
    interactionCategories: action.preserveData ? state.interactionCategories : [],
    interactionReasons: action.preserveData ? state.interactionReasons : [],
    interactionTypes: action.preserveData ? state.interactionTypes : [],
    carTypes: action.preserveData ? state.carTypes : [],
    savedForm: undefined,
    gridResult: undefined
  })),
  on(CaseCreateActions.updateForm, (state, action) => ({
    ...state,
    savedForm: action.data,
    gridResult: undefined
  })),
  on(CaseCreateActions.getGridDataOCM, (state) => ({
    ...state,
    loadingGridResult: true
  })),
  on(CaseCreateActions.getGridDataOCMSuccess, (state, action) => ({
    ...state,
    loadingGridResult: false,
    gridResult: action.data
  })),
  on(CaseCreateActions.getGridDataOCMFailure, (state, action) => ({
    ...state,
    loadingGridResult: false,
    error: action.data.error,
    gridResult: { searchResults: [], invalidEquipments: action.data.invalidEquipments }
  }))
);
