import { createAction, props } from '@ngrx/store';

import { ICustomer, ILocation } from '@ruby/modules/customer-select/models/customer.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadIntermodalCustomers = createAction(
  '[Customer] Load Intermodal Customers'
);

export const loadIntermodalCustomersSuccess = createAction(
  '[Customer] Load Intermodal Customers Success',
  props<{ data: Array<ICustomer> }>()
);

export const loadIntermodalCustomersFailure = createAction(
  '[Customer] Load Intermodal Customers Failure',
  props<{ error: IError }>()
);

export const loadIntermodalLocations = createAction(
  '[Customer] Load Intermodal Locations',
  props<{ data: { customerIdentifier: string } }>()
);

export const loadIntermodalLocationsSuccess = createAction(
  '[Customer] Load Intermodal Locations Success',
  props<{ data: Array<ILocation> }>()
);

export const loadIntermodalLocationsFailure = createAction(
  '[Customer] Load Intermodal Locations Failure',
  props<{ error: IError }>()
);
