import { createAction, props } from '@ngrx/store';

import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const loadOfflineCustomers = createAction(
  '[Customer] Load Offline Customers'
);

export const loadOfflineCustomersSuccess = createAction(
  '[Customer] Load Offline Customers Success',
  props<{ data: Array<ICustomer> }>()
);

export const loadOfflineCustomersFailure = createAction(
  '[Customer] Load Offline Customers Failure',
  props<{ error: IError }>()
);
