export class NSMessages {
  // General Purpose
  static readonly created = 'Created';
  static readonly updated = 'Updated';
  static readonly deleted = 'Deleted';
  static readonly error = 'Error';
  static readonly noData = 'No Data';
  static readonly noExcelData = 'No data available to download.';
  static readonly downloading = 'Downloading';
  static readonly noResults = 'No Results found';
  static readonly noWaybill = 'No waybills found';
  static readonly emailConfirmation = 'Email sent successfully!';
  static readonly noMedia = 'Media not found';
  static readonly uploadError = 'Upload Error';
  static readonly uploadSuccess = 'File Successfully Uploaded.';
  static readonly uploadEmptyFile = 'You cannot upload empty files';
  static readonly uploadDuplicatedFile = 'You cannot upload duplicated files';
  static readonly selectRecord = 'Select at least one record.';
  static readonly noProfile = 'User Profile Info is not available. Submitting Freight Claim/Notification is disabled. ' +
    'If you continue to experience this issue, please reach out to our Help Desk at cshelpdesk@nscorp.com for assistance.';
  static readonly notAuthorized = 'You are not authorized to use this application. ' +
    'If you continue to experience this issue, please reach out to our Help Desk at cshelpdesk@nscorp.com for assistance.';
  static readonly alert = 'Alert';
  static readonly warning = 'Warning';
  static readonly genericTitle = 'Message';
  static readonly genericMessageSuccess = 'The request was successfully';
  static readonly confirmation = 'Confirmation';
  static readonly accessDenied = 'Access Denied!';
  static readonly accessPending = 'Checking Access!';
  static readonly appOutdated = 'App update!';
  static readonly appOutdatedMsg = 'There\'s a newer version of this app. We will refresh it for you in a bit. Hang tight';
  static readonly download = 'Download';
  static readonly fileDownload = 'File download in progress';
  static readonly success = 'Success';
  static readonly additionalPermissions = 'Additional Permissions Required';
  static readonly confirmStayOrRedirectToLegacy = 'Do you want to continue using the new AccessNS?';

  static readonly removeLegacyReportsTitle = 'Schedule Your Reports!!';
  static readonly removeLegacyReportsMessage = 'The reports found under the Customized Reporting, ' +
    'Tracing Apps, Intermodal Applications, and Intermodal Dray Applications within AccessNS will no longer be available effective, ' +
    'Wednesday March 23rd at 6:00 PM EST. <br/>' +
    'If you need information in one or more of these reports, we encourage you to schedule each report to be delivered on a periodic basis ' +
    '(hourly, daily, weekly) via email, fax, or FTP. Reports must be scheduled before tomorrow at 6:00 PM.';
  static readonly moduleNoPermissions = 'To request access to this module, please contact cshelpdesk@nscorp.com.<br>';

  // Intermodal
  static readonly intermodalEnroute = 'Enroute Lanes';
  static readonly intermodalEnrouteLanes = 'No lanes available.';

  // Account
  static readonly passwordChangeSuccess = 'Password Changed Successfully';
  static readonly securityQuestionsUpdateSuccess = 'Successfully updated the security questions.';
  static readonly profileUpdated = `Profile Successfully ${ NSMessages.updated }!`;
  static readonly preferencesSuccessfullyUpdated = `Preferences Successfully ${ NSMessages.updated }!`;
  static readonly appRequestedMessage = 'Your authorization request was submitted successfully. Please allow 48 hours for your request to be reviewed.';
  static readonly checkEmailPasswordMessage = 'Please double-check the temporary password, sent via email, is entered correctly.';

  // Admin Alerts
  static readonly alertCreated = `Alert Successfully ${ NSMessages.created }!`;
  static readonly alertUpdated = `Alert Successfully ${ NSMessages.updated }!`;
  static readonly alertDeleted = `Alert Successfully ${ NSMessages.deleted }!`;

  // Case Management
  static readonly caseFileLimit = 'File/Attachment greater than 25MB. Please select a smaller file 25MB or less to be uploaded and saved.';
  static readonly caseCreationSuccess = 'Case was successfully created in AccessNS.';
  static readonly caseInterestedPartyDeletedMessageSuccess = 'The interested party has been removed';
  static readonly caseInterestedPartyRemoveMessageConfirmation = 'Are you sure you want to remove this contact as an Interested Party?';
  static readonly caseEquipmentRemoveMessageConfirmation = 'Are you sure you want to remove the unit(s)?';
  static readonly caseAttachmentOptions = 'Please select the available options';
  static readonly caseCsrAttachmentOptions = 'Attachments posted by NS Customer Service can not be deleted';
  static readonly caseAttachmentConfirmation = 'Are you sure you want to delete';
  static readonly caseAttachmentRemoveSuccess = 'Successfully removed attachment';
  static readonly equipmentRemoveSuccess = 'Successfully removed equipments(s).';
  static readonly equipmentAddSuccess = 'Successfully added equipments(s).';
  static readonly equipmentCaseIdFailureTitle = 'Unable to retrieve case information';
  static readonly equipmentCaseIdFailureMsg = 'An issue occurred while retrieving case information. Click here to navigate to the Case Screen to view details';
  static readonly equipmentSelectionSubTitle = 'Select up to 25 units of equipment to add to your case';

  // Damage Freight
  static readonly damageNotificationConfirmation = 'Damage Notification Confirmation.';
  static readonly damageNotificationUpdated = `Damage Notification ${ NSMessages.updated }.`;
  static readonly damageNotificationDeleted = `Damage Notification ${ NSMessages.deleted }.`;
  static readonly damageClaimConfirmation = 'Freight Claim Confirmation.';
  static readonly damageClaimUpdated = `Freight Claim ${ NSMessages.updated }.`;
  static readonly damageConvertToClaim = 'Notification has been converted to claim successfully.';

  // HLJS
  static readonly hljsSubmitSuccess = 'Statements were submitted.';

  // Mileage Inquiry
  static readonly mileageAddJunction = 'Both origin carrier and destination carrier should be NS or junction info is needed.';
  static readonly mileageRemoveJunctionDetails = 'Both origin and destination carriers are NS. Please remove junction details to proceed.';
  static readonly mileageNonNSCarriers = 'At least one carrier must be NS in order to calculate mileage.';
  static readonly mileageInvalidJunction = 'This station is not a valid junction. Kindly retry.';

  // Party To Waybill
  static readonly partyToWaybill = 'One moment while we confirm that you are party to the waybill';

  // Price Publication
  static readonly pricePublicationTitle = 'Price Publication';
  static readonly pricePublicationDescription = 'Keeps you up-to-date with the most current pricing for all your Norfolk Southern Rail Shipments';
  static readonly pricePublicationSubscribeToSTCCHelpDescription = 'Will register you to be notified anytime there is a change within any ' +
    'publications which contain this STCC.';
  static readonly pricePublicationSubscribeToPublicationHelpDescription = 'Will register you to be notified anytime there is a change within this publication.';
  static readonly pricePublicationSubscribeToSectionHelpDescription = 'Will register you to be notified anytime there is a change within this ' +
    'section of the publication.';
  static readonly pricePublicationEmailHelpDescription = 'This is the email address associated with your AccessNS profile. If you wish to change ' +
    'this, please go to Preferences link under My Profile.';
  static readonly pricePublicationExpiringPublicationsTitle = 'Price Publications Expiring Within the Next 30 days';
  static readonly pricePublicationSubscribeHeaderTitle = 'Subscribe';
  static readonly pricePublicationUnsubscribeHeaderTitle = 'Unsubscribe';
  static readonly pricePublicationSubscribeTitle = 'Subscribe To:';
  static readonly pricePublicationUnsubscribeTitle = 'Unsubscribe From:';
  static readonly pricePublicationSubscribeSuccess = 'Requested publication subscribed successfully.';
  static readonly pricePublicationSubscribeSectionSuccess = 'Requested section subscribed successfully.';
  static readonly pricePublicationSubscribeSTCCSuccess = 'Requested STCC(s) subscribed successfully.';
  static readonly pricePublicationUnsubscribeSuccess = 'Requested publication unsubscribed successfully.';
  static readonly pricePublicationUnsubscribeSectionSuccess = 'Requested section unsubscribed successfully.';
  static readonly pricePublicationUnsubscribeSTCCSuccess = 'Requested STCC(s) unsubscribed successfully.';
  static readonly pricePublicationSubscribeConfirmation = 'Subscribe Confirmation';
  static readonly pricePublicationUnsubscribeConfirmation = 'Unsubscribe Confirmation';
  static readonly pricePublicationSTCCHelpDescription = 'Please Select below Link to Subscribe and Unsubscribe the STCC.';
  static readonly pricePublicationNotificationSettingsConfirmation = 'Settings Confirmation';
  static readonly pricePublicationUpdateNotificationSettingsSuccess = 'You are subscribed to receive notifications on expiring publications.';
  static readonly pricePublicationExportMessage = 'This request will return count publications. This may take some time. Are you sure that you ' +
    'want to continue?';

  // Special Instructions
  static readonly specialInstructionCreated = `Special Instruction Successfully ${ NSMessages.created }!`;
  static readonly specialInstructionUpdated = `Special Instruction Successfully ${ NSMessages.updated }!`;
  static readonly specialInstructionDeleted = `Special Instruction Successfully ${ NSMessages.deleted }!`;

  // Watchlist Messages
  static readonly watchlistAddLimit = 'No Equipment ID(s) to add to the watchlist';
  static readonly watchlistRemoveLimit = 'No Equipment ID(s) to remove from the watchlist';
  static readonly watchlistExceededLimit = 'Watchlist Limit Exceeded';
  static readonly watchlistRemovedOne = `Removed Successfully`;
  static readonly watchlistAddedOne = `Added Successfully`;
  static readonly watchlistEquipmentWatchlist = 'The equipment: ';
  static readonly watchlistAddAllTitle = 'Add All WatchList item(s)';
  static readonly watchlistRemovedWatchlist = ' has been successfully removed from your Watchlist. This message will not reappear going forward.';
  static readonly watchlistAddedWatchlist = ' has been successfully added from your Watchlist. This message will not reappear going forward.';
  static readonly watchlistFinalExceededLimitDes = ' more items to the watchlist of click below link to remove old watchlist items';
  static readonly watchListUnauthorizedMessage = 'You are not authorize to view this item because you are not a party to the current waybill.';

  // HLJS Messages
  static readonly hljsSubmitted = 'Statement is submitted successfully';
  static readonly hljsContactCreated = `HLJS Contact Successfully ${ NSMessages.created }!`;
  static readonly hljsContactUpdated = `HLJS Contact Successfully ${ NSMessages.updated }!`;
  static readonly hljsContactDeleted = `HLJS Contact Successfully ${ NSMessages.deleted }!`;

  // EBill Messages
  static readonly eBillEmailRaaTip = 'Check to have the eDelivery emails sent to the Revenue Accounting Analyst';
  static readonly eBillBolNumberInfo = 'Image ID is not found.';
  static readonly eBillBolNumberUrl = 'https://www.nscorp.com/AbolsImageRetriever/AbolsPdfImage?folderId=';
  static readonly eBillInvoiceNumberInfo = 'Requested freight bill image is not available. Please contact to your administration';
  static readonly eBillInvoiceSelectionWarning = 'You must need to select reason for all invoices.';
  static readonly eBillEmailSuccessMsg = 'Your request has been received. The bill you selected will be sent.';
  static readonly eBillCarDisputeMsg = 'At least one date field and comment fields are mandatory.';
  static readonly eBillNoPermissions = 'To Request access to the eFreightBill module, please contact EFreightBillRequest@nscorp.com';
  static readonly eBillStatusCode = 'ACCESSNS_BACKEND_EFREIGHTBILL000';

  // EPrice Messages
  static readonly ePricePublicRatesHelpDescription = 'Freight All Kinds (FAK) rates are tariff quotes for shipments in lanes not under contract and ' +
    'can be used by anyone.';
  static readonly ePriceSpecialQuotesHelpDescription = 'Agreed upon rates(s) for one or more OD pairs (Origin/Destination) for a specific beneficial owner.';
  static readonly ePriceQuoteNumbersHelpDescription = 'Please enter up to 10 quote numbers each separated by comma.';
  static readonly ePriceQuoteNumbersHelpExample = 'Example: 1001,703,111.';
  static readonly ePriceDuplicateTerminals = 'Origin and destination terminal cannot be same.';

  // Track Moves
  static readonly trackMoveEmptyTrack = 'No from track selected.';
  static readonly trackMoveEmptyToTrack = 'No to track selected.';
  static readonly trackMoveEmptyRecords = 'There is no inventory on this track.';
  static readonly mainTrackSelected = 'Equipment can only be moved to sub-tracks. Main industry tracks are no longer valid target tracks.';
  static readonly dropNotAllowed = 'Drag/Drop within same grid is not allowed.';
  static readonly multipleTrackSelected = 'Selected cars are currently on multiple sub-tracks. Please only move cars from one distinct ' +
    'sub-track at a time.';
  static readonly singleTrackSelected = 'Moves to the same track are not allowed.';
  static readonly toTrackNotSelected = 'The following equipment(s) does not have a To Track Selected.';
  static readonly consecutiveTrack = 'When moving to the same track, inventory must be consecutive.';

  //ECR
  static readonly ecrStatusPageTitle = 'ECR Status';
  static readonly ecrCreateSuccess = 'ECR Request Created Successfully';
  static readonly ecrSubmitSuccess = 'ECR Request Submitted Successfully';
  static readonly ecrUpdateSuccess = 'ECR Request Updated Successfully';
  static readonly ecrDeleteSuccess = 'ECR Request Deleted Successfully';
  static readonly ecrUnsavedChanges = 'Changes have not been saved. Click Continue to process without saving.';
  static readonly ecrStatusCode = 'ACCESSNS_BACKEND_ECR_001';
  static readonly ecrRequestWithZero = 'One or more weeks in the request calendar have zero request totals.<br>Please confirm no demand is requested.';
  static readonly ecrRequestWithNumber = 'Is the total request for the week of replace required on the date submitted?<br /><br/>' +
    'Clicking Yes confirms that the total demand for the week will be sent on the date indicated,<br /><br/>' +
    'Clicking No indicates that the demand may be delivered over the course of the entire week.';
  static readonly ecrNoPermissions = 'To request access to the ECR module, please contact the appropriate fleet team.<br>' +
    '<b>Boxcars</b> (boxcars@nscorp.com)<br>' +
    '<b>Hoppers</b> (hoppers@nscorp.com)<br>' +
    '<b>Gons, Coils, Flats</b> (cdumetals@nscorp.com)';
  static readonly ecrLimitExceeded = 'The total request submited for the following week(s)<br> replace<br>' +
    'is at least 20% higher than your request for this same time period in the previous year.' +
    '<br>Click Yes to submit the request as is.' +
    '<br>Click No to change the request amount.';

  // IBol
  static readonly iBolDeleteSuccessMessage = 'Deleted successfully';
  static readonly iBolDeleteConfirmationMessage = 'Are you sure you want to delete';
  static readonly iBolCreatePatternPageTitle = 'Create a Pattern';
  static readonly iBolUpdatePatternPageTitle = 'Update Pattern';
  static readonly iBolBillPageTitle = 'Bill of Lading';
  static readonly iBolSavedBolsPageTitle = 'Saved/Unsent Bill Of Lading';
  static readonly iBolBillSuccessfullySavedMessage = 'Shipment Instruction saved successfully';
  static readonly iBolPatternSuccessfullySavedMessage = 'Pattern saved successfully';
  static readonly iBolBillTitle = 'Shipment Instruction';
  static readonly iBolImportCarsFormatError = 'Please check the data, the current data format is wrong.';
  static readonly iBolImportCarsLengthError = 'Please check the data, the number of delimitted fields inputted will not create complete rows.';
  static readonly iBolImportCarsMaxError = 'Please check the data, the max number of rows was surpassed.';
  static readonly iBolImportRailCarsHint = 'To paste car information into this form, copy your data with either a comma(,) or semicolon(;)'
    + ' between each field and paste the copied data into the text box above. <br/>Include the lead car. No spaces into the data are allowed.'
    + ' Then specify which fields you have pasted and then press the Apply button.';
  static readonly iBolImportVehiclesHint = 'To paste VIN information into this form, copy your data with either a comma(,) or semicolon(;) '
    + 'between each field and paste the copied data in the Text Box above.<br/> Include the lead car. No spaces into the data are allowed. '
    + 'Then specify which fields you have pasted and then press the Apply button.';
  static readonly iBolVINLabel = 'Vehicle Identification Number';
  static readonly iBolClearStepConfirmation = 'Are you sure you want to clear the current step?';
  static readonly iBolResetFormConfirmation = 'Are you sure you want to reset the all form?';
  static readonly iBolRemoveEntryConfirmation = 'Are you sure you want to remove?';
  static readonly iBolReplaceHazmatForm = 'This selection will overlay the commodity information on the current screen. ' +
    'If you need to save that information, press the Cancel button at the bottom of this message. ' +
    'Otherwise press OK and your new selection will be processed. Continue with selection?';
  static readonly iBolHazIncorrectCommodity = 'Package Qualifier in Commodity Screen inconsistency with Package Qualifier in Hazardous Material Screen.';
  static readonly iBolHazInvalidSTCC = 'Please, verify if the stcc was already added or if it belongs to the commodities list.';
  static readonly iBolInvalidPackageQuantity = 'There cannot be more than 1 Pieces for a single car.';
  static readonly iBolHazmatSubmitMessage = 'To proceed without making changes resubmit.';
  static readonly iBolSubmitBillDefaultMessage = 'This equipment may not be at your location.';
  static readonly iBolGenericErrorMessage = 'Something wrong happened';
  static readonly iBolHazmatInvalidSTCC = 'The Hazardous Material Section is required for STCC(s) starting with 48 and 49.';
  static readonly iBolSingleBillWarningMessage = 'The "Type of Bill" is set to "Single", which will create separate waybills' +
    'for each piece of equipment. If this is not what you want, return to the Bill Setup page and change the "Type of Bill."';
  static readonly iBolEquipmentMovingMessage = 'You can not Edit this bill because the equipment is already moving.';
  static readonly iBolForgotAddCommodityMessage = 'Please don\'t forget to press the \'Add Hazmat Information to Continue\''
    + ' to add the hazardous material entry to the bill.';
  static readonly iBolCreatePatternDialogTitle = 'Create Pattern';
  static readonly iBolCreateBillDialogTitle = 'Create Bill';
  static readonly iBolCreateBillHazmatDialogTitle = 'Hazardous Materials';
  static readonly iBolIntermodalLabel = 'Intermodal';
  static readonly iBolEmptyWaybillLabel = 'Empty Waybil';
  static readonly iBolUnitTrainLabel = 'Unit Train';
  static readonly iBolLoadMarchandiseLabel = 'Loaded Merchandise';
  static readonly iBolCoalMarchandiseLabel = 'Coal Merchandise';
  static readonly iBolDeadheadCMTLLabel = 'Deadhead CMTL';
  static readonly iBolProfileNotSetup = 'Your profile is not currently setup to create bills for this Customer. <br/> To request access, please aoc@nscorp.com';
  static readonly iBolImageIdError = 'Invalid image id';
  static readonly iBolDestinationMXCartaPorte = 'Because your shipment is destined for Mexico, additional information is ' +
    'required per Mexican SAT Carta Porte Guidelines in the ‘Add/Edit Additional Equipment Section of the Bill Setup Tab’';
  static readonly iBolLoadPatternError = 'An Issue occurred while loading your pattern. Please try again. ' +
    'If you continue to experience this issue, please reach out to our Help Desk at cshelpdesk@nscorp.com for assistance.';

  // Customized Reporting
  static readonly customizedReportingValidateFTPTitleSuccess = 'Connection established';
  static readonly customizedReportingValidateFTPMessageSuccess = 'The ftp configuration is correct';
  static readonly customizedReportingCreatedReportInvalidSetup = 'This report is not enable yet for this grid';
  static readonly customizedReportingCreatedReportTitleSuccess = 'Report Created Successfully';
  static readonly customizedReportingCreatedReportMessageSuccess = 'Report Created';
  static readonly customizedReportingCreatedReportDistributeMessageSuccess = 'Report Created. The report will be executed once and ' +
    'distributed to all recipients';
  static readonly customizedReportingCreatedReportInvalidReportName = 'Please set a valid report name';
  static readonly customizedReportingCreatedReportInvalidDestination = 'Please select a destination folder';
  static readonly customizedReportingCreatedReportInvalidRecipient = 'Please add at least 1 recipient';
  static readonly customizedReportingCreatedReportInvalidHTMFormatForFTP = 'The HTML format option is not available for FTP recipient';
  static readonly customizedReportingCreatedReportInvalidWeeklyDays = 'Please select at least 1 day of the week';
  static readonly customizedReportingCreatedReportTimeFrameTitle = 'TIME OF DAY';
  static readonly customizedReportingCreatedReportTimeFrameSubtitle = 'Select a delivery time';
  static readonly customizedReportingCreatedReportOnlyForExternalsMessage = 'To Create a Report you need an external account';
  static readonly customizedReportingRunConfirmationReportMessage = 'Are you sure you want to run the selected report(s)?';
  static readonly customizedReportingRunReportTitleSuccess = 'Running';
  static readonly customizedReportingRunReportMessageSuccess = 'The selected reports have been queued for delivery';
  static readonly customizedReportingFavoriteReportAddedTitleSuccess = 'Favorite added';
  static readonly customizedReportingFavoriteReportAddedMessageSuccess = 'The report was added as favorite';
  static readonly customizedReportingFavoriteReportDeletedTitleSuccess = 'Favorite removed';
  static readonly customizedReportingFavoriteReportDeletedMessageSuccess = 'The report was removed as favorite';
  static readonly customizedReportingReportSelectionRequiredTitle = 'Selection Required';
  static readonly customizedReportingReportSelectionRequiredMessage = 'Select report(s) for run';
  static readonly customizedReportingReportPausedConfirmationMessage = 'Are you sure you want to pause all reports?';
  static readonly customizedReportingReportPausedTitleSuccess = 'Paused';
  static readonly customizedReportingReportPausedMessageSuccess = 'The reports have been paused';
  static readonly customizedReportingReportResumedConfirmationMessage = 'Are you sure you want to resume all reports?';
  static readonly customizedReportingReportResumedTitleSuccess = 'Resumed';
  static readonly customizedReportingReportResumedMessageSuccess = 'The reports have been resumed';
  static readonly customizedReportingReportRestoreTitleSuccess = 'Restored';
  static readonly customizedReportingReportRestoredMessageSuccess = 'The reports have been restored';
  static readonly customizedReportingReportRestoreSelectionRequiredMessage = 'Select report(s) to restore';
  static readonly customizedReportingRestoreConfirmationReportMessage = 'Are you sure you want to restore the selected(s) report?';
  static readonly customizedReportingDeleteConfirmationReportMessage = 'Are you sure you want to delete the selected(s) items?';
  static readonly customizedReportingReportDeletedTitleSuccess = 'Deleted';
  static readonly customizedReportingReportDeletedMessageSuccess = 'The reports have been deleted';
  static readonly customizedReportingInvalidDeleteRootFolderMessage = 'You cannot delete a root folder (Companies or Personal Folder)';
  static readonly customizedReportingInvalidDeleteCompanyFolderMessage = 'You can\'t delete a company folder';
  static readonly customizedReportingInvalidDeleteOwnerMessage = 'You have to be the owner of the reports/folders that you want to delete';
  static readonly customizedReportingDeleteSelectedTooltip = 'Select a report or folder to delete';
  static readonly customizedReportingRunSelectedTooltip = 'Select a report or folder with reports to run';
  static readonly customizedReportingValidDeleteSelectedTooltip = 'Delete selected reports and folders';
  static readonly customizedReportingReportClonedTitleSuccess = 'Cloned';
  static readonly customizedReportingReportClonedMessageSuccess = 'The report has been cloned';
  static readonly customizedReportingSingleReportDeleteConfirmation = 'Are you sure you want to delete the selected report?';
  static readonly customizedReportingSingleReportDeletedMessageSuccess = 'The report has been deleted';
  static readonly customizedReportingCreateNewFolderTitle = 'Create New Folder';
  static readonly customizedReportingEditFolderTitle = 'Edit Folder Name';
  static readonly customizedReportingEditFolderName = 'Please enter a new folder name to edit';
  static readonly customizedReportingNewFolderMinimumLength = 'The folder name should be at least';
  static readonly customizedReportingCreateNewFolderTypeFolderName = 'Please enter a new folder name to create';
  static readonly customizedReportingCreateNewFolderMessageSuccess = 'The folder has been created';
  static readonly customizedReportingInvalidDropItemsInReport = 'You cannot drop items in a report';
  static readonly customizedReportingInvalidDropInRootCompanyFolder = 'You can only drop into a specific company folder';
  static readonly customizedReportingInvalidDropInvalidOwner = 'You must be the owner of the destination folder';
  static readonly customizedReportingInvalidDragAndDropInvalidRootFolderSelection = 'You cannot move root folders';
  static readonly customizedReportingInvalidDropSameParent = 'You cannot move a report/folder into the same parent';
  static readonly customizedReportingInvalidDropOwner = 'You cannot move reports/folders if you are not the owner';
  static readonly customizedReportingReportDragAndDropUpdateTitleSuccess = 'Moved';
  static readonly customizedReportingReportDragAndDropUpdateTitleMessage = 'The items have been moved';
  static readonly customizedReportingReportEditedTitleSuccess = 'Report Edited Successfully';
  static readonly customizedReportingReportEditedMessage = 'Report edited';
  static readonly customizedReportingFolderEditedTitleSuccess = 'Folder Edited Successfully';
  static readonly customizedReportingFolderEditedMessage = 'Folder edited';
  static readonly customizedReportingReportColumnsEditedMessageSuccess = 'Report Columns/Filters Edited Successfully';
  static readonly customizedReportingDownloading = 'The report will be available in a few seconds';
  static readonly customizedReportingHTMLFormatNotSupported = 'The HTML format is not supported to download';

  // Release Switch
  static readonly releaseSwitchRejectOneCar = 'You can only reject one car at a time.';
  static readonly releaseSwitchRefuseOneCar = 'You can only refuse one car at a time.';
  static readonly releaseSwitchLimitedCar = 'You can only release 100 car(s) at a time.';
  static readonly releaseSwitchCreated = 'Release Switch Request created successfully.';
  static readonly releaseSwitchWarningCutoffDate = 'The requested release date & time is after your Cut-Off time and ' +
    'will not be planned for today. This request will be planned for the next scheduled service date.';

  // Order
  static readonly orderLimitedCar = 'You can only order 100 car(s) at a time.';
  static readonly orderCreated = 'Order(s) submitted successfully.';
  static readonly orderUpdated = 'Order updated successfully.';
  static readonly orderDeleted = 'Order(s) deleted successfully.';

  // Billing Inventory
  static readonly disputeApproval = 'Submitted approval successfully.';
  static readonly disputeSubmit = 'Submitted dispute successfully.';

  // Receiving Inventory
  static readonly receivingInventoryWSPlaced = 'Placed';
  static readonly receivingInventoryWSPlacement = 'Available for Placement';
  static readonly receivingInventoryWSEnRouteToday = 'En Route';
  static readonly receivingInventoryWSEnRouteYesterday = 'En Route (yesterday\'s plan)';
  static readonly receivingInventoryWSEnRouteHistorical = 'En Route (historical ETA)';
  static readonly receivingInventoryWSRequested = 'Requested';

  // Short Line Profiles
  static readonly sLProfilesCommercialSummaryCreated = `Profile Has Been ${ NSMessages.created }`;
  static readonly sLProfilesCommercialSummaryUpdated = `Profile Has Been ${ NSMessages.updated }`;
  static readonly sLProfilesCommercialSummaryDeleted = `Profile Has Been ${ NSMessages.deleted }`;

  // Short Line Additional Contacts
  static readonly sLProfilesContactsCreated = `Contact Has Been ${ NSMessages.created }`;
  static readonly sLProfilesContactsUpdated = `Contact Has Been ${ NSMessages.updated }`;
  static readonly sLProfilesContactsDeleted = `Contact Has Been ${ NSMessages.deleted }`;

  // Short Line Operating Characteristics
  static readonly sLProfilesCharacteristicUpdated = `Characteristic Has Been ${ NSMessages.updated }`;

  // Diversions
  static readonly diversionsConsigneeSearchWarning = 'Please select a state and enter at least three letters of the consignee name.';
  static readonly diversionsCareOfPartySearchWarning = 'Please select a state and enter at least three letters of the care of party name.';
  static readonly diversionsCustomerSearchWarning = 'Please enter at least three letters of the customer name.';
  static readonly diversionsCompanySearchWarning = 'Please select a state and enter at least three letters of the company name.';
  static readonly diversionsTurnoverCarsTitle = 'Car(s) to have Turnover applied';
  static readonly diversionsConsigneeTitle = 'Consignee';
  static readonly diversionsCareOfPartyTitle = 'Care of Party';
  static readonly diversionsRailTitle = 'Rail Destination (If different from Consignee City/State)';
  static readonly diversionsCartaPorteTitle = 'Carta Porte';
  static readonly diversionsRoutingTitle = 'Routing';
  static readonly diversionsChangePayerTitle = 'Payer of Freight';
  static readonly diversionsReshipChangePayerTitle = 'Payer of Freight';
  static readonly diversionsCommodityTitle = 'Commodity';
  static readonly diversionsReconsignPartyBilledTitle = 'Party to be Billed for Reconsignment Charges';
  static readonly diversionsDestReconsignPersonAuthTitle = 'Person Authorizing Destination Reconsignment';
  static readonly diversionsTurnoverReviewTitle = 'Review Destination Turnover';
  static readonly diversionsPartyRailcarTitle = 'Party Railcar Turned Over to (Consignor)';
  static readonly diversionsPartyLoadingTitle = 'Party Loading/Relinquishing Rights to Railcar';
  static readonly diversionsTurnoverPartyBilledTitle = 'Party to be Billed for Turnover Charges';
  static readonly diversionsReconsignPersonAuthTitle = 'Person Authorizing Reconsignment';
  static readonly diversionsOriginTurnoverReviewTitle = 'Review Origin Turnover';
  static readonly diversionsDispositionCarsTitle = 'Car(s) to have Empty Disposition Applied';
  static readonly diversionsCurrentLoadedCarsTitle = 'Current Loaded Destination (Origin for empty billing)';
  static readonly diversionsEmptyConsigneeCarsTitle = 'Consignee for Next Empty Move';
  static readonly diversionsEmptyPersonAuthTitle = 'Person Authorizing Empty Disposition';
  static readonly diversionsEmptyReviewTitle = 'Review Empty Disposition';
  static readonly diversionsDiversionCarsTitle = 'Car(s) to be Diverted';
  static readonly diversionsDiversionPartyBilledTitle = 'Party to be Billed for Diversion Charges';
  static readonly diversionsBrokerTitle = 'Cross Border Transit - Broker Information';
  static readonly diversionsMexicoForwarderTitle = 'Cross Border Transit – Mexico Forwarder Information';
  static readonly diversionsCanadaAesTitle = 'Cross Border Transit – Canada AES Reference';
  static readonly diversionsCanadaErpTitle = 'Cross Border Transit – Canada Hazardous Material ERP';
  static readonly diversionsDiversionPersonAuthTitle = 'Person Authorizing Diversion';
  static readonly diversionsDiversionReviewTitle = 'Review Diversion';
  static readonly diversionsDiversionPersonAuthHelp = 'The party requesting the diversion certifies that it is authorized by the owner or lessee of the car in\n' +
    'issue to make the diversion. As a condition of Norfolk Southern performing the diversion, the party\n' +
    'requesting the diversion understands and agrees that it becomes responsible for reimbursing Norfolk\n' +
    'Southern for any and all charges and costs which may arise from the party requesting the diversion being\n' +
    'in fact not authorized to make the diversion.';
  static readonly diversionsReshipCarsTitle = 'Car(s) to be Reshipped';
  static readonly diversionsShipperTitle = 'New Shipper';
  static readonly diversionsPersonAuthReshipTitle = ' Person Authorizing Reship';
  static readonly diversionsReviewReshipTitle = 'Review Reship';
  static readonly diversionsReshipPaymentP = 'Pre-Paid';
  static readonly diversionsReshipPaymentC = 'Collect';
  static readonly diversionsPrevRequestConfirmCancel = 'Are you sure you want to cancel Request';
  static readonly diversionsPrevRequestConfirmReject = 'Are you sure you want to reject Request';
  static readonly diversionsPrevRequestConfirmComplete = 'Are you sure you want to complete Request';
  static readonly diversionsConfirmBeforeResetForm = 'Are you sure you want to clear all fields and restart the request?';
  static readonly diversionsEquipmentLimitExceeded = 'Equipment limit exceeded';
  static readonly diversionsMexicanBound = 'Mexican bound shipments can only contain one equipment';
  static readonly diversionsSameConsigneeMessage = 'Destination Turnover is showing the same as the current billing.';

  // Unit Train
  static readonly unitTrainPassLoadDateWarning = 'Scheduled Load Date selected is a past date. Are you sure you want to continue?';
  static readonly unitTrainExceedsLoadDateWarning = 'Scheduled Load Selected exceeds 45 days. Are you sure you want to continue?';
  static readonly unitTrainClonePermit = 'Do you want to clone the permit';
  static readonly unitTrainPermitConfirmCreate = 'Are you sure you want to continue?';
  static readonly unitTrainSuccessCreatePermit = 'Successfully created new Permit.';
  static readonly unitTrainSuccessUpdatePermit = 'Successfully updated Permit.';
  static readonly unitTrainSuccessClonePermit = 'Successfully cloned Permit.';
  static readonly unitTrainReleaseEmpties = 'Release Empties';
  static readonly unitTrainReleaseEmptiesSuccess = ' car(s) not confirmed at destination.';
  static readonly unitTrainCarsReleased = 'Car(s) Released';
  static readonly unitTrainServerDate = 'Date';
  static readonly unitTrainServerDateFailure = 'Release date should be within 2 hours prior/equal to current date/time Check at least one car to release.';
  static readonly unitTrainCalendarPermissionDenied = 'You are not authorized to change this shipment.';
  static readonly unitTrainCalendarDragMonthDenied = 'This Shipment cannot be moved to past date or beyond the current month.';
  static readonly unitTrainNoResultsFound = 'No Results found matching your search parameters.';
  static readonly unitTrainDeliveryLoadDateValidation = 'Please schedule DeliverByDate more than Load Date.';
  static readonly unitTrainLoadDeliveryDateValidation = 'Please schedule LoadDate Less than Deliver By Date.';
  static readonly unitTrainMovingShipment = 'Moving Shipment.';
  static readonly unitTrainSuccessSubmitDispute = 'Successfully Submitted Dispute.';
  static readonly unitTrainNoPermissions = 'To request access to the Unit Train module, please contact cshelpdesk@nscorp.com.<br>';

  // Rate Request
  static readonly rateRequestSuccess = 'We have received your request. We will respond to it shortly.';
  static readonly rateRequestInvalidOriginDestination = 'Origin station and destination station cannot be same.';
  static readonly rateRequestInvalidDuplicates = 'Please remove duplicate entries before submitting.';
  static readonly unitTrainSuccessSubmittedReport = 'Report processed successfully.';

  // Gate Receipt
  static readonly gateImageNotFound = '<br>Images are available at NG AGS Locations only for the past 13 months. ' +
    'Images may take up to 24 hours before becoming available<br/>' +
    'Please contact eCommerce Help at 1-800-635-5768 or echelp@nscorp.com.';

  // Revenue
  static readonly revenueHelpPotentialCandidates = 'Shipments identified as refund candidates (to be reviewed and processed at the end of the period)';
  static readonly revenueHelpPotentialRefundAmount = 'Refund amount associated with shipments identified as refund candidates (to be reviewed and ' +
    'processed at the end of the period)';
  static readonly revenueHelpRefundAmountPaid = 'Refund amount paid';
  static readonly revenueRefundAccepted = 'Accept Refund';
  static readonly revenueRefundAcceptedMessage = 'Thank you for confirming your acceptance. You will receive a confirmation email shortly.';

  // Revenue Contracts
  static readonly revenueContractStatusExpired = 'The contract expiration date has passed.';
  static readonly revenueContractStatusExecuted = 'The contract is within the effective and expiration dates.';
  static readonly revenueContractStatusDrafting = 'NS is in the process of drafting the contract.';
  static readonly revenueContractStatusPendingApprovals = 'Contract has been submitted for approvals by NS and customer.';
  static readonly revenueContractStatusPendingSignature = 'Contract is awaiting signature by NS and customer.';
  static readonly revenueCaseAttachmentDownloading = 'The file will be available in a few seconds';

  // Revenue Claims
  static readonly revenueClaimSubmitted = 'Revenue Claim Create submitted.';

  // Grid Preference
  static readonly gridColumnSave = 'Do you want to save the changes?';

  // Order Fill
  static readonly orderFillLocationsNotFound = 'No locations found';
  static readonly orderFillLocationsRequired = 'Locations(Class Codes) are required to view empty car order fill history information';

  // Forgot Password
  static readonly challengeQuestionslockedoutMessage = 'Please call Customer Support to reset your security questions:';
  static readonly challengeQuestionslockedoutContact = '1-800-635-5768';
  static readonly challengeQuestionslockedoutContactOption = 'option #4';

  // Revenue claim Alternate Account
  static readonly imppersonateAccount = 'Impersonate an Account';
  static readonly imppersonateAccountMessage = 'Please enter the account you wish to impersonate';

  // Invalid File extension Type
  static readonly fileUploadErrorTitle = 'File Upload Instructions';
  static readonly fileUploadOnlyImagesMessage = 'Please upload only images.';

  // Transit Performance
  static readonly onTimeTransitPerformanceTooltip: string = 'Percentage of inbound loads arrive on-time demonstrating performance over time for your selected period.';

  //Unsubscribe Notification
  static readonly notificationUnsubscribed: string = 'Notification unsubscribed';

  //IARS messages
  static readonly iarsRequestReservationsCloseMessage = 'Changes will be lost, Are you sure?';
  static readonly iarsRequestReservationsDuplicateRecords = 'Duplicate reservation exists. Please Adjust';
  static readonly iarsManageAllocationConfirmMessage = 'Allocation space modification, Are you sure you want to continue?';

}
