import { createAction, props } from '@ngrx/store';

import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { IError } from '@ruby/shared/models/request/error.interface';

export const searchCarLoadCustomers = createAction(
  '[Customer] Search Car Load Customers',
  props<{ data: { classCode?: string; customerName?: string } }>()
);

export const searchCarLoadCustomersSuccess = createAction(
  '[Customer] Search Car Load Customers Success',
  props<{ data: Array<ICustomer> }>()
);

export const searchCarLoadCustomersFailure = createAction(
  '[Customer] Search Car Load Customers Failure',
  props<{ error: IError }>()
);

export const clearCarLoadCustomers = createAction(
  '[Customer] Clear Car Load Customers'
);
