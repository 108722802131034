import { createSelector } from '@ngrx/store';

import { IContact, IUserInfo } from '@ruby/modules/account/models/contact.interface';
import { ICustomer } from '@ruby/modules/customer-select/models/customer.interface';
import { hasIpCustomers, selectCurrentCustomerSelected } from '@ruby/modules/customer-select/store/selectors/customers.selectors';
import { selectAssignedCustomers } from '@ruby/modules/customer-select/store/selectors/offline-customers.selectors';
import {
  selectUserRacf,
  hasAuthorization,
  hasAuthorizations,
  hasRole,
  hasRoles,
  isInternalRole,
  selectAuthorizations,
  selectRoles
} from '@ruby/modules/security/store/selectors/authorizations.selectors';
import { selectUserInformation } from '@ruby/modules/security/store/selectors/user-information.selectors';
import { EAuthorization } from '@ruby/shared/enums/authorizations.enums';
import { EUserType } from '@ruby/shared/enums/customer-select.enums';
import { ERole } from '@ruby/shared/enums/roles.enums';

export {
  selectUserRacf,
  selectRoles,
  selectAuthorizations,
  hasRole,
  hasRoles,
  hasAuthorization,
  hasAuthorizations,
  isInternalRole,
  selectUserInformation,
  selectCurrentCustomerSelected,
  selectAssignedCustomers
};

export const selectUserInfoState = createSelector(
  selectRoles,
  selectUserInformation,
  selectUserRacf,
  isInternalRole,
  (roles: Array<string>, contact?: IContact, racfId?: string, isInternal?: boolean) => ({
    contact,
    roles,
    racfId,
    type: isInternal ? EUserType.INTERNAL : EUserType.EXTERNAL
  } as IUserInfo)
);

export const hasOCMAccess = createSelector(
  hasRole(ERole.ROLE_Internal),
  hasAuthorization(EAuthorization.CASE_MANAGEMENT_USER),
  selectCurrentCustomerSelected,
  (isInternal: boolean, isOCMUser: boolean, customer?: ICustomer) =>
    isOCMUser && !isInternal && customer?.type.role !== ERole.ROLE_SL
);

export const selectRubyAuthorizations = createSelector(
  hasOCMAccess,
  (hasOCM: boolean) => [
    ...(hasOCM ? [EAuthorization.RUBY_HAS_OCM] : [])
  ]
);

export const hasIpNoTyesPermissions = createSelector(
  hasRole(ERole.ROLE_IP),
  hasIpCustomers,
  (isIP: boolean, hasIpCustomersValue: boolean) => isIP && !hasIpCustomersValue
);

export const hasManageSubscriptionAccess = createSelector(
  selectCurrentCustomerSelected,
  (customer?: ICustomer) => customer?.type.role === ERole.ROLE_IP
);
