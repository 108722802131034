import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';

import { AuthorizationsService } from '@ruby/core/authentication/authorizations.service';
import * as AuthorizationsActions from '@ruby/modules/security/store/actions/authorizations.actions';
import * as SecurityActions from '@ruby/modules/security/store/actions/security.actions';
import * as UserInformationActions from '@ruby/modules/security/store/actions/user-information.actions';
import { IAuthorizations } from '@ruby/shared/models/commons/authentication.interface';
import { IError } from '@ruby/shared/models/request/error.interface';
import { DataDogService } from '@ruby/shared/services/analytics/datadog.service';
import { LaunchDarklyService } from '@ruby/shared/services/analytics/launch-darkly.service';
import { PendoService } from '@ruby/shared/services/analytics/pendo.service';

@Injectable()
export class AuthorizationsEffects {

  getAuthorizations$ = createEffect(() => this.actions$.pipe(
    ofType(AuthorizationsActions.getAuthorizations),
    exhaustMap(() => this.authorizationsService.getAuthorizations().pipe(
      map(auth => AuthorizationsActions.getAuthorizationsSuccess({ data: auth })),
      catchError((error: IError) => of(AuthorizationsActions.getAuthorizationsFailure({ error })))
    ))
  ));

  getAuthorizationsSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthorizationsActions.getAuthorizationsSuccess),
    tap((action) => this.setupOnAuthorizationsSuccess(action.data)),
    concatMap(() => [
      SecurityActions.setupCompleted(),
      UserInformationActions.getUserInformation()
    ])
  ));

  constructor(
    private actions$: Actions,
    private authorizationsService: AuthorizationsService,
    private dataDigService: DataDogService,
    private pendoService: PendoService,
    private launchDarklyService: LaunchDarklyService
  ) {}

  private setupOnAuthorizationsSuccess(data: IAuthorizations): void {
    this.dataDigService.setAuthorizationsMetadata(data);
    this.pendoService.initializePendo(data);
    this.launchDarklyService.initializeLaunchDarkly(data);
  }
}
