import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'ng-security-layout',
  templateUrl: './security.layout.html',
  styleUrls: ['./security.layout.scss']
})
export class SecurityLayoutComponent {
  version$: Observable<string>;

  constructor(private http: HttpClient) {
    this.version$ = this.http.get('assets/context/version', { responseType: 'text' });
  }
}
